import React, {
  createContext,
  useMemo
} from 'react';
import {
  arrayOf,
  node,
  shape,
  string,
  bool
} from 'prop-types';
import { number } from '@thd-nucleus/data-sources';
import {
  transformResponseToContextValues
} from '../utils/promo-model-utils';

export const defaultModelContext = {
  rewardTiers: [{
    maxAllowedRewardAmount: 0,
    minThresholdVal: 0,
    thresholdType: '',
    rewardVal: 0,
    rewardType: '',
    rewardLevel: ''
  }],
  src1EligibilityCriteria: {},
  promotionTags: '',
  subExperience: '',
  tgt1EligibilityCriteria: {},
  isBXG1Enabled: false
};

export const PromoModelContext = createContext(defaultModelContext);

export const PromoModelProvider = ({
  itemId,
  promotion,
  isBXG1Enabled,
  children
}) => {

  const contextValue = useMemo(() => {
    if (!promotion) {
      return {
        ...defaultModelContext
      };
    }

    const {
      subExperience,
      rewardTiers,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria
    } = transformResponseToContextValues({
      promotion,
      isBXG1Enabled
    });

    return {
      itemId,
      subExperience,
      rewardTiers,
      src1EligibilityCriteria,
      tgt1EligibilityCriteria
    };
  }, [
    promotion,
    isBXG1Enabled
  ]);

  return (
    <PromoModelContext.Provider value={contextValue}>
      {(!promotion)
        ? (
          <div />
        ) : children}
    </PromoModelContext.Provider>
  );
};

PromoModelProvider.displayName = 'PromoModelProvider';

PromoModelProvider.propTypes = {
  isBXG1Enabled: bool.isRequired,
  itemId: string.isRequired,
  promotion: shape({
    promotionId: string,
    skuItemGroup: string,
    promotionTags: arrayOf(string),
    eligibilityCriteria: arrayOf(shape({
      itemGroup: string,
      minThresholdVal: number({ float: true }),
      thresholdType: string
    })),
    reward: shape({
      tiers: arrayOf(shape({
        minThresholdVal: number({ float: true }),
        thresholdType: string,
        rewardVal: number({ float: true }),
        rewardType: string,
        rewardLevel: string,
        maxAllowedRewardAmount: number({ float: true })
      }))
    })
  }).isRequired,
  children: node.isRequired
};
