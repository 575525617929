import { createContext } from 'react';

const funcDefault = () => {
  // This is intentional
};

export const AddToContext = createContext({
  listDetails: {},
  projectRefetch: funcDefault,
  listRefetch: funcDefault,
  handleBackPress: funcDefault,
  setScreen: funcDefault,
  selectedProductDetails: {},
  setSelectedProductDetails: funcDefault,
  setShowSuccessMessage: funcDefault,
  addSelectedTo: funcDefault,
  addToType: '',
  products: []
});