import { defaultContent } from './ComboboxContext';

const defaultState = defaultContent();

const motion = {
  down: (state, list) => {
    if (!state.active) {
      return {
        index: 0,
        item: list[0]
      };
    }

    const nextIndex = (state.active.index + 1) % list.length;
    return {
      index: nextIndex,
      item: list[nextIndex]
    };
  },
  up: (state, list) => {
    if (!state.active) {
      const lastIndex = list.length - 1;
      return {
        index: lastIndex,
        item: list[lastIndex]
      };
    }
    const index = (state.active.index - 1) % list.length;
    const nextIndex = index < 0 ? list.length - 1 : index;
    return {
      index: nextIndex,
      item: list[nextIndex]
    };
  }
};

const comboboxMotionReducer = (state, action) => {
  const { type, list } = action;
  if (!Array.isArray(list) || list.length === 0) return defaultState;

  switch (type) {
  case 'ArrowDown': {
    return {
      list: list || [],
      active: motion.down(state, action?.list)
    };
  }
  case 'ArrowUp': {
    return {
      list: list || [],
      active: motion.up(state, action?.list)
    };
  }
  default: {
    return defaultState;
  }
  }
};

export { comboboxMotionReducer };
