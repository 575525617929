import { useState, useMemo } from 'react';
import { handleEvent } from '../utils/typeahed-element-events';

/**
 * `useAsync` extracts behavior related to asynchronous searches.
 *
 * - debounce `onInputChange` to allow for a complete search text before executing `onSearch`
 * - debounce `onSubmit` for protection against `{enter}` key or click spams
 *
 * Executes `onSearch` callback when Typeahead's input is in focus
 */
export const useAsync = (props) => {
  const {
    onSearch,
    onSubmit,
    onInputFocus,
    ...otherProps
  } = props;
  const [results, setResults] = useState(null);

  const executeSearchRequest = async (event, searchText) => {
    if (typeof onSearch !== 'function') return;

    const data = await Promise.resolve(onSearch(searchText));
    setResults(data);
  };

  /**
   * handlers
   */
  const memoizedOnInputChange = useMemo(() => (event) => handleEvent.onChange(event, executeSearchRequest, true), []);

  const memoizedOnInputFocus = useMemo(() => (event) => {
    executeSearchRequest(event, event?.target?.value);
    handleEvent.onFocus(event, onInputFocus);
  }, [onInputFocus]);

  return {
    ...otherProps,
    onSubmit: (event, customEvent) => handleEvent.onSubmit(event, customEvent, onSubmit, true),
    onInputChange: memoizedOnInputChange,
    onInputFocus: memoizedOnInputFocus,
    results
  };
};
