/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Clock } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames';

const TimerEvent = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg,
  invertText,
  fontSize
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const style = {
    message: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText },
      { 'sui-h2-display': fontSize === 'base' && !isMobile },
      { 'sui-h3-display': fontSize === 'small' && !isMobile },
      { 'sui-h4-display': fontSize === 'extraSmall' && !isMobile },
      { 'sui-h4-display': fontSize === 'small' && isMobile },
      { 'sui-h5-display': fontSize === 'extraSmall' && isMobile },
      { 'sui-tracking-normal': fontSize === 'small' && isMobile },
      { 'sui-tracking-normal': fontSize === 'extraSmall' && isMobile },
      { '@lg:sui-text-5xl @md:sui-text-4xl @sm:sui-text-2xl sui-text-base': !isMobile }
    ),
    timer: classNames(
      'sui-flex',
      'sui-mr-2',
      'sui-ml-2',
      'sui-items-center',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText },
      { '@lg:sui-text-5xl @md:sui-text-4xl @sm:sui-text-2xl sui-text-base': !isMobile }
    ),
    countdown: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText },
      { 'sui-h4-display': !isMobile },
      { 'sui-h2-display': fontSize === 'base' },
      { 'sui-h5-display': fontSize === 'small' && isMobile },
      { 'sui-h6-display': fontSize === 'extraSmall' && isMobile },
      { '@lg:sui-text-4xl @md:sui-text-5xl @sm:sui-text-xl sui-text-base': !isMobile },
      'sui-break-all'
    ),
    timerContainer: classNames(
      'sui-flex',
      'sui-pt-2',
      'sui-px-2',
      { 'sui-pt-3': fontSize === 'base' || !isMobile },
      'sui-justify-center'
    ),
    messageContainer: classNames(
      'sui-flex',
      'sui-justify-center',
      'sui-items-center',
      { 'sui-h3-display': fontSize === 'small' && !isMobile },
      { 'sui-h4-display': fontSize === 'extraSmall' && !isMobile },
      { 'sui-h4-display': fontSize === 'small' && isMobile },
      { 'sui-h5-display': fontSize === 'extraSmall' && isMobile },
      { 'sui-whitespace-nowrap': !isMobile }
    )
  };

  const { messagingString, timeString } = timeStringAndMessaging;
  const { days, hours, minutes, seconds } = timeString;
  const countdown = { days,
    hrs: hours,
    min: minutes,
    sec: seconds };
  const clockFontSize = fontSize === 'base' ? 'large' : 'inherit';

  return (
    <div data-component="TimerEvent" className="sui-@container">
      <div className={style.messageContainer}>
        <div hidden={!shouldIncludeSvg}>
          <span className={style.timer}>
            <Clock size={clockFontSize} />
          </span>
        </div>
        <span className={style.message}>
          {(messagingString && shouldUseMessagingString) && (
            messagingString
          )}
        </span>
      </div>
      <div className={style.timerContainer}>
        {Object.entries(countdown).map(([key, value]) => {
          return (
            <span className={style.countdown} key={key}> 
              <span className="sui-pr-1">{value}</span>
              <span className={key !== 'sec' && 'sui-pr-3'}>{key}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

TimerEvent.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show clock image
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show orage colour text
   */
  invertText: PropTypes.bool,
  /**
   * To show message and time
   */
  /**
   * To show 1.4em text
   */
  fontSize: PropTypes.string,
  timeStringAndMessaging: PropTypes.oneOfType([
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.string
    }),
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.element
    })
  ])
};

TimerEvent.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  invertText: false,
  fontSize: 'base',
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerEvent;
