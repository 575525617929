import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExperienceContext, useConfigService, useStore } from '@thd-nucleus/experience-context';
import {
  arrayOf,
  number,
  bool,
  params,
  shape,
  string,
  useDataModel
} from '@thd-nucleus/data-sources';
import { Link, Popover, Typography } from '@one-thd/sui-atomic-components';
import { Clock } from '@one-thd/sui-icons';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  getEndDateWithTimezoneOffset,
  getLongDescription,
  getPromoToDisplay,
  getShortDescription,
  getSpecialBuy,
  getStartDate,
  hasSpecialBuy
} from './util';
import './promotion.style.scss';
import { analyticsPromoClickEvent } from '../utils/utils';
import { PromoModelProvider } from '../contexts/PromoModelProvider';
import { PromoMessageProvider } from '../contexts/PromoMessageProvider';
import { PromoMessaging } from './PromoMessaging';
import { CONDITIONAL_PROMO_PAGES } from '../constants';

const Promotion = ({ itemId, hideConditionalPromoDescription, pageType }) => {
  const { channel } = useContext(ExperienceContext);
  const { isExchangeCustomer } = useThdCustomer() || {};
  const { storeId } = useStore();
  const [mounted, setMounted] = useState(false);
  const hiddenPromosConfig = useConfigService('fs:pip-hidden-promotion-types');
  const isBXG1Enabled = useConfigService('fs:promotion-products--bxg1Enabled');
  const isPlpPromoMessageEnabled = useConfigService('fs:promotion--plpPromoMessageEnabled');
  const hiddenPromos = hiddenPromosConfig
    ? JSON.parse(hiddenPromosConfig)
    : [];

  useEffect(() => {
    setMounted(true);
  }, []);

  const { data } = useDataModel('product', { variables: { itemId, storeId } });

  const promotion = getPromoToDisplay({ data, hiddenPromos, hideConditionalPromoDescription });
  const conditionalPromo = data?.product?.pricing?.conditionalPromotions?.[0];
  const isSpecialBuy = hasSpecialBuy(promotion);
  const specialBuy = isSpecialBuy ? getSpecialBuy(promotion) : null;
  const hasPlpPromoMessaging = CONDITIONAL_PROMO_PAGES.includes(pageType) && isPlpPromoMessageEnabled && conditionalPromo;

  const shortDesc = getShortDescription(promotion);
  const longDesc = getLongDescription(promotion);
  const start = getStartDate(promotion);
  const end = getEndDateWithTimezoneOffset(promotion, isSpecialBuy);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!promotion && !hasPlpPromoMessaging) { return null; }

  return (
    <div data-testid="promotion" data-component="Promotion">
      {isSpecialBuy && (
        <div className="sui-flex">
          <div className="sui-inline-block sui-align-baseline sui-fill-brand sui-w-6 sui-h-6 sui-pr-8 sui-pt-2">
            <Clock size="regular" color="brand" />
          </div>
          <div className="sui-pl-1">
            <Typography weight="bold">Special Buy ends in&nbsp;
              <div className="sui-inline-block">
                {mounted && (
                  <CountdownTimer
                    isPromotionTimer
                    endDate={end}
                  />
                )}
              </div>
            </Typography>
            {!isExchangeCustomer && (
              <div className="sui-block">
                <Link href={specialBuy.link} target={specialBuy.link} color="info" underline="hover">
                  {specialBuy.description}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
      {(!isSpecialBuy && !hasPlpPromoMessaging) && (
        <div className="promotion">
          <Typography
            variant="body-base"
            color="info"
            onClick={(event) => {
              event.preventDefault();
              setAnchorEl(event.currentTarget);
              analyticsPromoClickEvent();
            }}
          >{shortDesc}
          </Typography>
          <Popover
            id={open ? 'promotion-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            placement="top"
            title="Promotion Details"
          >
            <Typography variant="body-xs">
              <div data-component="PromotionTooltip">
                <b>Promotion Details</b>
                <div className="promotion__tooltip">{`Price Valid: ${start} - ${end}`}</div>
                <div>{longDesc || shortDesc}</div>
              </div>
            </Typography>
          </Popover>
        </div>
      )}
      {hasPlpPromoMessaging && (
        <PromoModelProvider
          itemId={itemId}
          promotion={conditionalPromo}
          isBXG1Enabled={isBXG1Enabled}
        >
          <PromoMessageProvider>
            <PromoMessaging />
          </PromoMessageProvider>
        </PromoModelProvider>
      )}
    </div>
  );
};

Promotion.displayName = 'Promotion';

Promotion.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      preferredPriceFlag: bool(),
      promotion: shape({
        dates: shape({
          start: string(),
          end: string()
        }),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        experienceTag: string(),
        subExperienceTag: string()
      }),
      conditionalPromotions: arrayOf(shape({
        promotionId: string(),
        skuItemGroup: string(),
        promotionTags: arrayOf(string()),
        eligibilityCriteria: arrayOf(shape({
          itemGroup: string(),
          minThresholdVal: number({ float: true }),
          thresholdType: string()
        })),
        reward: shape({
          tiers: arrayOf(shape({
            minThresholdVal: number({ float: true }),
            thresholdType: string(),
            rewardVal: number({ float: true }),
            rewardType: string(),
            rewardLevel: string(),
            maxAllowedRewardAmount: number({ float: true })
          }))
        })
      }))
    })
  })
};

Promotion.propTypes = {
  /** The item */
  itemId: PropTypes.string.isRequired,
  /** To use the config service to hide certain promos */
  hideConditionalPromoDescription: PropTypes.bool,
  /** Used to hide PLP messaging if we are on PIP */
  pageType: PropTypes.string
};

Promotion.defaultProps = {
  hideConditionalPromoDescription: false,
  pageType: 'PIP'
};

export default Promotion;
