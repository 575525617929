import React from 'react';
import { Row, Col, Placeholder, Carousel } from '@thd-olt-component-react/core-ui';
import './transactional-placeholder.scss';

function returnKeyTextValue(key) {
  return `text-${key}`;
}

function returnKeyTextValueForPod(key) {
  return `pod-${key}`;
}

function displayPlaceholder() {
  return () => (
    <Row>
      <Col className="transactional-placeholder__product-pod-image">
        <Placeholder height="100%" type="text" />
      </Col>
      <Col>
        {Array.from({ length: 6 }).fill('').map((_, key) => (
          <Placeholder
            key={returnKeyTextValue(key)}
            type="text"
            className="transactional-placeholder__product-pod-text-block"
          />
        ))}
      </Col>
    </Row>
  );
}

export const TransactionalPlaceholder = () => {
  const ProductPodPlaceholder = displayPlaceholder();

  return (
    <Row data-component="PackagesMiniTransactionalPlaceholder">
      <Row>
        <Col className="transactional-placeholder__heading-container">
          <Placeholder height="100%" type="text" />
        </Col>
      </Row>
      <Col className="transactional-placeholder__package-image-container" xs="5" sm="4">
        <Placeholder height="100%" type="text" />
      </Col>
      <Col xs="7" sm="8" className="transactional-placeholder__transactional-container">
        <Carousel
          breakpoints={{
            sm: { slidesPerGroup: 2, slidesPerView: 2 },
            md: { slidesPerGroup: 2, slidesPerView: 2 },
            lg: { slidesPerGroup: 4, slidesPerView: 4 },
            xl: { slidesPerGroup: 4, slidesPerView: 4 }
          }}
        >
          {Array.from({ length: 5 }).fill('').map((_, key) => (
            <ProductPodPlaceholder key={returnKeyTextValueForPod(key)} />
          ))}
        </Carousel>

        <Col className="transactional-placeholder__package-summary">
          <Placeholder height="100%" type="text" />
        </Col>
      </Col>
    </Row>
  );
};

TransactionalPlaceholder.displayName = 'PackagesMiniPlaceholder';
