import {
  params, string, arrayOf, shape
} from '@thd-nucleus/data-sources';

const BreadCrumbType = shape({
  dimensionName: string(),
  label: string()
});

export const dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    })
  }),
  searchModel: params({ keyword: string(), navParam: string() }).shape({
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    }),
    dimensions: arrayOf(shape({
      label: string(),
      refinements: arrayOf(shape({
        refinementKey: string(),
        label: string()
      }))
    }))
  })
};