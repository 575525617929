import React from 'react';
import {
  Alert, Button, DrawerBody, DrawerFooter, Link
} from '@one-thd/sui-atomic-components';
import { string, func, shape } from 'prop-types';

const AddToListSuccess = ({ newListDetails, onClose }) => {
  return (
    <>
      <DrawerBody>
        <Alert status="success">
          Your item has been added to your list:&nbsp;
          <Link title={newListDetails.listName} href={`/list/view/details/${newListDetails.listId}`}>
            {newListDetails.listName}
          </Link>
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <Button
          fullWidth
          variant="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DrawerFooter>
    </>
  );
};

AddToListSuccess.propTypes = {
  newListDetails: shape({
    listId: string,
    listName: string,
  }).isRequired,
  onClose: func.isRequired
};

AddToListSuccess.displayName = 'AddToListSuccess';

export { AddToListSuccess };
