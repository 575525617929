/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import {
  bool as boolType,
  string as stringType,
  shape,
  object,
  oneOfType
} from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import className from 'classnames';
import { dynamicRecsDataModel } from '../dataModel';
import DynamicRecs from '../dynamic-recs/DynamicRecs';
import {
  isLoading,
  getDrecsImpressionID,
  getDrecsComponent,
  getDrecsimpressionType,
  getImpressionsNameValue,
  getCollectionTitle,
  getCollectionInitialIndexValue,
  useTabCountConfigValue,
  getAnalyticImpressionData
} from '../utils/helpers';
import { CollectionCarousel } from '../core/CollectionCarousel';
import { ProductPodV7Carousel } from '../core/ProductPodV7Carousel';
import { publish } from '../utils/publisher';

export const TntComponent = (props) => {
  const {
    queryProps,
    tntData,
    storeInfo,
    recsprops,
    impressionData,
    showLoading,
    isCollection,
    anchorImage,
    showColUpLevel,
    isBatteryCollection,
    hideSwatches,
    hideATC,
    hideFavorites,
    parent,
    zoneName,
    zonePosition,
    itemIds,
    isCategoryTab,
    isIRG,
    name
  } = props;
  const tabCountConfigValue = useTabCountConfigValue(showColUpLevel);
  const [impressionCategoryName, setImpressionCategoryName] = useState('');
  const [impressionActiveIndex, setImpressionActiveIndex] = useState('');
  const { storeId, storeZip: zipCode, isLocalized, membershipInformation } = storeInfo;
  const recsSchema = tntData?.schemaName;
  const anchor = queryProps?.anchor;
  const mobile = queryProps?.vars?.variables?.appId === 'mobile';
  const analyticsParent = tntData?.isTNT && tntData?.isDts ? `recs-dts-product-pod-carousel-${recsSchema}` : `recs-product-pod-carousel-${recsSchema}`;
  const loadedStoreInfo = useRef({ storeId, zipCode });
  const batteryTabName = useRef([]);
  const initialBatteryTitle = useRef('');
  const [newCategoryGuid, setNewCategoryGuid] = useState(queryProps?.vars);
  const hasRun = useRef(false);

  const {
    data, variables, loading, error, refetch
  } = useDataModel(queryProps?.qName, newCategoryGuid);

  const productsCnt = tntData?.isDts ? data?.dts?.products?.length : data?.recs?.products?.length;
  const productsData = tntData?.isDts ? data?.dts : data?.recs;

  const triggerTntAnalytics = (fallBackTrue) => {
    publish({
      componentName: 'recs-tnt',
      eventName: 'fallback-analytics',
      data: {
        testApiValue: recsSchema,
        fallbackValue: fallBackTrue || 'f',
        errorValue: (typeof error === 'undefined') ? 'n' : 'y',
        productsCntValue: productsCnt || 0
      }
    });
  };

  useEffect(() => {
    hasRun.current = false;
    setNewCategoryGuid({
      ...queryProps?.vars,
      variables: {
        ...queryProps?.vars?.variables,
        anchorId: queryProps?.anchor
      }
    });
  }, [queryProps?.anchor]);

  useEffect(() => {
    if (!loading && storeId !== '8119' && !hasRun.current && productsCnt > 0) {
      triggerTntAnalytics();
      hasRun.current = true;
    } else if (!loading && storeId !== '8119' && !hasRun.current && (error || !productsCnt > 0 || !productsData)) {
      triggerTntAnalytics('t');
      hasRun.current = true;
    }
    // this code executes only on the initial render or initial calls because
    // battery collection tab clicks calls has only 1 category data
    // For pipcollection  and IRG tabs click, we don't chnage loading value
    if (!loading && !error && productsData?.categories !== null) {
      if (isIRG && productsData?.categories.length > 1) {
        setImpressionCategoryName(getCollectionTitle(isIRG, isCategoryTab));
        setImpressionActiveIndex(getCollectionInitialIndexValue(isIRG, isCategoryTab));
      } else if (!mobile && productsData?.categories?.length >= tabCountConfigValue) {
        setImpressionCategoryName(getCollectionTitle(isIRG, isCategoryTab, isCollection));
        setImpressionActiveIndex(getCollectionInitialIndexValue(isIRG, isCategoryTab, isCollection));
      }
    }

    // Saving title of the battery collection container to handle impressions NAME value
    // for the battery collections fallback scenario
    if (!mobile && !loading && !error && isBatteryCollection && productsData?.metadata?.collectionName) {
      initialBatteryTitle.current = productsData?.metadata?.collectionName || '';
    }
  }
  , [loading]);
  useEffect(() => {
    if (itemIds && itemIds?.length > 0) {
      refetch();
    }
  }, [itemIds]);

  const setCategoryGuid = useCallback(
    (event, guid, categoryName, changeActiveIndex, index) => {
      event.preventDefault();
      const guidAnchorId = queryProps?.anchor + guid;
      if (queryProps?.vars?.variables) {
        queryProps.vars.variables.anchorId = guidAnchorId;
      }
      batteryTabName.current = categoryName;
      changeActiveIndex(index);
      setNewCategoryGuid({
        ...queryProps?.vars,
        variables: {
          ...queryProps?.vars?.variables,
          anchorId: guidAnchorId
        }
      });
      setImpressionCategoryName(categoryName);
      setImpressionActiveIndex(index + 1);
    },
    []
  );
  const setVisualTabsCategoriesData = useCallback(
    (categoryName, index) => {
      setImpressionCategoryName(categoryName);
      setImpressionActiveIndex(index + 1);
    },
    []
  );

  if (!loading && storeId !== '8119' && newCategoryGuid?.variables?.anchorId === anchor && (error || !productsCnt > 0 || !productsData)) {
    return <DynamicRecs {...recsprops} />;
  }

  if (data && !loading && isLocalized) {
    loadedStoreInfo.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true
    };
  }
  const anchorIdForAnalytics = anchor?.split(',')[0];
  const wrapperClasses = className(
    'tnt-container',
    'sui-p-4',
    recsSchema,
    {
      loading: showLoading && loading,
    }
  );
  let impressionName = '';

  if (!loading) {
    impressionName = getImpressionsNameValue(
      isCollection,
      showColUpLevel,
      isCategoryTab,
      isIRG,
      productsData?.metadata,
      productsData?.products,
      loading,
    tntData?.tntTitle
    ) || initialBatteryTitle.current;
  }
  return (
    <ImpressionProvider
      data={{
        id: getDrecsImpressionID(impressionData),
        component: getDrecsComponent(impressionData, 'TNTComponent'),
        name: impressionName,
        type: getDrecsimpressionType(impressionData),
        recType: recsSchema || '',
        position: '',
        category: impressionCategoryName,
        categoryPostion: impressionActiveIndex,
        ...(name ? { sectionSlotID: name } : {}),
      }}
    >
      <div
        id={recsSchema}
        className={wrapperClasses}
        data-type="container"
        data-component={`tnt-container-${recsSchema}`}
      >
        <meta data-prop="name" content={recsSchema} />
        {isCollection
          ? (
            <CollectionCarousel
              anchorId={anchor}
              anchorImage={anchorImage}
              itemClass="fixed-item"
              showColUpLevel={showColUpLevel}
              scheme={recsSchema}
              isBatteryCollection={isBatteryCollection}
              loading={isLoading(data, loading)}
              setCategoryGuid={setCategoryGuid}
              setVisualTabsCategoriesData={setVisualTabsCategoriesData}
              tabClick={batteryTabName?.current}
              data={productsData}
              hideSwatches={hideSwatches}
              hideATC={hideATC}
              hideFavorites={hideFavorites}
              storeId={loadedStoreInfo?.current?.storeId}
              membershipInformation={membershipInformation}
              zipCode={loadedStoreInfo?.current?.zipCode}
              parent={parent || analyticsParent}
              analyticsAnchorProductSku={anchorIdForAnalytics}
              tntTitle={tntData?.tntTitle}
              tabCountConfigValue={tabCountConfigValue}
              analyticImpressionData={getAnalyticImpressionData('DynamicRecs', impressionName)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )
          : (
            <ProductPodV7Carousel
              anchorId={anchor}
              anchorImage={anchorImage}
              itemClass="fixed-item"
              scheme={recsSchema}
              loading={isLoading(data, loading)}
              data={productsData}
              hideSwatches={hideSwatches}
              hideATC={hideATC}
              hideFavorites={hideFavorites}
              storeId={loadedStoreInfo?.current?.storeId}
              membershipInformation={membershipInformation}
              zipCode={loadedStoreInfo?.current?.zipCode}
              parent={parent || analyticsParent}
              zoneName={zoneName}
              zonePosition={zonePosition}
              podResults={productsData?.products?.length}
              analyticsAnchorProductSku={anchorIdForAnalytics}
              tntTitle={tntData?.tntTitle}
              setVisualTabsCategoriesData={setVisualTabsCategoriesData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
      </div>
    </ImpressionProvider>

  );
};

TntComponent.dataModel = dynamicRecsDataModel;

TntComponent.displayName = 'TntComponent';
TntComponent.propTypes = {
  queryProps: oneOfType([object]).isRequired,
  tntData: oneOfType([object]).isRequired,
  storeInfo: oneOfType([object]).isRequired,
  recsprops: oneOfType([object]).isRequired,
  impressionData: shape({
    id: stringType,
    component: stringType,
    type: stringType
  }),
  showLoading: boolType,
  isCollection: boolType,
  anchorImage: stringType,
  showColUpLevel: boolType,
  isBatteryCollection: boolType,
  hideATC: boolType,
  hideFavorites: boolType,
  zoneName: stringType,
  zonePosition: stringType,
  itemIds: stringType,
  isCategoryTab: boolType,
  isIRG: boolType
};
TntComponent.defaultProps = {
  impressionData: {
    id: null,
    component: null,
    type: null
  },
  showLoading: true,
  isCollection: false,
  anchorImage: null,
  showColUpLevel: false,
  isBatteryCollection: false,
  hideATC: false,
  hideFavorites: true,
  zoneName: '',
  zonePosition: '',
  itemIds: '',
  isCategoryTab: false,
  isIRG: false
};