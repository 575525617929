import { MESSAGE_NUMBER_WORD_MAP } from './constants';
// dropZeros removes decimals if price has no cents
// dropZeros:true  = 239 -> $239 || 239.23 -> $239.23
// dropZeros:false = 239 -> $239.00 || 239.23 -> $239.23
export const formatPrice = (price, dropZeros) => {
  if (price == null || price <= 0) { return ''; }
  if (price < 1 && price !== 0) { return `${(price * 100).toFixed()}¢`; }
  if (dropZeros && price % 1 === 0) { return `$${parseFloat(price)}`; }
  return `$${parseFloat(price).toFixed(2)}`;
};

export const formattedReward = ({
  rewardVal
}, dropZeros, rewardType) => {
  if (rewardType === 'PERCNTOFF') { return `${rewardVal}%`; }
  return formatPrice(rewardVal, dropZeros);
};

export const toWord = (num) => {
  return MESSAGE_NUMBER_WORD_MAP[num] || num;
};
