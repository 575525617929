import React, { useEffect, useState, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId, useConfigService } from '@thd-nucleus/experience-context';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import Helper from './Helper';
import { componentReady } from '../publisher';
import { MediaGalleryMobile as MobileMediaGallery } from './subcomponents';
import { MediaHighlight } from './subcomponents/MediaHighlight/MediaHighlight';
import { dataModel } from './dataModel';
import { mediaGalleryMobilePropTypes, mediaGalleryMobileDefaultPropTypes } from './MediaGalleryPropTypes';
import { MediaGalleryLoader } from './subcomponents/MediaGalleryLoader/MediaGalleryLoader';
import { useDecisionEngine } from '../hooks/DecisionEngine/useDecisionEngine';

export const MediaGalleryMobile = ({
  collectionId,
  highlight,
  itemId,
  configId,
  overrideData,
  onMediaClick,
  showLoader,
  firstItemFetchPriority
}) => {
  const { media: overrideMedia, ...overrideDataForOverlay } = overrideData || {};
  const { externalMedia } = useLifeCycleEventBus('configurator.configurator_media_gallery_event');
  const mediaMapRef = useRef(null);
  const storeId = useStoreId();
  const [showAvataarAR, setShowAvataarAR] = useState(false);
  const autoPlayInViewportEnabled = useConfigService('fs-prop:video-player--autoplay-on-viewport-mw-ab-enabled');
  const showAvataarDefault = Helper.containAvataarId(itemId);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.avataar.click', (payload) => {
      setShowAvataarAR(payload?.output?.showAvataar);
    });
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('media-gallery.avataar.click');
    };
  }, []);

  useEffect(() => {
    if (itemId) {
      componentReady({ itemId });
    }
  }, []);

  const productData = useDataModel('product', {
    variables: {
      itemId,
      configId,
      storeId,
      skipPaintDetails: !configId
    },
    skip: !itemId || overrideData
  });
  const collectionData = useDataModel('collection', {
    variables: { collectionId, storeId },
    skip: !collectionId || overrideData
  });

  const { product } = productData?.data || {};
  const { collection } = collectionData?.data || {};
  const { metadata } = collection || {};

  const decisionEngineImageType = useDecisionEngine({ media: product?.media });

  const { displayedMedia, ...apiResults } = Helper.getDisplayedMedia({
    decisionEngineImageType,
    isMobile: false,
    itemId,
    productData,
    collectionId,
    collectionData,
    externalMedia,
    overrideData,
    placeholder: showLoader && <MediaGalleryLoader isMobile />,
    showAvataarDefault
  });

  const { augmentedRealityIndex } = displayedMedia;

  if (apiResults.apiState) {
    return apiResults.returnStatement;
  }

  if (highlight) {
    return <MediaHighlight itemId={itemId} />;
  }

  const handleMediaClick = ({ media: clickedMedia }) => {
    if (onMediaClick) {
      onMediaClick({ media: clickedMedia });
    }
  };

  mediaMapRef.current = displayedMedia;

  return (
    <MobileMediaGallery
      media={mediaMapRef.current}
      onMediaClick={handleMediaClick}
      product={product || metadata}
      overrideData={overrideDataForOverlay}
      firstItemFetchPriority={firstItemFetchPriority}
      autoPlayInViewportEnabled={autoPlayInViewportEnabled}
      itemId={itemId}
      showAvataarAR={showAvataarAR}
      showAvataarDefault={showAvataarDefault}
      augmentedRealityIndex={augmentedRealityIndex}
      onAvataarButtonClick={() => setShowAvataarAR(false)}
    />
  );
};

MediaGalleryMobile.displayName = 'MediaGalleryMobile';

MediaGalleryMobile.propTypes = mediaGalleryMobilePropTypes;

MediaGalleryMobile.defaultProps = mediaGalleryMobileDefaultPropTypes;

MediaGalleryMobile.dataModel = dataModel;
