import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@one-thd/sui-carousel';
import {
  params,
  extend,
  shape as shapeType,
  string as stringType,
  number as numberType,
  arrayOf as arrayType,
  customType,
  useDataModel,
  QueryProvider,
  hoist
} from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { AddToProductPod } from '../addto-product-pod/AddToProductPod';
import { PreviouslyPurchasedPlaceholder } from './PreviouslyPurchasedPlaceholder';

const HoistedPurchasedResultWrapper = ({ children }) => children;

HoistedPurchasedResultWrapper.dataModel = extend(AddToProductPod);

export const PreviouslyPurchased = ({
  groupsExist, listsExist, updateList, listId
}) => {

  const { svocID } = useThdCustomer();
  const store = useStore();
  const { storeId, membershipInformation } = store;
  const { deliveryZip, channel } = useContext(ExperienceContext);

  const options = {
    variables: {
      Nm: 'LAST6MONTHS',
      Nao: '0',
      userId: svocID
    },
    context: { withAuth: true },
    ssr: false
  };

  const { data: biaData,
    loading: biaLoading,
    error: biaErrors,
    variables: biavariables } = useDataModel('buyItAgain', options);
  const purchasedItemIds = biaData?.buyItAgain?.purchasedItemIds || [];
  const searchNavOptions = {
    variables: {
      itemIds: [...purchasedItemIds],
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      additionalSearchParams: {
        deliveryZip
      }
    },
    skip: !purchasedItemIds?.length,
    ssr: false
  };
  const response = useDataModel('searchModel', searchNavOptions);
  const {
    data, loading: searchLoading, variables, error: searchErrors,
  } = response;
  const { products = [], metadata } = data?.searchModel || {};
  if (biaLoading || searchLoading) {
    return (
      <PreviouslyPurchasedPlaceholder />
    );
  }

  if (biaErrors || searchErrors || products.length === 0) {
    return null;
  }

  return (
    <div data-testid="previously-purchased-carousel" className="sui-pt-4">
      <QueryProvider
        cacheKey="Previously-Purchased"
        dataSource="searchNav"
        defaultVariables={{
          storeId,
          loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null
        }}
      >
        <HoistedPurchasedResultWrapper>
          <Carousel
            title="Previously Purchased"
            breakpoints={
              {
                sm: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                md: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                lg: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                xl: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                }
              }
            }
          >
            {products.map((product, index) => {
              return (
                <div className="sui-flex" key={index}>
                  <AddToProductPod
                    itemId={product.itemId}
                    index={index}
                    parent="ProjectFormsPreviouslyPurchased"
                    isMobile={channel === 'mobile'}
                    groupsExist={groupsExist}
                    updateList={updateList}
                  />
                </div>
              );
            })}
          </Carousel>
        </HoistedPurchasedResultWrapper>
      </QueryProvider>
    </div>
  );
};

PreviouslyPurchased.dataModel = extend(
  {
    buyItAgain: params({
      userId: stringType(),
      Nm: customType('BIADATERANGE').enum(['YESTERDAY', 'LAST7DAYS', 'LAST30DAYS',
        'THISMONTH', 'LASTMONTH', 'LAST3MONTHS', 'LAST6MONTHS', 'LAST9MONTHS', 'LAST12MONTHS', 'LAST25MONTHS'],
      'LAST6MONTHS'),
      Nao: stringType(),
    }).shape({
      pageOffSet: numberType(),
      totalItemCount: numberType(),
      returnedItemCount: numberType(),
      purchasedItemIds: arrayType(stringType())
    }),
  },
  {
    searchModel: params({
      itemIds: arrayType(stringType()),
      storeId: stringType(),
      loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
        svocID: stringType(),
        programTiers: arrayType(
          shapeType({
            tier: stringType(),
            program: stringType()
          })
        )
      }),
      additionalSearchParams: customType('AdditionalParams').shape({
        deliveryZip: stringType(),
      })
    }).shape({
      products: params({ startIndex: numberType() }).arrayOf(
        AddToProductPod.dataModel.product
      )
    }),
  },
  AddToProductPod
);

PreviouslyPurchased.propTypes = {
  groupsExist: PropTypes.bool,
  updateList: PropTypes.func,
};

PreviouslyPurchased.defaultProps = {
  groupsExist: false,
  updateList: () => {},
};
