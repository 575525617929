import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ownerDocument from '@one-thd/sui-atomic-components/dist/private/utils/ownerDocument';
import useForkRef from '@one-thd/sui-atomic-components/dist/private/hooks/useForkRef';
import { CalendarCell } from './CalendarCell';

const YearPicker = React.forwardRef((props, ref) => {
  const {
    autoFocus = false,
    year,
    page,
    onChange
  } = props;

  const { current: currentYear } = useRef(dayjs().year());
  const gridRef = useRef(null);
  const handleRef = useForkRef(gridRef, ref);

  const nextItem = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.firstChild;
    }
    if (currentFocus && currentFocus.nextElementSibling) {
      return currentFocus.nextElementSibling;
    }
    return grid.firstChild;
  };

  const previousItem = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.lastChild;
    }
    if (currentFocus && currentFocus.previousElementSibling) {
      return currentFocus.previousElementSibling;
    }
    return grid.lastChild;
  };

  const nextRow = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.firstChild;
    }
    const currentIndex = Array.prototype.indexOf.call(grid.children, currentFocus);
    const nextIndex = grid.children[currentIndex + 3];
    if (nextIndex) {
      return nextIndex;
    }
    return grid.firstChild;
  };

  const previousRow = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.lastChild;
    }
    const currentIndex = Array.prototype.indexOf.call(grid.children, currentFocus);
    const nextIndex = grid.children[currentIndex - 3];
    if (nextIndex) {
      return nextIndex;
    }
    return grid.lastChild;
  };

  const moveFocus = (grid, currentFocus, traversalFunction, fallBackwards = false) => {
    let wrappedOnce = false;
    let nextFocus = traversalFunction(grid, currentFocus);

    while (nextFocus) {
      // Prevent infinite loop.
      if (nextFocus === grid.firstChild) {
        if (wrappedOnce) {
          return;
        }
        wrappedOnce = true;
      }

      // Same logic as useAutocomplete.js
      const nextFocusDisabled = nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

      if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
        // Move to the next element.
        if (fallBackwards) {
          nextFocus = previousItem(grid, nextFocus);
        } else {
          nextFocus = nextItem(grid, nextFocus);
        }
      } else {
        nextFocus.focus();
        return;
      }
    }
  };

  const handleKeyDown = (event) => {
    const grid = gridRef.current;
    const currentFocus = ownerDocument(grid).activeElement;

    const nextItemKey = 'ArrowRight';
    const previousItemKey = 'ArrowLeft';
    const nextRowKey = 'ArrowDown';
    const previousRowKey = 'ArrowUp';

    switch (event.key) {
      case nextItemKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, nextItem);
        break;
      case previousItemKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, previousItem, true);
        break;
      case nextRowKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, nextRow);
        break;
      case previousRowKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, previousRow, true);
        break;
      default:
        break;
    }
  };

  const displayYears = () => {
    const position = year + (page * 12);
    const numberOfItems = [...Array(12)];
    const yearArray = numberOfItems.map((el, index) => position + index);
    return yearArray;
  };

  const gridClasses = classNames('sui-grid sui-grid-cols-3');

  return (
    <div
      role="grid"
      className={gridClasses}
      ref={handleRef}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      {displayYears().map((el) => (
        <CalendarCell
          key={el}
          selected={el === year}
          current={el === currentYear}
          focusable={year}
          granularity="year"
          isViewFocused={autoFocus}
          size="large"
          value={el}
          onClick={onChange}
        >
          {el}
        </CalendarCell>
      ))}
    </div>
  );
});

YearPicker.displayName = 'YearPicker';

YearPicker.propTypes = {
  autoFocus: PropTypes.bool,
  year: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func
};

YearPicker.defaultProps = {};

export { YearPicker };
