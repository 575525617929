export const ownerDocument = (node) => {
  if (node) {
    return node.ownerDocument;
  }
  return document;
};

export const ownerWindow = (node) => {
  const doc = ownerDocument(node);
  return doc.defaultView || window;
};

export default function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

export const isValidAnchorElement = (anchorElement) => {
  return typeof anchorElement?.getBoundingClientRect === 'function';
};
