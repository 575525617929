import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import createTypography from '@one-thd/sui-atomic-components/dist/utils/createTypography';
import { ButtonBase } from '@one-thd/sui-atomic-components/dist/button/ButtonBase';

const CalendarWeekHeader = () => {
  const headerTypography = createTypography({
    variant: 'sm',
    weight: 'bold'
  });

  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekDaysAbbr = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const calendarWeekClasses = classNames('sui-grid sui-grid-cols-7 sui-text-center sui-mb-2px');
  const headerClasses = classNames('sui-flex sui-items-center sui-justify-center sui-h-5 sui-w-full sui-text-sm sui-font-bold sui-text-primary', headerTypography.classes);
  return (
    <thead>
      <tr className={calendarWeekClasses}>
        {weekDaysAbbr.map((day, index) => (
          <th
            abbr={weekDays[index]}
            role="columnheader"
            className={headerClasses}
            key={day}
          >
            {day}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export { CalendarWeekHeader };