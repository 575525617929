import React, { useContext } from 'react';
import {
  Popover,
  FormController,
  RadioGroup,
  Radio,
  SelectionControlLabel,
  Typography,
} from '@one-thd/sui-atomic-components';

import PropTypes from 'prop-types';
import { MOBILE } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ENGLISH_LANG_CODE, SPANISH_LANG_CODE } from '../commons/constants';
/* eslint-disable max-len */

export const ControllerPopover = ({ anchor: anchorEl, close, preferredLanguage, handleChange }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === MOBILE;
  const zIndexclass = isMobile ? 'sui-z-tooltip' : 'sui-z-800';
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={close}
      placement="bottom-end"
      title="Select Language"
      className={zIndexclass}
    >
      <div className={`${!isMobile && 'sui-w-80'} sui-pr-1`} translate="no">
        <FormController>
          <RadioGroup
            orientation="horizontal"
            aria-labelledby="translation-language-options"
            name="horizontal-radio-buttons-group"
            value={preferredLanguage}
            onChange={handleChange}
          >
            <span className={`${isMobile ? 'sui-mr-20' : 'sui-mr-10'}`}>
              <SelectionControlLabel value={ENGLISH_LANG_CODE} label="English">
                <Radio />
              </SelectionControlLabel>
            </span>
            <SelectionControlLabel value={SPANISH_LANG_CODE} label="Español">
              <Radio />
            </SelectionControlLabel>
          </RadioGroup>
        </FormController>
        <Typography variant="body-xs">
          {preferredLanguage === ENGLISH_LANG_CODE
            ? (
              <>
                <strong>Disclaimer:</strong> To provide a better online shopping experience for customers who use Spanish, we are running a limited pilot to use generative AI to provide Spanish translations of information, Q&A, and reviews about select products. We aim to have this feature be as accurate as possible; however, always read labels, warnings, and directions and other information provided with a product before using a product. For additional information about our products or services, please review the manufacturer&apos;s documentation or contact The Home Depot customer service.
              </>
            )
            : (
              <>
                <strong>Descargo de responsabilidad:</strong> Para brindar una mejor experiencia de compra en línea a los clientes que usan español, estamos ejecutando una prueba piloto limitada para usar IA generativa para proporcionar traducciones al español de información, preguntas y respuestas y reseñas sobre productos seleccionados. Nuestro objetivo es que esta funcionalidad sea lo más precisa posible; sin embargo, lea siempre las etiquetas, advertencias e instrucciones y otra información proporcionada con un producto antes de usarlo. Para obtener información adicional sobre nuestros productos o servicios, revise la documentación del fabricante o comuníquese con el servicio de atención al cliente de The Home Depot.
              </>
            )}
        </Typography>
      </div>
    </Popover>
  );
};

ControllerPopover.propTypes = {
  anchor: PropTypes.element,
  preferredLanguage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

ControllerPopover.defaultProps = {
  anchor: null,
  preferredLanguage: ENGLISH_LANG_CODE,
};
