import React, { useState, useEffect, useRef } from 'react';
import { func, bool, string } from 'prop-types';
import {
  params,
  extend,
  string as stringType,
  useLazyDataModel,
  shape as shapeType,
  customType
} from '@thd-nucleus/data-sources';
import {
  Button, LoadingButton, DrawerBody, DrawerFooter, Typography
} from '@one-thd/sui-atomic-components';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { triggerAnalytics } from '../helpers';
import {
  PROJECT_ALREADY_EXISTS_ERROR,
  PROJECT_COPY,
  PROJECT_CREATE_CLICK,
  PROJECT_CREATE_SUCCESS
} from '../constants';
import { ProjectInformationForm } from '../ProjectForms/ProjectInformationForm';
import { JobsiteInformationForm } from '../ProjectForms/JobsiteInformationForm';

export const CreateAProjectContent = ({
  handleClose,
  onCreate,
  isCopyProject,
  projectId,
  isSharedProject,
  isAddToDrawer
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectInfoError, setIsProjectInfoError] = useState(false);
  const [isJobsiteError, setIsJobsiteError] = useState(false);
  const [projectInformation, setProjectInformation] = useState({});
  const [jobsiteInformation, setJobsiteInformation] = useState({});
  const [duplicateNameError, setDuplicateNameError] = useState('');
  const { svocID } = useThdCustomer();
  const projectForm = useRef();

  const [createProject, createProjectResponse] = useLazyDataModel('createProject', {
    variables: {},
    context: { withAuth: true },
    fetchPolicy: 'no-cache',
  });

  const onClose = (isCreated = false) => {
    setProjectInformation({});
    setJobsiteInformation({});
    setShowErrors(false);
    if (isCreated) {

    }
    if (isAddToDrawer) {
      return;
    } else {
      handleClose();
    }
  }

  const handleFormSubmit = async (submitEvent) => {
    submitEvent.preventDefault();

    triggerAnalytics(PROJECT_CREATE_CLICK, {});

    const {
      estimatedStartDate,
      estimatedEndDate
    } = projectInformation;

    if (isProjectInfoError || isJobsiteError || !projectInformation.projectName) {
      setShowErrors(true);
      return;
    }

    if (isCopyProject) {
      setIsLoading(true);
    }
    let jobAddress = { ...jobsiteInformation };
    let isValidAddress = Object.keys(jobAddress).length !== 0;
    if (isValidAddress && jobAddress.zipCode === '') {
      delete jobAddress.zipCode;
      isValidAddress = false;
    }
    if (isValidAddress) {
      jobAddress.country = 'US';
      jobAddress.addressType = 'JOB_SITE';
    }
    await createProject({
      variables: {
        accountId: svocID,
        ...projectInformation,
        jobSiteAddress: !isValidAddress ? undefined : { ...jobAddress },
        estimatedStartDate:
          estimatedStartDate === undefined ? undefined : new Date(estimatedStartDate).toISOString(),
        estimatedEndDate:
          estimatedEndDate === undefined ? undefined : new Date(estimatedEndDate).toISOString()
      }
    });
  };

  const checkForAndSetAlreadyExistError = (error) => {
    const gqlErrors = error?.graphQLErrors;
    let errorMessage = '';
    gqlErrors?.forEach(function (err, index) {
      if (err?.message === PROJECT_ALREADY_EXISTS_ERROR) {
        errorMessage = PROJECT_ALREADY_EXISTS_ERROR;
      }
    });
    setDuplicateNameError(errorMessage);
  };

  useEffect(() => {
    if (createProjectResponse?.data?.createProject && createProjectResponse?.data?.createProject?.id) {
      const isType = projectInformation?.propertyType ? 'y' : 'n';
      const isDesc = projectInformation?.notes ? 'y' : 'n';
      const isAddress = jobsiteInformation?.addressLine1 ? 'y' : 'n';
      const isZip = jobsiteInformation?.zipCode ? 'y' : 'n';

      // In the format of: “projects: project created: type <y/n>: desc <y/n>: address <y/n>: zip <y/n>”
      // eslint-disable-next-line max-len
      const settingsString = `projects: project created: type ${isType}: desc ${isDesc}: address ${isAddress}: zip ${isZip}`;
      triggerAnalytics(PROJECT_CREATE_SUCCESS,
        {
          settings: settingsString,
          projectId: createProjectResponse?.data?.createProject?.id
        }
      );

      onCreate(createProjectResponse?.data?.createProject);
      onClose(true);
    } else if (createProjectResponse?.error) {
      checkForAndSetAlreadyExistError(createProjectResponse?.error);
    }
  }, [createProjectResponse?.data, createProjectResponse?.error]);

  return (
    <>
      <DrawerBody data-component="CreateAProject">
        <form
          className="sui-grid sui-gap-6"
          autoComplete="off"
          noValidate
          onSubmit={handleFormSubmit}
          ref={projectForm}
        >
          <Typography variant="body-lg" weight="bold">
            Project Information
          </Typography>
          <ProjectInformationForm
            showErrors={showErrors}
            projectInformation={projectInformation}
            setProjectInformation={setProjectInformation}
            isProjectInfoError={isProjectInfoError}
            setIsProjectInfoError={setIsProjectInfoError}
            open={open}
            duplicateNameError={duplicateNameError}
          />
          <Typography variant="body-lg" weight="bold">
            Jobsite Information
          </Typography>
          <JobsiteInformationForm
            showErrors={showErrors}
            jobsiteInformation={jobsiteInformation}
            setJobsiteInformation={setJobsiteInformation}
            isJobsiteError={isJobsiteError}
            setIsJobsiteError={setIsJobsiteError}
            open={open}
          />
        </form>
      </DrawerBody>
      <DrawerFooter position="center">
        <div className="sui-flex sui-flex-col sui-gap-2">
          <LoadingButton
            data-testid="create-project-submit-button"
            variant="primary"
            fullWidth
            loading={isLoading}
            onClick={handleFormSubmit}
            type="submit"
          > Create Project
          </LoadingButton>
          <Button
            data-testid="create-project-cancel-button"
            variant="secondary"
            fullWidth
            onClick={handleClose}
            type="submit"
          > Cancel
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

CreateAProjectContent.propTypes = {
  // callback when user clicks the form button
  // return parameters: { id, projectAccessType, project }
  onCreate: func,
  isCopyProject: bool,
  projectId: string,
  isSharedProject: bool,
  isAddToDrawer: bool
};

CreateAProjectContent.defaultProps = {
  onCreate: () => { },
  isCopyProject: false,
  projectId: null,
  isSharedProject: false,
  isAddToDrawer: false
};

CreateAProjectContent.displayName = 'CreateAProjectContent';

CreateAProjectContent.dataModel = extend(
  {
    createProject: params({
      accountId: stringType().isRequired(),
      projectName: stringType().isRequired(),
      propertyType: stringType(),
      estimatedStartDate: stringType(),
      estimatedEndDate: stringType(),
      notes: stringType(),
      jobSiteAddress: customType('JobSiteAddress').shape({
        addressType: customType('addressType').enum(['ASSORTMENT_SIP', 'FULFILLMENT', 'JOB_SITE']),
        addressLine1: stringType().isRequired(),
        addressLine2: stringType(),
        zipCode: stringType().isRequired(),
        city: stringType().isRequired(),
        state: stringType().isRequired(),
        country: stringType().isRequired()
      })
    }).mutation().shape({
      id: stringType(),
      projectName: stringType()
    })
  },
  JobsiteInformationForm
);
