import React from 'react';
import { string } from 'prop-types';

import { Row } from '@thd-olt-component-react/core-ui';

import { RENDER_VISUAL } from '../constants';
import { PackagesMiniAbTest } from './PackagesMiniAbTest';

const PackageMiniGM = ({ itemId, name }) => (
  <div data-testid="pacakge-mini-visual" className="sui-w-full">
    <PackagesMiniAbTest
      itemId={itemId}
      name={name}
      renderComponent={RENDER_VISUAL}
      layoutGroup={{
        tag: Row
      }}
    />
  </div>
);

PackageMiniGM.propTypes = {
  itemId: string.isRequired,
  name: string
};

PackageMiniGM.defaultProps = {
  name: ''
};

PackageMiniGM.displayName = 'PackageMiniGM';

export { PackageMiniGM };