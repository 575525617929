import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TypeaheadAsync } from '@thd-olt-component-react/typeahead';
import { fetchSearch, searchHystoryFormat } from '../../AddToHelpers';
import { typeaheadAPI } from './typeaheadAPI';

export const SearchProducts = ({ onSubmit }) => {
  const containerRef = useRef(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [results, setResults] = useState([]);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const searchHistory = fetchSearch();
    setResults(searchHistory);
  }, []);

  const hasHistory = Boolean(results?.length);

  const onSearch = async (typedString) => {
    setSearchTerm(undefined);
    const formatedSearchResut = searchHystoryFormat(results);
    if (formatedSearchResut?.length) return formatedSearchResut;

    if (typedString?.length > 0) {
      const typeaheadResults = await typeaheadAPI.fetch({
        searchText: typedString,
        config: {},
        recentSearches: hasHistory ? results : []
      });
      const suggest = typeaheadAPI.format(typeaheadResults?.results);
      setHasSuggestions(Boolean(suggest?.length));
      return suggest;
    }
    return formatedSearchResut;
  };

  const onFocusOut = () => {
    setAnchorElement(null);
  };

  const onSearchSubmit = (event, customEvent) => {
    const { selectedSearchTerm } = customEvent;
    setSearchTerm(selectedSearchTerm);
    onSubmit(selectedSearchTerm);
    onFocusOut();
  };

  const searchFieldProps = {
    formProps: {
      action: '#',
      autoComplete: 'off'
    },
    inputProps: {
      placeholder: 'Enter a SKU, model #, or keyword',
      name: 'keyword',
      type: 'search',
      value: searchTerm
    },
    customClasses: {
      inputContainer: null
    }
  };

  const suggestionsMenuProps = {
    anchorElement,
    open: (anchorElement !== null) && (hasSuggestions || hasHistory),
    anchorOffset: { top: 0 },
    fullScreenHeight: false,
    suggestionsMenuTitle: hasHistory ? 'Your recent searches' : null,
    suggestionsMenuFooter: null,
  };

  const resultsListProps = {
    alwaysShowArrowInResults: false,
    numberOfResultsToShow: 15
  };

  return (
    <TypeaheadAsync
      data-test
      ref={containerRef}
      searchFieldProps={searchFieldProps}
      suggestionsMenuProps={suggestionsMenuProps}
      resultsListProps={resultsListProps}
      onSubmit={onSearchSubmit}
      onFocusOut={onFocusOut}
      onInputFocus={() => setAnchorElement(containerRef.current)}
      onSearch={onSearch}
    />
  );
};

SearchProducts.displayName = 'SearchProducts';

SearchProducts.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
