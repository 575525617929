import React, { createContext, useContext } from 'react';
import { node, bool, string } from 'prop-types';

const PresentationContext = createContext({});
export const usePresentation = () => useContext(PresentationContext);

export const PresentationProvider = ({ useCondensedLayout, itemId, children }) => {
  return (
    <PresentationContext.Provider value={{ useCondensedLayout, itemId }}>
      { children }
    </PresentationContext.Provider>
  );
};

PresentationProvider.propTypes = {
  children: node.isRequired,
  useCondensedLayout: bool.isRequired,
  itemId: string
};

PresentationProvider.defaultProps = {
  itemId: null
};
