import React, { useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { params, shape, string, useDataModel } from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';

const useConfig = () => {
  const eventMessage = useConfigService('PIPEventMessage');
  const eventMessageWithTimer = useConfigService('PIPEventWithTimerMessage');
  const eventLink = useConfigService('PIPEventLink');
  const endDate = useConfigService('PIPEventEndDate');
  const backgroundImage = useConfigService('PIPEventBackgroundImage');
  const alwaysShow = useConfigService('PIPEventAlwaysShow');
  const startDate = useConfigService('PIPEventStartDate');
  const timerStartDate = useConfigService('PIPEventTimerStartDate');
  const showTimer = useConfigService('PIPActiveEventShowTimer');
  const fallbackColor = useConfigService('PIPEventFallbackColor');

  return {
    eventMessage,
    eventMessageWithTimer,
    eventLink,
    endDate,
    backgroundImage,
    alwaysShow,
    startDate,
    timerStartDate,
    showTimer,
    fallbackColor
  };
};

const getEventBadge = (badges) => {
  const itemBadges = badges?.filter((badge) => badge?.name === 'Event Badge');
  return itemBadges?.length ? itemBadges[0] : null;
};

const getEventDetails = (config, { eventLink, eventMessage, endDate, backgroundImage }) => {
  const defaultStartDate = '2024-11-30T03:00:00-04:00';
  const usedBackgroundImage = config.backgroundImage || backgroundImage;
  const usedEventMessage = config.eventMessage || eventMessage;
  const usedTimerEventMessage = config.eventMessageWithTimer || eventMessage;
  const usedEventLink = config.eventLink || eventLink;
  const usedEndDate = new Date(config.endDate || endDate).valueOf();
  const usedFallbackColor = config.fallbackColor;
  const eventStartDate = new Date(config.startDate || defaultStartDate).valueOf();
  const timerStartDate = config.timerStartDate ? new Date(config.timerStartDate).valueOf() : eventStartDate;
  const isTimerEnabled = config.showTimer || false;
  const currentDate = Date.now();

  return {
    usedBackgroundImage,
    usedEventMessage,
    usedTimerEventMessage,
    usedEventLink,
    usedEndDate,
    usedFallbackColor,
    eventStartDate,
    timerStartDate,
    isTimerEnabled,
    currentDate
  };
};

const renderLoadingSkeleton = () => (
  <Skeleton data-testid="skeleton-loader" disablePadding className="sui-h-full" SkeletonContentProps={{ grow: true }}>
    <SkeletonBlock aspect="wide" />
  </Skeleton>
);

const getStyle = (isMobile) => ({
  bannerWrapper: classNames(
    'sui-h-full',
    'sui-bg-cover',
    'sui-flex',
    'sui-items-center',
    'sui-justify-center',
    'hover:sui-no-underline',
    'sui-px-2',
    'sui-py-8',
    { 'sui-h3-display': !isMobile },
    { 'sui-h4-display': isMobile }
  ),
  bannerWithTimerWrapper: classNames(
    'sui-block',
    'sui-py-5',
    'sui-h-full',
    'sui-bg-cover',
    'hover:sui-no-underline'
  ),
  bannerText: classNames(
    'sui-uppercase',
    'sui-text-inverse',
    { '@lg:sui-text-5xl @md:sui-text-4xl @sm:sui-text-2xl sui-text-base': !isMobile }

  )
});

const renderBanner = (
  shouldShowTimer,
  style,
  usedBackgroundImage,
  usedEventLink,
  usedEventMessage,
  usedTimerEventMessage,
  usedEndDate,
  usedFallbackColor,
) => (
  <a
    aria-label="Event Countdown Timer"
    href={usedEventLink}
    className={shouldShowTimer ? style.bannerWithTimerWrapper : style.bannerWrapper}
    style={{ backgroundImage: `url(${usedBackgroundImage})`, backgroundColor: usedFallbackColor }}
  >
    {shouldShowTimer ? (
      <CountdownTimer
        shouldUseMessagingString
        shouldIncludeSvg
        shouldEventTimer
        invertText
        customMessagingString={usedTimerEventMessage}
        endDate={usedEndDate}
        fontSize="small"
      />
    ) : (
      <div className={style.bannerText}>
        {usedEventMessage}
      </div>
    )}
  </a>
);

const ProductPageEventCountdownTimer = ({
  eventLink, eventMessage, endDate, backgroundImage, itemId
}) => {
  const config = useConfig();
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const { data, loading, error } = useDataModel('product', {
    variables: { itemId }
  });

  const eventBadge = getEventBadge(data?.product?.badges);

  const {
    usedBackgroundImage,
    usedEventMessage,
    usedTimerEventMessage,
    usedEventLink,
    usedEndDate,
    usedFallbackColor,
    eventStartDate,
    timerStartDate,
    isTimerEnabled,
    currentDate
  } = getEventDetails(config, { eventLink, eventMessage, endDate, backgroundImage });

  if (error || currentDate > usedEndDate) {
    return null;
  }

  const style = getStyle(isMobile);
  const shouldShowBanner = currentDate >= eventStartDate && currentDate <= usedEndDate;
  const shouldShowTimer = currentDate >= timerStartDate && currentDate <= usedEndDate && isTimerEnabled;

  if (loading && (config.alwaysShow || eventBadge) && shouldShowBanner) {
    return renderLoadingSkeleton();
  }

  if ((!eventBadge && !config.alwaysShow) || !shouldShowBanner) {
    return null;
  }

  return (
    <div data-component="PIPEventCountdownTimer" className="sui-@container">
      {shouldShowBanner
        && renderBanner(
          shouldShowTimer,
          style,
          usedBackgroundImage,
          usedEventLink,
          usedEventMessage,
          usedTimerEventMessage,
          usedEndDate,
          usedFallbackColor,
        )}
    </div>
  );
};

ProductPageEventCountdownTimer.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  eventMessage: PropTypes.string,
  eventLink: PropTypes.string,
  backgroundImage: PropTypes.string,
  itemId: PropTypes.string.isRequired
};

ProductPageEventCountdownTimer.defaultProps = {
  backgroundImage: 'https://dam.thdstatic.com/contentful/heroflattenimage/SBF2025-Timer-Background.png',
  eventLink: 'https://www.homedepot.com/b/Spring-Black-Friday/N-5yc1vZ1z1cn1p?catStyle=ShowProducts',
  eventMessage: 'Spring Black Friday',
  endDate: '2024-12-05T03:00:00-04:00'
};

ProductPageEventCountdownTimer.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    badges: params({ storeId: string() }).arrayOf(
      shape({
        endDate: string(),
        label: string(),
        name: string()
      })
    )
  })
};

ProductPageEventCountdownTimer.displayName = 'ProductPageEventCountdownTimer';

export { ProductPageEventCountdownTimer };
