import React, { useContext } from 'react';
import { bool, number } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { RelatedSearchLinks } from './RelatedSearchLinks';
import { getKeywordsFromLocalStorage, getKeywordsFromCookie } from '../../helpers/recent-searches';

export const RecentSearch = ({ limitItems, noOfItems }) => {
  const title = 'Recent Searches';
  const { channel } = useContext(ExperienceContext);

  let relatedKeywords = channel === 'mobile' ? getKeywordsFromCookie() : getKeywordsFromLocalStorage();

  if (!relatedKeywords || !relatedKeywords.length) return null;

  if (limitItems) relatedKeywords = relatedKeywords.slice(0, noOfItems);

  return (
    <div className="sui-w-full" data-component="RecentSearch">
      <RelatedSearchLinks
        relatedLinks={relatedKeywords}
        title={title}
      />
    </div>
  );
};

RecentSearch.displayName = 'RecentSearch';

RecentSearch.propTypes = {
  limitItems: bool,
  noOfItems: number
};

RecentSearch.defaultProps = {
  limitItems: false,
  noOfItems: 10
};
