import React, { useState, useCallback } from 'react';
import {
  string, arrayOf, shape, func
} from 'prop-types';
import { Tab, Tabs } from '@one-thd/sui-atomic-components';
import { TOP_PICKS } from '../utils/helpers';

const VisualTabsCollection = (props) => {
  const {
    categories, products, setCategoryGuid
  } = props;
  const [value, setValue] = useState(0);
  let tabCategories = [];
  let allItemsCategory;
  let productItemIds;

  const onChange = (event, index) => {
    setValue(index);
  };
  const changeActiveIndex = useCallback((newIndex) => {
    setValue(newIndex);
  }, []);

  if (products?.length > 0) {
    productItemIds = products?.map((product) => product?.product?.itemId);
  }

  allItemsCategory = {
    subCategory: TOP_PICKS,
    itemIds: productItemIds,
    categoryGuid: ''
  };

  tabCategories.push(allItemsCategory);

  categories.forEach((category) => {
    tabCategories.push({
      subCategory: `${category.category}`,
      itemIds: category?.itemIds,
      categoryGuid: category?.categoryGuid
    });
  });

  return (
    <Tabs value={value} onChange={onChange} aria-label="VisualTabsCollection" variant="scrollable">
      {tabCategories?.map((category, idx) => {
        return (
          <Tab
            label={category?.subCategory}
            key={idx}
            onClick={(ev) => setCategoryGuid(
              ev,
              category?.categoryGuid,
              category?.subCategory,
              changeActiveIndex,
              idx
            )}
          />
        );
      })}
    </Tabs>
  );
};

VisualTabsCollection.propTypes = {
  categories: arrayOf(shape({
    category: string,
    itemIds: arrayOf
  })),
  products: arrayOf(shape({
    product: shape({
      itemId: string
    })
  })),
  setCategoryGuid: func
};

VisualTabsCollection.defaultProps = {
  categories: {
    category: null,
    itemIds: null,
    products: null
  },
  products: {
    product: {
      itemId: null
    }
  },
  setCategoryGuid: null
};

export default VisualTabsCollection;
