import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { RelatedSearchPill } from './RelatedSearchPill';

const RelatedSearchLinks = (props) => {
  const {
    relatedLinks,
    title
  } = props;

  return (
    <div className="sui-w-full sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{title}</Typography>
      <div
        className="sui-flex sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4"
      >
        {relatedLinks.map((relatedSearchResult, index) => {
          const copyText = relatedSearchResult.anchor || relatedSearchResult.keyword;
          return (
            <RelatedSearchPill
              key={`relatedSearchResult-${copyText}${index}`}
              relatedSearchResult={relatedSearchResult}
              index={index}
              copyText={copyText}
            />
          );
        })}
      </div>
    </div>
  );
};

export { RelatedSearchLinks };

RelatedSearchLinks.propTypes = {
  relatedLinks: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string
};

RelatedSearchLinks.defaultProps = {
  title: 'Related Searches'
};
