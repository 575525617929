/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
  number, string, func, bool, shape, node, arrayOf
} from 'prop-types';
import { CUSTOM_SUBMIT_EVENTS, handleEvent } from '../utils/typeahed-element-events';
import { ClickAwayListener } from '../utils/ClickAwayListener';
import { SearchField } from './SearchField';
import { Results } from './Results';
import HTMLElementType from '../utils/HTMLElementType';
import { SuggestionsMenu } from './SuggestionsMenu';
import { isValidAnchorElement } from '../utils/typeahead-utils';
import { SuggestionsMenuFooter } from './SuggestionsMenuFooter';
import { SuggestionsMenuTitle } from './SuggestionsMenuTitle';
import { useComboboxContext } from './ComboboxContext';
import { ComboboxProvider } from './ComboboxProvider';

const Typeahead = forwardRef(({
  onSubmit,
  onFocusOut,
  onInputChange,
  onInputFocus,
  searchFieldProps,
  suggestionsMenuProps,
  resultsListProps,
  results,
}, ref) => {

  const [hasUserSearchTerm, setHasUserSearchTerm] = useState(false);
  const userSearchTermRef = useRef('');
  const searchInputRef = useRef(null);
  const typeaheadActiveRef = useRef(null);

  const { comboboxMotionState, comboboxMotionDispatch } = useComboboxContext();

  const {
    formProps = {},
    inputProps = {}
  } = searchFieldProps;
  const {
    open,
    anchorElement,
    anchorOffset,
    fullScreenHeight,
    suggestionsMenuTitle,
    suggestionsMenuFooter
  } = suggestionsMenuProps;
  const {
    numberOfResultsToShow = 6,
    alwaysShowArrowInResults = false
  } = resultsListProps;

  const openMenu = (isValidAnchorElement(anchorElement) && open);

  if (openMenu) { typeaheadActiveRef.current = true; }

  const resultsSubset = results?.slice(0, numberOfResultsToShow) || [];

  const handleClickAway = () => {
    if (!typeaheadActiveRef.current) return;

    typeaheadActiveRef.current = false;
    comboboxMotionDispatch({ type: 'SearchClear' });

    if (typeof onFocusOut === 'function') { onFocusOut(); }
  };

  const handleOnSubmit = (event, customEvent) => {
    handleEvent.onSubmit(event,
      { ...customEvent,
        url: customEvent?.url || comboboxMotionState?.active?.item?.link,
        results: customEvent?.results || comboboxMotionState?.list,
        userSearchTerm: userSearchTermRef.current },
      onSubmit
    );
  };

  const handleOnFocus = (event) => {
    typeaheadActiveRef.current = true;
    handleEvent.onFocus(event, onInputFocus);
  };

  const handleOnKeyDown = (event) => {
    if (openMenu && event?.key.length > 1) {
      // prevents the cursor from moving to the start of text
      if (event.key === 'ArrowUp') event.preventDefault();

      comboboxMotionDispatch({ type: event?.key, list: resultsSubset });
    }
  };

  const handleOnChange = (event) => {
    userSearchTermRef.current = event?.target?.value;
    setHasUserSearchTerm(event?.target?.value?.length > 0);
    handleEvent.onChange(event, onInputChange);
  };

  const handleSearchIconClick = (event) => {
    handleOnSubmit(event, {
      type: CUSTOM_SUBMIT_EVENTS.SEARCH_ICON_CLICK,
      selectedSearchTerm: searchInputRef?.current?.value
    });
  };

  const handleFormOnSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleOnSubmit(event, {
      type: CUSTOM_SUBMIT_EVENTS.ENTER_KEY,
      selectedSearchTerm: searchInputRef.current.value
    });
  };

  const handleSearchClear = (event) => {
    comboboxMotionDispatch({ type: 'SearchClear' });
    setHasUserSearchTerm(false);
    userSearchTermRef.current = '';
    searchInputRef.current.value = '';
    searchInputRef.current.focus();
  };

  useEffect(() => {
    // prevents glitch effect in chrome that caused input's focus event
    // to execute when it's not the target element.
    // scenario: input is in focus -> click outside of the window -> then click back inside the window.
    const handleBlur = () => {
      if (document.activeElement !== searchInputRef.current) return;
      // eslint-disable-next-line no-unused-expressions
      searchInputRef.current?.blur();
    };

    window.addEventListener('blur', handleBlur);
    return () => window.removeEventListener('blur', handleBlur);
  }, []);

  useEffect(() => {
    if (comboboxMotionState.active) {
      comboboxMotionDispatch({ type: 'SearchClear' });
    }
  }, [results]);

  useEffect(() => {
    if (comboboxMotionState?.active?.item?.title) {
      searchInputRef.current.value = comboboxMotionState.active.item.title;
    }
  }, [comboboxMotionState.active]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div id="typeahead-container" data-testid="typeahead-container" ref={ref} data-component="Typeahead">
        <SearchField
          ref={searchInputRef}
          onSearchIconClick={handleSearchIconClick}
          onClearIconClick={handleSearchClear}
          showSearchClearIcon={searchInputRef?.current?.value?.length > 0 || hasUserSearchTerm}
          formProps={{
            ...formProps,
            onSubmit: handleFormOnSubmit
          }}
          inputProps={{
            ...inputProps,
            id: 'typeahead-search-field-input',
            'data-testid': 'typeahead-search-field-input',
            role: 'combobox',
            'aria-autocomplete': 'list',
            'aria-controls': 'results-listbox',
            'aria-owns': 'results-listbox',
            'aria-expanded': openMenu,
            onChange: handleOnChange,
            onFocus: handleOnFocus,
            onKeyDown: handleOnKeyDown
          }}
          customClasses={searchFieldProps?.customClasses}
        />
        <SuggestionsMenu
          open={openMenu}
          anchorElement={anchorElement}
          anchorOffset={anchorOffset}
          fullScreenHeight={fullScreenHeight}
        >
          <SuggestionsMenuTitle title={suggestionsMenuTitle} />
          <Results
            searchInputElement={searchInputRef.current}
            alwaysShowArrowInResults={alwaysShowArrowInResults}
            formattedResults={resultsSubset}
            onSubmit={handleOnSubmit}
          />
          <SuggestionsMenuFooter footer={suggestionsMenuFooter} />
        </SuggestionsMenu>
      </div>
    </ClickAwayListener>
  );
});

Typeahead.displayName = 'Typeahead';

Typeahead.propTypes = {
  onSubmit: func,
  onFocusOut: func,
  onInputChange: func,
  onInputFocus: func,
  searchFieldProps: shape({
    formProps: shape({}),
    inputProps: shape({}),
    showSearchClearIcon: bool
  }),
  suggestionsMenuProps: shape({
    open: bool,
    anchorElement: HTMLElementType,
    anchorOffset: shape({
      top: number,
      bottom: number
    }),
    fullScreenHeight: bool,
    suggestionsMenuTitle: string,
    suggestionsMenuFooter: node
  }),
  resultsListProps: shape({
    numberOfResultsToShow: number,
    /** Toggle Show Arrow Icon in Results (default: only shows in mobile) */
    alwaysShowArrowInResults: bool
  }),
  results: arrayOf(shape({
    link: string,
    title: string,
    categories: arrayOf(shape({
      cLink: string,
      cTitle: string,
    }))
  })),
};

Typeahead.defaultProps = {
  onSubmit: () => { },
  onFocusOut: () => { },
  onInputChange: () => { },
  onInputFocus: () => { },
  searchFieldProps: shape({
    formProps: {},
    inputProps: {},
    showSearchClearIcon: true
  }),
  suggestionsMenuProps: shape({
    open: false,
    anchorElement: undefined,
    anchorOffset: shape({
      top: undefined,
      bottom: undefined
    }),
    fullScreenHeight: false,
    suggestionsMenuTitle: undefined,
    suggestionsMenuFooter: undefined
  }),
  resultsListProps: {
    numberOfResultsToShow: 6,
    alwaysShowArrowInResults: false
  },
  results: null
};

const withProvider = () => forwardRef((props, ref) => (
  <ComboboxProvider>
    <Typeahead {...props} ref={ref} />
  </ComboboxProvider>
));

const TypeaheadWithProvider = withProvider();
export { TypeaheadWithProvider as Typeahead };
