import React, { useState, useEffect } from 'react';
import {
  params,
  extend,
  string,
  useLazyDataModel,
  shape,
  arrayOf
} from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import {
  FormController,
  FormLabel,
  Dropdown,
  MenuItem,
  TextField,
} from '@one-thd/sui-atomic-components';
import {
  validateTextField,
} from '../helpers';
import {
  EMPTY_ZIP_CODE_MESSAGE,
  EMPTY_ADDRESS_MESSAGE,
  INVALID_ZIP_CODE
} from '../constants';

export const JobsiteInformationForm = ({
  showErrors,
  jobsiteInformation,
  setJobsiteInformation,
  isJobsiteError,
  setIsJobsiteError,
  open,
}) => {

  const [errors, setErrors] = useState({
    addressLine1Error: '',
    addressLine2Error: '',
    zipCodeError: ''
  });

  const {
    addressLine1Error,
    addressLine2Error,
    zipCodeError
  } = errors;

  const {
    addressLine1, addressLine2, zipCode, city, state
  } = jobsiteInformation;
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownSelection, setDropdownSelection] = useState('');
  const [placeholderValue, setPlaceholderValue] = useState('');
  const handleDropdownSelection = (event) => {
    setDropdownSelection(event.target.value);
  };

  const [
    fetchCityState, { data: cityAndStateData, error: cityAndStateError, loading }
  ] = useLazyDataModel('cityStateLookupByZipCode', {
    variables: {},
    skip: (zipCode?.length !== 5),
    context: { withAuth: true },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (zipCode?.length === 5) {
      fetchCityState({
        variables: {
          zipCode
        }
      });
    }
  }, [zipCode]);

  const getDropdownValue = () => {
    const sanitizedState = state?.trim();
    const cityAndState = `${city}, ${sanitizedState}`;
    const index = dropdownOptions.indexOf(cityAndState);
    const value = dropdownOptions[index];
    if (dropdownSelection?.length && index >= 0) {
      if (dropdownSelection !== placeholderValue) {
        setPlaceholderValue(dropdownSelection);
      }
      return dropdownSelection;
    }
    if (city && sanitizedState && dropdownOptions.length > 0) {
      if (value !== placeholderValue) {
        setPlaceholderValue(value);
      }
      return value;
    }
    if (value?.length && placeholderValue !== value) {
      setPlaceholderValue(value);
    } else if (placeholderValue !== dropdownOptions[0]) {
      setPlaceholderValue(dropdownOptions[0]);
    }
    return dropdownOptions[0];

  };

  useEffect(() => {
    const cityAndStateArray = cityAndStateData?.cityStateLookupByZipCode?.postalDetailsList;
    if (cityAndStateArray) {
      setDropdownOptions([]);
      cityAndStateArray.map((location) => {
        setDropdownOptions((prevState) => ([
          ...prevState,
          `${location.city}, ${location.state}`
        ]));
      });
      setErrors((prevState) => ({
        ...prevState,
        zipCodeError: ''
      }));
    }
    if (cityAndStateError?.message) {
      setErrors((prevState) => ({
        ...prevState,
        zipCodeError: INVALID_ZIP_CODE
      }));
    }
  }, [cityAndStateData]);

  useEffect(() => {
    const sanitizedState = state?.trim();
    const cityAndState = `${city}, ${sanitizedState}`;
    const index = dropdownOptions?.indexOf(cityAndState);
    if (index < 0) {
      setPlaceholderValue(dropdownOptions[0]);
      setDropdownSelection(dropdownOptions[0]);
    } else {
      setPlaceholderValue(dropdownOptions[index]);
    }
    getDropdownValue();
  }, [dropdownOptions]);

  useEffect(() => {
    if (dropdownSelection?.length) {
      const cityAndStateMutation = dropdownSelection.split(',');
      const newCity = cityAndStateMutation[0];
      const newState = cityAndStateMutation[1];
      setJobsiteInformation((prevState) => ({
        ...prevState,
        city: newCity,
        state: newState
      }));
    }
  }, [dropdownSelection]);

  const handleAddressOneValidation = (value) => {
    const { isError, errorMessage } = validateTextField(value);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        addressLine1Error: errorMessage
      }));
    } else if (!isError && addressLine1Error !== EMPTY_ADDRESS_MESSAGE) {
      setErrors((prevState) => ({
        ...prevState,
        addressLine1Error: ''
      }));
    }
  };

  const handleAddressTwoValidation = (value) => {
    const { isError, errorMessage } = validateTextField(value);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        addressLine2Error: errorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        addressLine2Error: ''
      }));
    }
  };

  const handleOnChange = (key, event) => {
    const { value } = event.target;
    setJobsiteInformation((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  useEffect(() => {
    const errorMessageValues = Object.values(errors);
    const hasErrors = errorMessageValues.some(Boolean);
    if (hasErrors) {
      setIsJobsiteError(true);
    } else {
      setIsJobsiteError(false);
    }
  }, [errors]);

  const [isOptional, setIsOptional] = useState(true);
  useEffect(() => {
    if (addressLine1 || addressLine2 || zipCode) {
      setIsOptional(false);
      setErrors((prevState) => ({
        ...prevState,
        addressLine1Error: (!addressLine1) ? EMPTY_ADDRESS_MESSAGE : '',
        zipCodeError: (!zipCode) ? EMPTY_ZIP_CODE_MESSAGE : ''
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        addressLine1Error: '',
        zipCodeError: ''
      }));
      setIsOptional(true);
    }
  }, [addressLine1, addressLine2, zipCode]);

  useEffect(() => {
    if (!open && isJobsiteError) {
      setErrors((prevState) => ({
        ...prevState,
        addressLine1Error: '',
        addressLine2Error: '',
        cityError: '',
        stateError: '',
        zipCodeError: ''
      }));
    }
  }, [open]);

  return (
    <>
      <TextField
        fullWidth
        optional={isOptional}
        value={addressLine1}
        id="jobsite-address-one"
        label="Address Line 1"
        name="addressLine1"
        onBlur={() => { handleAddressOneValidation(addressLine1); }}
        onChange={(event) => { handleOnChange('addressLine1', event); }}
        status={
          showErrors && addressLine1Error ? 'error' : null
        }
        statusMessage={
          showErrors ? addressLine1Error : ''
        }
        InputProps={{
          inputProps: {
            'data-testid': 'jobsite-address-one-input'
          }
        }}
      />
      <TextField
        fullWidth
        optional
        value={addressLine2}
        id="jobsite-address-two"
        label="Address Line 2"
        name="addressLine2"
        onBlur={() => { handleAddressTwoValidation(addressLine2); }}
        onChange={(event) => { handleOnChange('addressLine2', event); }}
        status={
          showErrors && addressLine2Error ? 'error' : null
        }
        statusMessage={
          showErrors ? addressLine2Error : ''
        }
        InputProps={{
          inputProps: {
            'data-testid': 'jobsite-address-two-input'
          }
        }}
      />
      <TextField
        fullWidth
        optional={isOptional}
        value={zipCode}
        id="jobsite-zipcode"
        label="Zip Code"
        name="zipCode"
        onChange={(event) => { handleOnChange('zipCode', event); }}
        status={
          showErrors && zipCodeError ? 'error' : null
        }
        statusMessage={
          showErrors ? zipCodeError : ''
        }
        InputProps={{
          inputProps: {
            'data-testid': 'jobsite-zipcode-input',
            maxlength: '5'
          }
        }}
      />
      <FormController fullWidth>
        <FormLabel
          optional
          htmlFor="jobsite-information-dropdown"
          id="jobsite-information-dropdown-label"
        >
          City, State
        </FormLabel>
        <Dropdown
          id="jobsite-information-dropdown"
          disabled={!dropdownOptions[0]}
          value={getDropdownValue()}
          onChange={(event) => { handleDropdownSelection(event); }}
          placeholder={placeholderValue || dropdownOptions[0]}
        >
          {(dropdownOptions[0]) && (
            dropdownOptions.map((location) => (
              <MenuItem value={location}>{location}</MenuItem>
            ))
          )}
        </Dropdown>
      </FormController>
    </>
  );
};

JobsiteInformationForm.displayName = 'JobsiteInformationForm';

JobsiteInformationForm.propTypes = {
  showErrors: PropTypes.bool.isRequired,
  jobsiteInformation: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  setJobsiteInformation: PropTypes.func.isRequired,
  isJobsiteError: PropTypes.bool.isRequired,
  setIsJobsiteError: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

JobsiteInformationForm.dataModel = extend({
  cityStateLookupByZipCode: params({
    zipCode: string().isRequired(),
  }).shape({
    postalDetailsList: arrayOf(
      shape({
        city: string(),
        state: string()
      })
    )
  })
});
