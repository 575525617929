import React, {
  useContext, useEffect, useState, useRef, useMemo
} from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf,
  shape, useDataModel, extend, number, customType, bool, useLazyDataModel
} from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { DrawerHeader } from '@one-thd/sui-atomic-components';
import { CreateAProjectContent } from '@thd-olt-component-react/project-forms';
import { DefaultScreen } from '../../common/DefaultScreen';
import { CreateGroup } from './CreateGroup';
import { SelectGroup } from './SelectGroup';
import { MiniPip } from '../../common/mini-pip/MiniPip';
import { AddToContext } from '../../AddToContext';
import { SCREENS, HEADER_TEXT, ADD_ITEM_NEW_PROJECT } from '../../AddToConstants';
import { useProjects, projectsModel, useProjectDetails } from '../../hooks/useProjects';
import { addNonQuotableProductVariables } from '../../AddToHelpers';
import { AddToLoading } from '../../common/addto-loading/AddToloading';
import { SelectProject } from './SelectProject';

export const AddToProject = ({
  projectId,
  onSuccessGroupClick = () => { },
  products: productsProp = [],
  expandSelectedGroup,
  initialLoad,
  setInitialLoad,
  pageSize,
  setPageSize,
  onSuccessfulAddToGroup,
  showBackButton
}) => {
  const {
    storeId,
    screen,
    setScreen,
    handleClose,
    selectedGroup,
    setSelectedGroup,
    selectedProductDetails,
    showSuccessMessage,
    setShowSuccessMessage,
    setSelectedProductDetails
  } = useContext(AddToContext);
  // Flag to fire analytic if an item has been added to a project with a new project
  const isProjectCreated = useRef(false);
  const [products, setProducts] = useState(productsProp);
  const [selectedProject, setSelectedProject] = useState({
    projectId, projectName: '', projectCreated: false
  });
  const { svocID, customerType } = useThdCustomer();
  const isMultiAdd = products?.length > 1;
  const itemId = selectedProductDetails?.itemId || products[0]?.itemId;

  const { data } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    skip: isMultiAdd || !products[0]?.itemId
  });

  const { product: singleProductData = {} } = data || selectedProductDetails?.productData || {};
  const { storeSkuNumber, omsThdSku, specialOrderSku } = singleProductData?.identifiers || {};
  const singleItemSku = storeSkuNumber || omsThdSku || specialOrderSku;
  const { pricing } = singleProductData;
  const basePrice = pricing?.value;
  const quantity = selectedProductDetails?.quantity || products[0]?.quantity;
  const additionalVariables = addNonQuotableProductVariables(singleProductData);
  const { projectData, projectLoading, projectRefetch } = useProjectDetails({
    projectId: selectedProject.projectId
  });
  const {
    projectName = '',
    propertyType,
    addresses,
    defaultListId,
    defaultListMutationKey,
    itemGroups
  } = projectData?.projectDetails || {};
  const groupsExist = !!itemGroups?.length;
  const [keyword, setKeyword] = useState('');
  const selectedGroupId = useRef(null);
  const {
    PROJECT_CREATE_GROUP,
    PROJECT_SELECT_PROJECT,
    DEFAULT,
    PROJECT_CREATE_PROJECT,
    PROJECT_SELECT_GROUP,
    MINI_PIP_BASE,
    MINI_PIP_PRODUCT_OVERVIEW,
    MINI_PIP_PRODUCT_SPECS,
    MINI_PIP_PRODUCT_REVIEWS,
    LOADING
  } = SCREENS;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-project.ready');
  }, []);

  useEffect(() => {
    if (selectedProject?.projectId) {
      projectRefetch();
    }
  }, [selectedProject?.projectId]);

  useEffect(() => {
    if (productsProp?.length > 0) {
      const getValidSku = (skus) => {
        if (typeof skus === 'string') return skus;
        const { storeSkuNumber, omsThdSku, specialOrderSku } = skus;
        return storeSkuNumber || omsThdSku || specialOrderSku;
      };
      if (productsProp?.length > 1) {
        const productData = productsProp.map((itemData) => ({
          ...itemData,
          sku: getValidSku(itemData.sku)
        })).filter((item) => item?.sku);
        setProducts(productData);
      } else {
        setProducts(productsProp);
      }
    }
  }, [productsProp]);

  const { projects, error, refetch, loading } = useProjects({
    page: 1,
    pageSize,
    accountId: svocID,
    filter: [{ searchBy: 'STATUS', search: 'Active' }],
    sort: [{ sortBy: 'STATUS', sortOrder: 'ASC' }],
  });

  useEffect(() => {
    refetch();
  }, [svocID, pageSize]);

  const projectsCount = projects?.projects?.totalProjects || 0;
  const projectsData = projects?.projects?.projects || {};
  const projectsExist = !!projectsData?.length;

  useEffect(() => {
    if (loading && products?.length > 0 && !projectsExist && initialLoad) {
      setScreen(LOADING);
    } else if (products?.length > 0 && projectsExist && initialLoad) {
      setScreen(PROJECT_SELECT_PROJECT);
    } else if (!loading && products?.length > 0 && !projectsExist && initialLoad) {
      setScreen(PROJECT_CREATE_PROJECT);
    }
  }, [loading, projectsExist]);

  useEffect(() => {
    if (projectLoading && products?.length > 0) {
      setScreen(LOADING);
    } else if (products?.length > 0 && !selectedProject?.projectCreated && selectedProject?.projectId) {
      setScreen(PROJECT_SELECT_GROUP);
    } else if (products?.length > 0 && selectedProject?.projectCreated && selectedProject?.projectId) {
      setScreen(PROJECT_CREATE_GROUP);
    }
  }, [projectLoading, groupsExist]);

  const updateSelectedGroupId = (newGroupId) => {
    selectedGroupId.current = newGroupId;
  };

  const singleProduct = [
    {
      itemId, quantity, sku: singleItemSku, itemType: 'catalog', ...additionalVariables
    }
  ];
  const singleProductAnalytic = singleProduct.map((product) => ({
    quantity: product.quantity,
    sku: product.itemId,
    price: basePrice
  }));

  const multipleProduct = products.map((prod) => {
    const additionalVariablesMultipleProduct = addNonQuotableProductVariables(prod.productData);
    return {
      itemId: prod.itemId,
      quantity: prod.quantity,
      sku: prod.sku,
      itemType: 'catalog',
      ...additionalVariablesMultipleProduct
    };
  });
  const multipleProductAnalytic = multipleProduct.map((product) => ({
    quantity: product.quantity,
    sku: product.itemId,
    price: basePrice
  }));

  const [addItem] = useLazyDataModel('updateMaterials', {
    variables: {
      listId: defaultListId,
      mutationKey: defaultListMutationKey,
      operation: 'ADD_ITEM',
      groupName: selectedGroup,
      items: isMultiAdd ? multipleProduct : singleProduct
    },
    context: { withAuth: true },
    fetchPolicy: 'no-cache',
  });

  const addItemToList = async (type = 'OLD_GROUP') => {
    const response = await addItem();
    if (response?.data?.updateMaterials?.groupId) {
      onSuccessfulAddToGroup();
      setShowSuccessMessage(true);
      setScreen(DEFAULT);
      projectRefetch();
    }
    if (products?.length === 0) {
      const defaultEvent = 'projects: add to project action w/NO new Group created';
      const newGroupEvent = 'projects: add to project action w/new group created';
      const eventName = type === 'NEW_GROUP' ? newGroupEvent : defaultEvent;
      LIFE_CYCLE_EVENT_BUS.trigger(eventName, {
        id: projectId,
        price: basePrice,
        productSku: itemId,
        type: propertyType ? 'y' : 'n',
        desc: projectName ? 'y' : 'n',
        address: addresses?.[0]?.addressLine1 ? 'y' : 'n',
        zip: addresses?.[0]?.zipCode ? 'y' : 'n'
      });
    }
    return response;
  };

  const handleProjectCreation = async (response) => {
    if (response?.id) {
      setSelectedProject({
        projectId: response?.id,
        projectName: response?.projectName,
        projectCreated: true
      });
    }
    setScreen(PROJECT_CREATE_GROUP);
    isProjectCreated.current = true;
  };

  const onCreateGroup = async (groupName) => {
    setSelectedGroup(groupName);
    const res = await projectRefetch();
    if (res?.data?.projectDetails?.defaultListMutationKey !== defaultListMutationKey) {
      await addItemToList('NEW_GROUP');
    }
    if (res?.data?.projectDetails?.id) {
      const groups = res.data.projectDetails.itemGroups;
      const group = groups?.find((grp) => grp.groupName === groupName);
      selectedGroupId.current = group?.id || null;
    }
    if (isProjectCreated.current === true) {
      const payload = {
        id: projectId,
        type: propertyType ? 'y' : 'n',
        desc: projectName ? 'y' : 'n',
        address: addresses?.[0]?.addressLine1 ? 'y' : 'n',
        zip: addresses?.[0]?.zipCode ? 'y' : 'n',
        items: isMultiAdd ? multipleProductAnalytic : singleProductAnalytic
      };
      LIFE_CYCLE_EVENT_BUS.trigger(ADD_ITEM_NEW_PROJECT, payload);
    }
  };

  useEffect(() => {
    if (itemGroups) {
      const newGroupId = itemGroups.find((group) => group.groupName === selectedGroup)?.id;
      if (newGroupId) {
        updateSelectedGroupId(newGroupId);
      }
    }
  }, [selectedGroup]);

  const onSearch = (searchKeyword) => {
    setKeyword(searchKeyword);
  };

  const handleBackPress = () => {
    if (screen === PROJECT_CREATE_PROJECT && projectsExist) {
      setScreen(PROJECT_SELECT_PROJECT);
    }
    if (screen === PROJECT_CREATE_GROUP && groupsExist) {
      setScreen(PROJECT_SELECT_GROUP);
    }
    if (products?.length === 0 && (screen === PROJECT_SELECT_GROUP || screen === MINI_PIP_BASE)) {
      setScreen(DEFAULT);
      setSelectedGroup(null);
      setSelectedProductDetails({ itemId: null, quantity: null, productData: null });
    }
    if (products?.length > 0 && (screen === PROJECT_SELECT_GROUP || screen === MINI_PIP_BASE)) {
      setScreen(PROJECT_SELECT_PROJECT);
      setSelectedGroup(null);
      setSelectedProductDetails({ itemId: null, quantity: null, productData: null });
    }

    // eslint-disable-next-line max-len
    if (screen === MINI_PIP_PRODUCT_OVERVIEW || screen === MINI_PIP_PRODUCT_SPECS || screen === MINI_PIP_PRODUCT_REVIEWS) {
      setScreen(MINI_PIP_BASE);
    }
  };

  return (
    <>
      {(screen === DEFAULT || screen === PROJECT_SELECT_PROJECT || (screen === PROJECT_CREATE_GROUP && !groupsExist) || (screen === PROJECT_CREATE_PROJECT && !projectsExist)) ? (
        <DrawerHeader
          data-testid="add-to-drawer-header"
          title={showSuccessMessage && screen === DEFAULT ? 'Add to Project' : HEADER_TEXT[screen]}
          onClose={handleClose}
        />
      ) : (
        <DrawerHeader
          data-testid="add-to-drawer-header"
          title={HEADER_TEXT[screen] ? HEADER_TEXT[screen] : 'Add to Project'}
          onClose={handleClose}
          onBack={showBackButton ? handleBackPress : null}
        />
      )}
      {screen === LOADING && (
        <AddToLoading />
      )}
      {screen === DEFAULT && (
        <DefaultScreen
          keyword={keyword}
          onSearch={onSearch}
          handleClose={handleClose}
          projectName={projectName}
          projectId={selectedProject.projectId}
          selectedGroup={selectedGroup}
          showSuccessMessage={showSuccessMessage}
          groupsExist={groupsExist}
          onSuccessGroupClick={onSuccessGroupClick}
          selectedGroupId={selectedGroupId}
          products={products}
          selectedProject={selectedProject}
          expandSelectedGroup={expandSelectedGroup}
          customerType={customerType}
        />
      )}
      {screen === PROJECT_CREATE_PROJECT && (
        <CreateAProjectContent onCreate={handleProjectCreation} handleClose={handleClose} isAddToDrawer />
      )}
      {screen === PROJECT_SELECT_PROJECT && (
        <SelectProject
          products={products}
          projects={projectsData}
          projectsExist={projectsExist}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          groupsExist={groupsExist}
          pageSize={pageSize}
          setPageSize={setPageSize}
          projectsCount={projectsCount}
          loading={loading}
          setInitialLoad={setInitialLoad}
        />
      )}
      {screen === PROJECT_CREATE_GROUP && (
        <CreateGroup
          projectId={selectedProject.projectId}
          onCreateGroup={onCreateGroup}
          handleClose={handleClose}
          setSelectedGroup={setSelectedGroup}
          handleBackPress={handleBackPress}
        />
      )}
      {screen === PROJECT_SELECT_GROUP && (
        <SelectGroup
          projectId={selectedProject.projectId}
          handleClose={handleClose}
          products={products}
          addItem={addItemToList}
          groupsExist={groupsExist}
          handleBackPress={handleBackPress}
        />
      )}
      {
        screen?.startsWith('MINI_PIP') && (
          <MiniPip itemId={itemId} />
        )
      }

    </>
  );
};

AddToProject.displayName = 'AddToProject';

AddToProject.propTypes = {
  projectId: PropTypes.string,
  onSuccessGroupClick: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  expandSelectedGroup: PropTypes.func,
  initialLoad: PropTypes.bool.isRequired,
  setInitialLoad: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  onSuccessfulAddToGroup: PropTypes.func,
  showBackButton: PropTypes.bool
};

AddToProject.defaultProps = {
  projectId: null,
  onSuccessGroupClick: () => { },
  products: [],
  expandSelectedGroup: () => { },
  onSuccessfulAddToGroup: () => { },
  showBackButton: true
};

AddToProject.dataModel = extend({
  product: params({
    itemId: string().isRequired(),
    dataSource: string()
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      productType: string(),
      productLabel: string(),
      storeSkuNumber: string(),
      omsThdSku: string(),
      specialOrderSku: string()
    }),
    info: shape({
      productDepartment: string(),
      classNumber: string(),
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string()
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string()
      })
    })
  })
},
{
  updateMaterials: params({
    listId: string().isRequired(),
    mutationKey: string().isRequired(),
    operation: customType('MaterialsOperationType').enum(
      ['EDIT_GROUP_NAME', 'DELETE_GROUP', 'ADD_ITEM', 'DELETE_ITEM', 'UPDATE_QUANTITY', 'REARRANGE']
    ),
    groupName: string(),
    items: arrayOf(customType('Items').shape({
      itemId: string(),
      quantity: number(),
      sku: string(),
      itemType: string(),
      isQuotable: bool(),
      notQuotableLabel: string()
    }))
  }).mutation().shape({
    groupId: string()
  })
},
projectsModel,
CreateAProjectContent
);
