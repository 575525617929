import React from 'react';
import {
  arrayOf, bool, number, shape, string, func
} from 'prop-types';
import {
  List, MenuItem, IconButton, Typography
} from '@one-thd/sui-atomic-components';
import { DownArrowLeft } from '@one-thd/sui-icons';
import HTMLElementType from '../utils/HTMLElementType';
import { PrimaryResult } from './PrimaryResult';
import { CUSTOM_SUBMIT_EVENTS } from '../utils/typeahed-element-events';
import { useComboboxContext } from './ComboboxContext';

const Results = ({
  searchInputElement,
  formattedResults,
  alwaysShowArrowInResults,
  onSubmit
}) => {

  const { comboboxMotionState } = useComboboxContext();

  const hasResultsToShow = formattedResults?.length > 0;

  const downArrowLeftClasses = 'sui-flex sui-justify-center sui-self-center sui-rotate-90 sui-w-3 sui-h-3';

  const listClasses = 'sui-list-none sui-m-0 sui-px-0 sui-py-2 sui-relative';

  const filteredCategories = ({ categories }) => {
    return categories?.length
      ? categories.filter((result) => typeof result.cTitle === 'string')
      : [];
  };

  const handleArrowClick = (event, resultTitle) => {
    event.stopPropagation();
    event.preventDefault();
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
      .set.call(searchInputElement, resultTitle);
    searchInputElement.focus();
  };

  const ResultsArrowIcon = () => {
    return (
      <div data-testid="typeahead-results-arrow">
        <div className={downArrowLeftClasses}>
          <DownArrowLeft size="small" />
        </div>
      </div>
    );
  };

  const handleResultClick = (event, resultTitle, resultLink) => {
    event.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit(event,
        {
          type: CUSTOM_SUBMIT_EVENTS.MENU_ITEM_CLICK,
          selectedSearchTerm: resultTitle,
          url: resultLink,
          results: formattedResults
        });
    }
  };

  return (
    <List
      role="listbox"
      className={listClasses}
      tabIndex={-1}
      id="results-listbox"
      data-testid="results-listbox"
      key="results-list"
    >
      {hasResultsToShow && formattedResults?.map(({ title, link, categories }, index) => {
        const filteredCategoryResults = filteredCategories({ categories });
        const showCategories = filteredCategoryResults?.length > 0;
        const selected = comboboxMotionState?.active?.item?.title === title;
        return (
          <React.Fragment key={`ta-results-frag-${title}-${index}`}>
            <MenuItem
              component="a"
              selected={selected}
              aria-selected={selected}
              role="option"
              href={link}
              key={title}
              onClick={(event) => handleResultClick(event, title, link)}
              data-testid="typeahead-results-item"
              id={`typeahead-results-item-${index}`}
            >
              <div className="sui-w-full sui-flex sui-justify-between sui-items-center sui-h-5">
                <PrimaryResult resultTitle={title} resultLink={link} searchTerm={searchInputElement?.value} />
                {alwaysShowArrowInResults && (
                  <IconButton
                    key={title + ' arrow-icon'}
                    aria-label="Search Results Item Arrow Icon"
                    icon={ResultsArrowIcon}
                    iconSize="small"
                    onClick={(event) => handleArrowClick(event, title)}
                  />
                )}
              </div>
            </MenuItem>

            {showCategories && filteredCategoryResults.map(({ cLink, cTitle }) => {
              return (
                <MenuItem
                  component="a"
                  href={cLink}
                  key={cTitle}
                  data-testid="typeahead-category-result-item"
                  onClick={(event) => handleResultClick(event, cTitle, cLink)}
                  tabIndex={0}
                >
                  <div className="sui-ml-7 sui-flex sui-justify-center sui-items-center sui-h-5">
                    <Typography key={cTitle} decoration="underline" variant="body-base" color="primary">
                      {'in ' + cTitle}
                    </Typography>
                  </div>
                </MenuItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
};

Results.displayName = 'Results';

Results.propTypes = {
  searchInputElement: HTMLElementType,
  formattedResults: arrayOf(shape({
    link: string,
    title: string,
    categories: arrayOf(shape({
      cLink: string,
      cTitle: string,
    }))
  })),
  alwaysShowArrowInResults: bool,
  onSubmit: func
};

Results.defaultProps = {
  searchInputElement: undefined,
  formattedResults: null,
  alwaysShowArrowInResults: false,
  onSubmit: undefined
};

export { Results };
