import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup,
  FormController,
  FormLabel, Radio,
  RadioGroup,
  SelectionControlLabel,
  DrawerBody,
  DrawerFooter,
  Alert
} from '@one-thd/sui-atomic-components';
import {
  extend, arrayOf, params, string, number, customType,
} from '@thd-nucleus/data-sources';
import { AddToListProductInfo } from './AddToListProductInfo';
import { AddToContext } from '../../AddToContext';
import { OPERATION_ADD_ITEMS, TRACK, SCREENS } from '../../AddToConstants';
import { useUpdateList } from '../../hooks/useLists';

export const SelectList = ({
  listDetails,
  onClose,
  products,
  isSharedList,
  setFavoriteCount,
  favoriteCount,
  productValue,
  identifiers,
  primaryImage,
  setNewListDetails
}) => {
  const { setScreen } = useContext(AddToContext);
  const { LIST_CREATE_LIST, LIST_SUCCESS } = SCREENS;
  const [selectedList, setSelectedList] = useState({ listId: null, listName: null });
  const isMultiAdd = products?.length > 1;
  const singleProduct = [
    { itemId: products[0]?.itemId, quantity: products[0]?.quantity }
  ];

  const multipleProduct = products.map((prod) => ({
    itemId: prod.itemId,
    quantity: prod.quantity
  }));

  const { updateList, updateListResponse } = useUpdateList({
    id: selectedList?.id,
    products: isMultiAdd ? multipleProduct.reverse() : singleProduct,
    operationName: OPERATION_ADD_ITEMS
  });

  useEffect(() => {
    if (updateListResponse?.error) {
      const gqlErrors = updateListResponse?.error?.graphQLErrors;
      gqlErrors.forEach((err, index) => {
        if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
          setSelectedList({
            ...selectedList,
            error: 'Apologies, a temporary technical issue occured. Please try again soon.'
          });
        } else {
          setSelectedList({
            ...selectedList,
            error: err?.msg
          });
        }
      });
    }
  }, [updateListResponse.error]);

  useEffect(() => {
    if (updateListResponse?.data?.updateList?.id && !isMultiAdd) {
      LIFE_CYCLE_EVENT_BUS.trigger(TRACK.ADD_ITEM, {
        quantity: singleProduct?.quantity, price: productValue || 0, sku: products[0].itemId
      });
    }
    if (updateListResponse?.data?.updateList?.id && products && !isMultiAdd) {
      const addToListLocation = isSharedList ? 'shared list' : 'existing list';
      const listItem = products.map((prod) => ({
        listLocation: addToListLocation,
        quantity: prod.quantity || 'n/a',
        price: { basePrice: prod.price || 'n/a' },
        productInfo: { sku: prod.itemId || 'n/a' }
      }));
      LIFE_CYCLE_EVENT_BUS.trigger(TRACK.ADD_PRODUCTS, {
        listItem
      });
    }
  }, [updateListResponse?.data?.updateList]);

  const selectList = (list) => {
    setSelectedList({
      id: list?.listId,
      name: list?.listName
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await updateList({
      variables: {
        id: selectedList?.id
      }
    });
  };

  useEffect(() => {
    if (updateListResponse?.data?.updateList?.id) {
      setFavoriteCount(favoriteCount + 1);
    }
  }, [updateListResponse?.data?.updateList?.id]);

  if (updateListResponse?.data?.updateList?.id) {
    setNewListDetails({
      listName: selectedList?.name,
      listId: updateListResponse?.data?.updateList?.id
    });
    setScreen(LIST_SUCCESS);
  }

  return (
    <>
      <DrawerBody>
        {
          selectedList?.error && (
            <Alert status="error">{selectedList?.error}</Alert>
          )
        }
        {
          !isMultiAdd && (
            <AddToListProductInfo
              identifiers={identifiers}
              primaryImage={primaryImage}
            />
          )
        }
        <Button
          className="sui-mb-4 sui-mt-12 sui-mx-6 sui-text-base sui-flex"
          variant="text"
          onClick={() => { setScreen(LIST_CREATE_LIST); }}
        >
          <u>Create New List</u>
        </Button>
        <div className="sui-pb-4 sui-px-6 sui-text-base">-or-</div>
        <FormController className="sui-px-6">
          <FormLabel id="list-names" className="sui-cursor-default sui-text-base">
            Add to An Existing List:
          </FormLabel>
          <div className="sui-pt-10">
            <RadioGroup
              aria-labelledby="list-names"
              name="list-names"
              data-testid="form-group"
            >
              {(listDetails || []).map((list) => {
                return (
                  <SelectionControlLabel
                    className="sui-break-all"
                    key={list.listId}
                    value={list.listId}
                    label={list.listName}
                    onChange={(evt) => { selectList(list); }}
                  >
                    <Radio />
                  </SelectionControlLabel>
                );
              })}
            </RadioGroup>
          </div>
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={handleSubmit}
            type="submit"
            disabled={!selectedList?.id}
            data-testid="save-list"
          >
            Add to List
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
            data-testid="cancel-add-to-list"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

SelectList.propTypes = {
  itemId: PropTypes.string,
  quantity: PropTypes.number,
  isSharedList: PropTypes.bool.isRequired,
  listDetails: PropTypes.arrayOf(PropTypes.shape({
    listId: PropTypes.string,
    listName: PropTypes.string
  })),
  onClose: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  setFavoriteCount: PropTypes.func.isRequired,
  favoriteCount: PropTypes.number,
  productValue: PropTypes.number,
  setNewListDetails: PropTypes.func,
  identifiers: PropTypes.shape({
    brandName: PropTypes.string,
    productLabel: PropTypes.string
  }),
  primaryImage: PropTypes.shape({
    url: PropTypes.string
  })
};

SelectList.defaultProps = {
  itemId: null,
  quantity: 1,
  listDetails: [],
  products: [],
  favoriteCount: null,
  productValue: 0,
  setNewListDetails: () => {},
  identifiers: {},
  primaryImage: {}
};

SelectList.dataModel = extend(
  {
    updateList: params({
      id: string().isRequired(),
      operationName: customType('UpdateOperation').enum(['Update', 'RemoveItems', 'AddItems']),
      products: arrayOf(customType('ProductInput').shape({
        itemId: string(),
        quantity: number(),
      }).isRequired()
      )
    }).mutation().shape({
      id: string()
    })
  }
);
