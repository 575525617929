import React from 'react';
import {
  DrawerBody,
  Skeleton,
  SkeletonBlock,
  SkeletonLine
} from '@one-thd/sui-atomic-components';

export const AddToLoading = () => {
  return (
    <>
      <DrawerBody>
        <Skeleton disablePadding="y" orientation="horizontal">
          <SkeletonBlock />
          <SkeletonLine />
        </Skeleton>
      </DrawerBody>
    </>
  );
};