/* eslint-disable consistent-return */
/* globals resx, mockRVData */
import * as helpers from './helpers';

const shouldLoadFromStorage = () => {
  // checks to see if user came from PIP
  return !(helpers.getProductPageStatus(document?.referrer));
};

const useFetchPolicy = (isLocalized) => {
  return isLocalized && !shouldLoadFromStorage() ? 'network-only' : 'cache-first';
};

export {
  shouldLoadFromStorage,
  useFetchPolicy
};
