/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  number as numberType,
  any,
  string as stringType,
  bool as boolType
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodRow,
  PodSection,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating,
  PodTapper,
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const FBTCustomProductPod = ({
  itemId, storeId, itemIndex, data, channel, strategy, scheme, anchorProduct, url
}) => {
  const merchRec = useMemo(() => {
    const anchorId = (anchorProduct.length > 2) ? anchorProduct : 'n/a';
    return `MERCH=REC-_-${scheme}-_-${anchorId}-_-${itemIndex}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorProduct, scheme]);

  const theme = useTheme(FBTCustomProductPod);
  const { hideAddToList } = theme.props;

  const handleClick = () => {
    const productPodEventData = {
      podAction: 'product pod',
      podAnchorSku: itemId,
      target: '_self',
      parent: 'fbt-carousel'
    };
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productPodEventData);
  };

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2"
      analyticsData={{ parent: 'fbt-carousel', position: itemIndex, strategy, scheme }}
      render={(pod) => (
        <>
          <a
            className="sui-top-0 sui-left-0 sui-absolute sui-size-full sui-z-1"
            href={url}
            aria-label="Link"
            onClick={handleClick}
          />
          <div className="sui-flex sui-items-center sui-justify-between sui-mt-2">
            <ProductBadge itemId={pod.itemId} storeId={storeId} />
            {!hideAddToList && (
              <PodRow rowReverse noPadding>
                <PodTapper fitWidth zIndex>
                  <AddToList
                    itemId={pod.itemId}
                    storeId={storeId}
                    showIconButton
                  />
                </PodTapper>
              </PodRow>
            )}
          </div>
          <PodTapper zIndex>
            <ProductImage
              itemId={pod.itemId}
              showSecondaryImage={pod.showSecondaryImage}
              merchQueryParam={merchRec}
            />
          </PodTapper>
          <PodSection columnAlign noPadding>
            <PodTapper fitWidth zIndex>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                brandTitleMaxLine={3}
                disableShopThisCollection
                merchQueryParam={merchRec}
              />
            </PodTapper>
            <PodTapper fitWidth zIndex>
              <ProductRating itemId={pod.itemId} merchQueryParam={merchRec} />
            </PodTapper>
            <PodTapper zIndex pointerNone aPointer buttonPointer spanPointer>
              <Price
                channel={channel}
                itemId={pod.itemId}
                large={false}
                product={data}
                showPreferredPricingBadge
                hideSavingsText={false}
                stackWasPrice
              />
            </PodTapper>
          </PodSection>
        </>
      )}
    />
  );
};

FBTCustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating,
  ProductHeader,
  Price,
  AddToList
);

FBTCustomProductPod.propTypes = {
  itemId: stringType,
  storeId: stringType,
  itemIndex: numberType,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  channel: stringType,
  strategy: stringType,
  scheme: stringType,
  anchorProduct: stringType,
  url: stringType.isRequired
};

FBTCustomProductPod.defaultProps = {
  itemId: '',
  storeId: '',
  itemIndex: null,
  data: null,
  channel: '',
  strategy: '',
  scheme: '',
  anchorProduct: ''
};

FBTCustomProductPod.displayName = 'FBTCustomProductPod';

FBTCustomProductPod.themeProps = {
  hideAddToList: boolType
};

FBTCustomProductPod.defaultThemeProps = {
  hideAddToList: false
};
