import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf,
  shape, useDataModel, extend
} from '@thd-nucleus/data-sources';
import {
  DrawerBody, DrawerFooter,
  Typography, Button
} from '@one-thd/sui-atomic-components';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const ProductOverview = ({ itemId, updateList }) => {
  const { setScreen, addToType } = useContext(AddToContext);
  const { PROJECT_SELECT_GROUP, DEFAULT } = SCREENS;

  const { data } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });
  const { product } = data || {};
  const description = product?.details?.description || '';
  const descriptiveAttributes = product?.details?.descriptiveAttributes || [];

  const showDescriptiveAttributes = descriptiveAttributes.length > 0;

  const handleSubmit = async () => {
    if (addToType === 'List') {
      await updateList({
        variables: {
          products: [{ itemId, quantity: 1 }],
        }
      });
      setScreen(DEFAULT);
    } else {
      setScreen(PROJECT_SELECT_GROUP);
    }
  };

  return (
    <>
      <DrawerBody>
        <div className="sui-flex sui-flex-col sui-gap-4">
          <Typography>{description}</Typography>
          {showDescriptiveAttributes && (
            <ul className="sui-pl-4 sui-pr-1 sui-flex sui-flex-col sui-gap-2">
              {descriptiveAttributes.map((highlight, i) => (
                <li
                  key={`promo-quickview-overview-highlights__highlight-${i}`}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: highlight.value }}
                />
              ))}
            </ul>
          )}
        </div>
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="productOverview-AITP-button"
          variant="secondary"
          fullWidth
          onClick={handleSubmit}
          type="submit"
        >
          Add to {addToType}
        </Button>
      </DrawerFooter>
    </>
  );
};

ProductOverview.displayName = 'ProductOverview';

ProductOverview.propTypes = {
  itemId: PropTypes.string.isRequired,
  updateList: PropTypes.func
};

ProductOverview.defaultProps = {
  updateList: () => { }
};

ProductOverview.dataModel = extend(
  {
    product: params({
      itemId: string().isRequired(),
      dataSource: string()
    }).shape({
      itemId: string(),
      dataSources: string(),
      details: shape({
        description: string(),
        descriptiveAttributes: arrayOf(shape({
          value: string(),
        })),
      }),
    }),
  }
);
