import React, { useContext } from 'react';
import classnames from 'classnames';
import { bool } from 'prop-types';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import './packages-mini-loader.scss';

function returnLoadingPlaceholderKeyValue(key) {
  return `loadingPlaceholder-${key}`;
}

export const PackagesMiniPlaceholder = ({ isPackageLite }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const isDesktop = channel === 'desktop';

  const loadingArr = Array.from({ length: 6 }).fill(null);

  const litePlaceholderClasses = classnames('packages-mini-loader__lite', {
    'packages-mini-loader_lite--mobile': isMobile,
  });

  return (
    isPackageLite ? (
      <div data-component="PackagesMiniLitePlaceholder">
        <Placeholder className={litePlaceholderClasses} type="rect" />
      </div>
    ) : (
      <div className="packages-mini-loader" data-component="PackagesMiniPlaceholder">
        <Placeholder className="packages-mini-loader__main" />
        <div className="packages-mini-loader__tab-group">
          <div className="packages-mini-loader__tabs">
            {loadingArr.map((_, key) => (
              <Placeholder
                className="packages-mini-loader__tab"
                height="85px"
                key={returnLoadingPlaceholderKeyValue(key)}
              />
            ))}
          </div>
          {isDesktop && (
            <Placeholder className="packages-mini-loader__tab-content" width="100%" height="300px" type="rect" />
          )}
        </div>
      </div>
    )
  );
};

PackagesMiniPlaceholder.propTypes = {
  isPackageLite: bool
};
PackagesMiniPlaceholder.defaultProps = {
  isPackageLite: false
};

PackagesMiniPlaceholder.displayName = 'PackagesMiniPlaceholder';