import React from 'react';
import PropTypes from 'prop-types';

const PhoneLink = ({ phone, label }) => (
  <a href={`tel:${phone}`} className="sui-underline">
    <span>{label}</span>
  </a>
);

PhoneLink.displayName = 'PhoneLink';

PhoneLink.propTypes = {
  phone: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export { PhoneLink };