import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf, params, shape, string, useDataModel, extend
} from '@thd-nucleus/data-sources';
import { AddToContext } from '../../AddToContext';

export const AddToProductDetails = ({ products }) => {
  const {
    storeId,
    selectedProductDetails
  } = useContext(AddToContext);
  const itemid = products?.length > 0 ? products[0]?.itemId : selectedProductDetails?.itemId;
  const { data } = useDataModel('product', {
    variables: {
      itemId: itemid,
      storeId
    },
    skip: products?.length > 1 || !itemid
  });

  const { product } = data || {};

  const brandName = product?.identifiers?.brandName || product?.identifiers?.brandName;
  const productLabel = product?.identifiers?.productLabel || product?.identifiers?.productLabel;

  const image = product?.media?.images?.find(
    (img) => img.type === 'IMAGE' && img.subType === 'PRIMARY'
  );

  return (
    <div className="sui-flex">
      <img
        src={image?.url.replace('<SIZE>', '145')}
        alt={productLabel}
        className="sui-aspect-square sui-max-w-[20%] sm:sui-max-w-[30%]"
        width="145px"
        height="145px"
      />
      <div className="sui-pl-6 sui-text-xl">
        <strong>{brandName}&nbsp;</strong>{productLabel}
      </div>
    </div>
  );
};

AddToProductDetails.dataModel = extend({
  product: params({
    itemId: string().isRequired(),
  }).shape({
    identifiers: shape({
      brandName: string(),
      productLabel: string(),
      storeSkuNumber: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string()
      }))
    }),
  })
});

AddToProductDetails.displayName = 'AddToProductDetails';

AddToProductDetails.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  }))
};

AddToProductDetails.defaultProps = {
  products: []
};
