import {
  params, string, arrayOf, shape, customType, number,
  useDataModel, useLazyDataModel, extend, QueryProvider, bool
} from '@thd-nucleus/data-sources';

const useListDetails = (props) => {
  const { data: listData, loading: listLoading, refetch: listRefetch } = useDataModel('listDetails', {
    variables: {
      customerAccountID: props?.customerAccountID,
      userId: props?.userId,
      customerType: props?.customerType
    },
    fetchPolicy: 'cache-first',
    skip: props?.skip,
    ssr: true
  });

  return { listData, listLoading, listRefetch };
};

const useUpdateList = (props) => {
  const [updateList, updateListResponse] = useDataModel('updateList', {
    variables: props,
    fetchPolicy: 'no-cache',
    context: { withAuth: true }
  });

  return { updateList, updateListResponse };
};

const useCreateList = (props) => {
  const [createList, createListResponse] = useLazyDataModel('createList', {
    variables: props,
    fetchPolicy: 'no-cache',
    context: { withAuth: true },
    skip: props?.addToType !== 'List'
  });

  return { createList, createListResponse };
};

const listDetailsModel = {
  listDetails: params({
    customerAccountID: string().isRequired(),
    userId: string().isRequired(),
    customerType: customType('CustomerType').enum(['B2B', 'B2C'], 'B2C')
  }).shape({
    listResponse: shape({
      listDetails: arrayOf(shape({
        listId: string().isRequired(),
        listName: string()
      }))
    })
  })
};

const updateListModel = {
  updateList: params({
    id: string().isRequired(),
    operationName: customType('UpdateOperation').enum(['Update', 'RemoveItems', 'AddItems']),
    products: arrayOf(customType('ProductInput').shape({
      itemId: string(),
      quantity: number(),
    }).isRequired()
    )
  }).mutation().shape({
    id: string()
  })
};

const createListModel = {
  createList: params({
    name: string().isRequired(),
    products: arrayOf(
      customType('AddToListProductInput').shape({
        itemId: string(),
        quantity: number()
      })
    )
  }).mutation().shape({
    id: string(),
    name: string(),
    listAccessType: string(),
  })
};

export {
  useListDetails,
  useUpdateList,
  useCreateList,
  listDetailsModel,
  updateListModel,
  createListModel
};