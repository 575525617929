import React from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { ProductCard } from '../subcomponents/ProductCard';
import { propTypesPopularVariations, dataModelPopularVariations } from './constants';

const PopularVariationsComponent = ({ productId }) => {
  const {
    data,
    loading,
    error
  } = useDataModel('emtPopularVariations', {
    variables: {
      itemId: productId
    }
  });

  const popularVariationsList = data?.emtPopularVariations?.popularVariations;

  if (!data || loading || error || !popularVariationsList?.length) return null;

  return (
    <div className="sui-flex sui-flex-col sui-gap-4" data-component="PopularVariations">
      <Typography variant="h2">Popular Variations</Typography>
      <div className="sui-grid sui-gap-6 xl:sui-grid-cols-6 lg:sui-grid-cols-4 md:sui-grid-cols-4 sui-grid-cols-2">
        {popularVariationsList.map((product) => (
          <ProductCard
            product={product}
            key={product?.itemId}
          />
        ))}
      </div>
    </div>
  );
};

PopularVariationsComponent.displayName = 'PopularVariations';

PopularVariationsComponent.propTypes = propTypesPopularVariations;

PopularVariationsComponent.dataModel = dataModelPopularVariations;

export { PopularVariationsComponent };
