import React from 'react';
import {
  node
} from 'prop-types';

export const SuggestionsMenuFooter = ({
  footer
}) => {
  return (
    <>
      {footer && (
        <div
          id="suggestions-menu-footer"
          data-testid="suggestions-menu-footer"
          className="sui-pb-4"
        >
          {footer}
        </div>
      )}
    </>
  );
};

SuggestionsMenuFooter.displayName = 'SuggestionsMenuFooter';
SuggestionsMenuFooter.propTypes = {
  footer: node
};
SuggestionsMenuFooter.defaultProps = {
  footer: null
};