import { getAppId } from '../utils/helpers';

export const DtsTntVariables = (dtsModel, props, storeInfo, channel, isConsumerApp) => {
  const dtsanchorId = (dtsModel?.anchorId !== undefined) ? dtsModel?.anchorId : (props?.anchorId || props.itemId);
  const model = (Array.isArray(dtsModel.model)) ? props.testAPI : dtsModel.model;
  return {
    dtsopts: {
      opts: {
        variables: {
          anchorId: dtsanchorId,
          model: model || '',
          dataSource: model || '',
          key: dtsModel?.key || props?.requestKey,
          endpointType: dtsModel?.endpointType || 'product',
          appId: dtsModel?.appId || getAppId(channel, isConsumerApp),
          storeId: dtsModel?.storeId || storeInfo?.storeId,
          zipCode: dtsModel?.zipCode || storeInfo?.storeZip
        },
        skip: !storeInfo?.isLocalized,
        ssr: false
      }
    },
    qName: 'dts',
    dtsAnchor: dtsanchorId
  };
};

export const UseRecsCxtAndProps = (recsModelProps, props, storeInfo, channel, isConsumerApp) => {
  const recsanchorId = (recsModelProps?.anchorId !== undefined)
    ? recsModelProps?.anchorId : (props?.anchorId || props.itemId);
  const api = (Array.isArray(recsModelProps?.testAPI)) ? props.testAPI : recsModelProps?.testAPI;
  return {
    recsOpts: {
      opts: {
        variables: {
          anchorId: recsanchorId,
          appId: recsModelProps?.appId || getAppId(channel, isConsumerApp),
          apiName: api || '',
          dataSource: api || '',
          skipInstallServices: true,
          key: recsModelProps?.requestKey !== undefined ? recsModelProps?.requestKey : props?.requestKey,
          loyaltyMembershipInput: storeInfo?.membershipInformation?.data?.loyaltyMembership || null,
          maxResults: recsModelProps?.maxResults !== undefined ? recsModelProps?.maxResults : props?.maxResults,
          serviceType: recsModelProps?.serviceType !== undefined ? recsModelProps?.serviceType : props?.serviceType,
          storeId: recsModelProps?.storeId || storeInfo?.storeId,
          zipCode: recsModelProps?.zipCode || storeInfo?.storeZip
        },
        ssr: false,
        skip: !storeInfo?.isLocalized
      }
    },
    qName: 'recs',
    recsAnchor: recsanchorId
  };
};