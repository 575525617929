import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  Alert, Button, Link,
  DrawerBody, DrawerFooter
} from '@one-thd/sui-atomic-components';
import { SearchProducts } from './search-products/SearchProducts';
import { PreviouslyPurchased } from './previously-purchased/PreviouslyPurchased';
import { SearchResults } from './search-products/SearchResults';
import { AddToContext } from '../AddToContext';

export const DefaultScreen = ({
  keyword,
  onSearch,
  handleClose,
  projectName,
  projectId,
  selectedGroup,
  groupsExist,
  onSuccessGroupClick,
  selectedGroupId,
  products,
  selectedProject,
  newListDetails,
  updateList,
  customerType
}) => {
  const {
    showSuccessMessage,
    setShowSuccessMessage,
    showErrorMessage,
    setShowErrorMessage,
    addToType
  } = useContext(AddToContext);
  const successMessageRef = useRef(null);
  const errorMessageRef = useRef(null);
  const selectedProjectId = projectId || selectedProject.projectId;
  const selectedProjectName = projectName || selectedProject.projectName;

  const handleClick = () => {
    handleClose();
    onSuccessGroupClick(selectedGroupId.current);
    window.location.href = `/projects/${selectedProjectId}#group=${selectedGroupId.current}`;
  };

  const isMultipleProducts = products?.length > 1;

  const projectMessage = () => {
    return (
      <>
        {isMultipleProducts ? 'Items' : 'Item'} successfully added to <Link href={`/projects/${selectedProjectId}`}>{selectedProjectName}</Link>&nbsp;
        in group <Link component="button" onClick={handleClick}>{selectedGroup}</Link>&nbsp;
      </>
    )
  }

  const listMessage = () => {
    return (
      <>
        Your item has been added to your list.
      </>
    )
  }

  const handleAlertClose = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  }

  useEffect(() => {
    if (showSuccessMessage) {
      successMessageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    };
    if (showErrorMessage) {
      errorMessageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [showSuccessMessage, showErrorMessage]);

  return (
    <>
      <DrawerBody overflow="scroll">
        {showSuccessMessage && (
          <Alert
            status="success"
            onClose={handleAlertClose}
            data-testid="add-to-success-alert"
            ref={successMessageRef}
          >
            {addToType === 'Project' ? projectMessage() : listMessage()}
          </Alert>
        )}
        {showErrorMessage && (
          <Alert
            status="error"
            onClose={() => setShowErrorMessage(false)}
            data-testid="add-to-error-alert"
            ref={errorMessageRef}
          >
            Apologies, a temporary technical issue occured. Please try again soon.
          </Alert>
        )}
        {!products?.length > 0 && (
          <>
            <SearchProducts onSubmit={onSearch} />
            {keyword.length === 0 && customerType === 'B2B' && (
              <PreviouslyPurchased
                groupsExist={groupsExist}
                updateList={updateList}
              />
            )}
            <SearchResults
              keyword={keyword}
              groupsExist={groupsExist}
              updateList={updateList}
            />
          </>
        )}
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="add-to-cancel-button"
          variant={products?.length > 0 === 'search' ? 'secondary' : 'primary'}
          fullWidth
          onClick={handleClose}
          type="submit"
        > {products?.length > 0 === 'search' ? 'Cancel' : 'OK'}
        </Button>
      </DrawerFooter>
    </>
  );
};

DefaultScreen.propTypes = {
  keyword: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  projectId: PropTypes.string,
  selectedGroup: PropTypes.string,
  groupsExist: PropTypes.bool,
  selectedGroupId: PropTypes.shape({
    current: PropTypes.string
  }),
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  onSuccessGroupClick: PropTypes.func,
  selectedProject: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string
  }),
  newListDetails: PropTypes.shape({
    listId: PropTypes.string,
    listName: PropTypes.string
  }),
  updateList: PropTypes.func,
  customerType: PropTypes.string
};

DefaultScreen.defaultProps = {
  selectedGroup: null,
  selectedGroupId: { current: '' },
  products: [],
  onSuccessGroupClick: () => { },
  selectedProject: {},
  projectName: null,
  projectId: null,
  groupsExist: false,
  newListDetails: {},
  updateList: () => { },
  customerType: ''
};

DefaultScreen.dataModel = extend(
  SearchResults
);
