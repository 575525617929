import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, useDataModel, useLazyDataModel
} from '@thd-nucleus/data-sources';
import {
  Button, DrawerBody, DrawerFooter, TextField
} from '@one-thd/sui-atomic-components';
import { GROUP_ALREADY_EXITS_ERROR, SCREENS } from '../../AddToConstants';
import { validateGroupName } from '../../AddToHelpers';
import {
  useCreateGroup,
  projectDetailsModel,
  createGroupModel,
  useProjectDetails
} from '../../hooks/useProjects';
import { AddToContext } from '../../AddToContext';

export const CreateGroup = ({
  projectId,
  handleClose,
  onCreateGroup,
}) => {
  const [groupName, setGroupName] = useState();
  const [errorMessaging, setErrorMessaging] = useState('');
  const [isEnterPress, setIsEnterPress] = useState(false);
  const { setShowSuccessMessage, setScreen } = useContext(AddToContext);
  const { DEFAULT } = SCREENS;

  const { projectData, projectRefetch } = useProjectDetails({ projectId });

  const {
    defaultListId,
    defaultListMutationKey,
  } = projectData?.projectDetails || {};

  const { createGroup, createGroupResponse } = useCreateGroup({
    projectId,
    listId: defaultListId,
    mutationKey: defaultListMutationKey,
    groupName
  });

  const onClose = (isCreated = false) => {
    setGroupName('');
    setErrorMessaging('');
    if (isCreated) {
      projectRefetch();
      if (onCreateGroup) {
        onCreateGroup(groupName);
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleNameValidation = (value) => {
    if (isEnterPress) return;
    const { isError, errorMessage } = validateGroupName(value);
    if (isError) {
      setErrorMessaging(errorMessage);
    } else {
      setErrorMessaging('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setIsEnterPress(true);
    } else if (!isEnterPress) {
      setIsEnterPress(false);
    }
  };

  const handleOnChange = (event) => {
    const { value } = event.target;
    handleNameValidation(value);
    setGroupName(value);
  };

  const handleSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    if (!errorMessaging) {
      if (!groupName) {
        setErrorMessaging('Please enter a group name');

      } else {
        await createGroup();
      }
    }
  };

  useEffect(() => {
    if (createGroupResponse?.data?.createProjectListGroup?.groupId) {
      if (!onCreateGroup) {
        LIFE_CYCLE_EVENT_BUS.trigger('projects: group modification', {
          groupAction: 'group created'
        });
      }
      setShowSuccessMessage(true);
      setScreen(DEFAULT);
      projectRefetch();
      onClose(true);
    }

    if (createGroupResponse?.error) {
      if (createGroupResponse?.error?.message.includes(GROUP_ALREADY_EXITS_ERROR)) {
        setErrorMessaging(GROUP_ALREADY_EXITS_ERROR);
      }
    }

  }, [createGroupResponse?.data, createGroupResponse?.error]);

  return (
    <>
      <DrawerBody>
        <form
          onSubmit={handleSubmit}
        >
          <TextField
            id="group-name"
            label="Group Name"
            placeholder="Enter Group Name"
            type="text"
            onBlur={() => { handleNameValidation(groupName); }}
            value={groupName}
            fullWidth
            onChange={(event) => { handleOnChange(event); }}
            onKeyDown={(event) => { handleKeyDown(event); }}
            status={
              errorMessaging ? 'error' : null
            }
            statusMessage={
              errorMessaging || ''
            }
            InputProps={{
              inputProps: {
                'data-testid': 'group-name-input',
              }
            }}
          />
        </form>
      </DrawerBody>
      <DrawerFooter position="center">
        <div className="sui-flex sui-flex-col sui-gap-2">
          <Button
            data-testid="create-group-submit-button"
            variant="primary"
            fullWidth
            onClick={handleSubmit}
            type="submit"
          > Create Group
          </Button>
          <Button
            data-testid="create-group-cancel-button"
            variant="secondary"
            fullWidth
            onClick={handleClose}
            type="submit"
          > Cancel
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

CreateGroup.propTypes = {
  projectId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func,
};

CreateGroup.defaultProps = {
  onCreateGroup: null
};

CreateGroup.dataModel = {
  projectDetailsModel,
  createGroupModel
};
