import { Model } from './Model';

class ReviewStatsModel extends Model {

  get stats() {
    return this.currentSku?.FilteredReviewStatistics || null;
  }

  get averageOverallRating() {
    const rawAverage = this.currentSku?.FilteredReviewStatistics?.AverageOverallRating || 0.00;
    return parseFloat((Math.round(rawAverage * 10)) / 10);
  }

  get currentSku() {
    const reviewCount = this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'TotalReviewCount'],
      null
    );

    if (reviewCount && (reviewCount === this.totalResults)) {
      return this.getNestedProperties(['Includes', 'Products', 'store'], null);
    }
    const itemsSku = this.getNestedProperties(['Includes', 'Products', 'items'], [])
      .filter((el) => el?.FilteredReviewStatistics?.TotalReviewCount === this.totalResults);

    if (itemsSku.length > 0) {
      return itemsSku[0];
    }

    return this.getNestedProperties(['Includes', 'Products', 'store'], null);
  }

  get profileGenderAgeDistribution() {
    let stats = [{
      ratingValue: 'DIYer',
      count: 0,
      id: 'profile_diy'
    },
    {
      ratingValue: 'Professional',
      count: 0,
      id: 'profile_pro'
    },
    {
      ratingValue: 'Male',
      count: 0,
      id: 'gender_male'
    },
    {
      ratingValue: 'Female',
      count: 0,
      id: 'gender_female'
    },
    {
      ratingValue: '18to24',
      count: 0,
      id: 'age_18to24',
      label: '18 to 24'
    },
    {
      ratingValue: '25to34',
      count: 0,
      id: 'age_25to34',
      label: '25 to 34'
    },
    {
      ratingValue: '35to44',
      count: 0,
      id: 'age_35to44',
      label: '35 to 44'
    },
    {
      ratingValue: '45to54',
      count: 0,
      id: 'age_45to54',
      label: '45 to 54'
    },
    {
      ratingValue: '55to64',
      count: 0,
      id: 'age_54to64',
      label: '54 to 64'
    },
    {
      ratingValue: '65orOver',
      count: 0,
      id: 'age_65orOver',
      label: '65 or Over'
    }
    ];

    const { Age, Gender, HomeGoodsProfile } = this.currentSku?.FilteredReviewStatistics?.ContextDataDistribution || [];

    return stats.map((element) => {
      const ageDistribution = Age?.Values?.filter((el) => el.Value === element.ratingValue)?.[0];
      if (ageDistribution) {
        return {
          ratingValue: ageDistribution.Value,
          count: ageDistribution.Count,
          label: element.label,
          id: element.id
        };
      }
      const genderDistribution = Gender?.Values?.filter((el) => el.Value === element.ratingValue)?.[0];
      if (genderDistribution) {
        return {
          ratingValue: genderDistribution.Value,
          count: genderDistribution.Count,
          label: element.label,
          id: element.id
        };
      }
      const profileDistribution = HomeGoodsProfile?.Values?.filter((el) => el.Value === element.ratingValue)?.[0];
      if (profileDistribution) {
        return {
          ratingValue: profileDistribution.Value,
          count: profileDistribution.Count,
          label: element.label,
          id: element.id
        };
      }
      return element;
    });
  }

  get ratingDistribution() {
    let stats = [{
      ratingValue: 5,
      count: 0,
      ratingPercentage: 0
    },
    {
      ratingValue: 4,
      count: 0,
      ratingPercentage: '0'
    },
    {
      ratingValue: 3,
      count: 0,
      ratingPercentage: '0'
    },
    {
      ratingValue: 2,
      count: 0,
      ratingPercentage: '0'
    },
    {
      ratingValue: 1,
      count: 0,
      ratingPercentage: '0'
    }
    ];
    const totalResults = this.totalReviewCount;
    const distribution = this.currentSku?.FilteredReviewStatistics?.RatingDistribution || [];
    return stats.map((element) => {
      const starDistribution = distribution.filter((el) => el.RatingValue === element.ratingValue)[0];
      if (starDistribution) {
        return {
          ratingValue: starDistribution.RatingValue,
          count: starDistribution.Count,
          ratingPercentage: Math.round((starDistribution.Count / totalResults) * 100).toString()
        };
      } return element;
    });
  }

  getRatingDistribution(itemId) {
    let ratingDistribution;
    const store = this.currentSku || null;

    if (!store && itemId) {
      ratingDistribution = this.getRatingDistributionForItemId(itemId);
      if (ratingDistribution) {
        return ratingDistribution;
      }
    }
    ratingDistribution = this.getRatingDistributionFromStore();
    return ratingDistribution;
  }

  getRatingDistributionFromStore() {
    const store = this.getNestedProperties(['Includes', 'Products', 'store'], null);
    if (!store) {
      return null;
    }
    const distribution = this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'RatingDistribution'],
      []
    );

    return distribution.map((element) => {
      return {
        ratingValue: element.RatingValue,
        count: element.Count
      };
    });
  }

  getRatingDistributionForItemId(itemId) {
    const products = this.getNestedProperties(['Includes', 'Products'], []);
    const item = products[itemId];

    if (!item) {
      return null;
    }
    const distribution = this.getNestedProperties(
      ['Includes', 'Products', itemId, 'FilteredReviewStatistics', 'RatingDistribution'],
      []
    );

    return distribution.map((element) => {
      return {
        ratingValue: element.RatingValue,
        count: element.Count
      };
    });
  }

  get verifiedPurchaserStats() {
    let values = this.currentSku?.FilteredReviewStatistics?.ContextDataDistribution?.VerifiedPurchaser?.Values || [];
    if (!values.length) {
      return 0;
    }
    return values.filter((value) => value.Value === 'True')
      .map((key) => {
        return Number(key.Count);
      })[0];
  }

  get totalReviewCount() {
    return this.currentSku?.FilteredReviewStatistics?.TotalReviewCount || 0;
  }

  /** This method will be helpful to get the filtered results
   * when there is a search/filter operation performed on BV */
  get totalResults() {
    return this.getNestedProperties(['TotalResults'], 0);
  }

  get searchText() {
    return this.getNestedProperties(['FilterSelected', 'SearchText'], '');
  }

  get filterSelected() {
    return Object.values(this.getNestedProperties(['FilterSelected'], ''));
  }

  get secondaryRatingsAverages() {
    const secondaryRatingAverages = this.currentSku?.FilteredReviewStatistics?.SecondaryRatingsAverages || {};
    return Object.values(secondaryRatingAverages).filter((val) => !!val);
  }

  get contextDataDistribution() {
    let contextDataDistribution = this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'ContextDataDistribution'],
      {}
    );
    return Object.values(contextDataDistribution);
  }

  get contextDataDistributionGender() {
    return this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'ContextDataDistribution', 'Gender', 'Values'],
      {}
    );
  }

  get contextDataDistributionAge() {
    return this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'ContextDataDistribution', 'Age', 'Values'],
      {}
    );
  }

  get contextDataDistributionHomeGoodsProfile() {
    return this.getNestedProperties(
      [
        'Includes',
        'Products',
        'store',
        'FilteredReviewStatistics',
        'ContextDataDistribution',
        'HomeGoodsProfile',
        'Values'
      ],
      {}
    );
  }

  get contextDataDistributionExpertise() {
    return this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'ContextDataDistribution', 'Expertise', 'Values'],
      {}
    );
  }

  get totalRecommendedCount() {
    return parseInt(this.currentSku?.FilteredReviewStatistics?.TotalRecommendedCount, 10);
  }

  get recommendedCount() {
    return parseInt(this.currentSku?.FilteredReviewStatistics?.RecommendedCount, 10) || 0;
  }

  get recommendationPercentage() {
    const recommendCount = parseInt(this.currentSku?.FilteredReviewStatistics?.RecommendedCount, 10) || 0;
    const notRecommendedCount = parseInt(this.currentSku?.FilteredReviewStatistics?.NotRecommendedCount, 10) || 0;
    return String(Math.round((recommendCount / (recommendCount + notRecommendedCount)) * 100) || 0);
  }

  get secondaryRatingsLabels() {
    return this.getNestedProperties(
      ['Includes', 'Products', 'store', 'FilteredReviewStatistics', 'SecondaryRatingsAveragesOrder'],
      []
    );
  }

  get name() {
    return this.getNestedProperties(['Includes', 'Products', 'store', 'Name'], '');
  }

  get imageURL() {
    return this.getNestedProperties(['Includes', 'Products', 'store', 'ImageUrl'], '');
  }

  get productBrand() {
    return this.getNestedProperties(['Includes', 'Products', 'store', 'Brand', 'Name'], '');
  }

  get productDescription() {
    return this.getNestedProperties(['Includes', 'Products', 'store', 'Description'], '');
  }

  get productId() {
    return this.getNestedProperties(['Includes', 'Products', 'store', 'Id'], '');
  }

  get pages() {
    return this.getNestedProperties(['pagination', 'pages'], []);
  }

  get currentPage() {
    const selectedPage = this.pages.find((page) => page.isSelectedPage);
    if (selectedPage) return Number(selectedPage.label);

    return Number(this.getNestedProperties(['pagination', 'pages', 0, 'label'], 1));
  }

  get pageNeighbours() {
    return Number(this.getNestedProperties(['pagination', 'nextPage', 'label'], 1));
  }

  get totalPages() {
    return Number(this.getNestedProperties(['pagination', 'pages', this.pages.length - 1, 'label'], 1));
  }

  get pageContext() {

    let { totalPages } = this;
    let { currentPage } = this;
    let { pageNeighbours } = this;

    // page context
    return {
      totalPages,
      currentPage,
      pageNeighbours
    };
  }
}

export { ReviewStatsModel };