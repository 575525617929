import React from 'react';
import PropTypes from 'prop-types';

import { RatingMeter } from '@one-thd/sui-atomic-components';

import { BundleRating } from '../models/dataModel';
import { useBundleRating } from '../hooks/useBundleRating';

/**
 * BundleMiniRating component displays the average rating and total number of reviews for a given bundle.
 * If there are no reviews, the component returns null.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.bundle - The bundle object containing rating information.
 * @returns {JSX.Element|null} The rendered component or null if there are no reviews.
 */
const BundleMiniRating = ({ bundle }) => {
  const { averageRating, totalResults, noReviews } = useBundleRating({ bundle });
  if (noReviews) {
    return null;
  }

  const averageRatingLabel = totalResults > 0 ? `(${totalResults})` : '';
  return (
    <div data-testid="bundle-ratings">
      <RatingMeter
        value={averageRating}
        label={averageRatingLabel}
        RatingProps={{
          color: 'brand'
        }}
        data-testid="rating-button"
      />
    </div>
  );
};

BundleMiniRating.propTypes = {
  bundle: PropTypes.shape({})
};

BundleMiniRating.defaultProps = {
  bundle: {}
};

BundleMiniRating.displayName = 'BundleMiniRating';

BundleMiniRating.dataModel = BundleRating;

export { BundleMiniRating };