import { copy } from '@thd-olt-functional/utils';

export const getCategories = (data, pageType) => {
  let categories = [];
  if (data?.product?.taxonomy?.breadCrumbs) {
    categories = copy(data?.product?.taxonomy?.breadCrumbs).data;
  } else if (pageType === 'search' && data?.searchModel?.dimensions) {
    const dimensions = data?.searchModel?.dimensions;
    const CategoryDimensions = dimensions.find((dim) => dim.label === 'Category' && dim.refinements);
    if (CategoryDimensions) {
      categories = copy(CategoryDimensions.refinements).data;
    }
  } else if (pageType === 'browse' && data?.searchModel?.taxonomy?.breadCrumbs) {
    categories = copy(data?.searchModel?.taxonomy?.breadCrumbs).data;
  }
  return categories;
};
