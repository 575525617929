/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import useControlled from '@one-thd/sui-atomic-components/dist/private/hooks/useControlled';
import { IconButton } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-atomic-components/dist/private/icons/ArrowForward';
import { ArrowBack } from '@one-thd/sui-atomic-components/dist/private/icons/ArrowBack';
import { SortDesc } from '@one-thd/sui-atomic-components/dist/private/icons/SortDesc';
import { ViewChangerButton } from '../private/components/calendar/ViewChangerButton';
import { CalendarWeekHeader } from '../private/components/calendar/CalendarWeekHeader';
import { CalendarCell } from './CalendarCell';
import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';
import { addDays, isSameDate } from './CalendarUtils';

dayjs.extend(isToday);
dayjs.extend(isBetween);

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December'
];

const WEEK_SIZE = 7;
const CALENDAR_LIMIT = 2;

const showMonthAndYear = (month, year) => {
  return month > 11 ? `${MONTHS[month - 12]} ${year + 1}` : `${MONTHS[month]} ${year}`;
};

const formatDay = (string) => {
  return parseInt(string, 10);
};

const DateCalendar = React.forwardRef((props, ref) => {

  const {
    autoFocus,
    calendars = 1,
    getDataSlot = () => {},
    defaultValue: defaultValueProp,
    unavailableDates: excludeArray,
    maxDate,
    minDate,
    multiple,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    value: valueProp
  } = props;

  const calendarIterations = calendars <= CALENDAR_LIMIT || calendars < 1 ? calendars : 1;
  const numberOfCalendars = Array(calendarIterations).fill().map((el, index) => index);

  const [selectedDates, setSelectedDates] = useControlled({
    controlled: valueProp,
    defaultValue: defaultValueProp
  });

  const [selectedMonth, setSelectedMonth] = useState(dayjs(selectedDates).month() || dayjs().month());
  const [selectedYear, setSelectedYear] = useState(dayjs(selectedDates).year() || dayjs().year());
  const [selectedPage, setSelectedPage] = useState(0);

  const [currentView, setCurrentView] = useState('calendar');
  const [internalFocusedDay, setInternalFocusedDay] = useState(selectedDates || dayjs());
  const [internalHasFocus, setInternalHasFocus] = useControlled({
    controlled: autoFocus
  });

  let monthInclusionRange = selectedMonth + (calendars - 1);

  useEffect(() => {
    if (valueProp) {
      const valuePropMonth = dayjs(selectedDates).month();
      if (Array.isArray(valueProp)) {
        setInternalFocusedDay(valueProp);
        if (valuePropMonth < selectedMonth || valuePropMonth > monthInclusionRange) {
          setSelectedMonth(dayjs(valueProp[0]).month());
        }
        setSelectedYear(dayjs(valueProp[0]).year());
      } else {
        setInternalFocusedDay(valueProp);
        if (valuePropMonth < selectedMonth || valuePropMonth > monthInclusionRange) {
          setSelectedMonth(dayjs(valueProp).month());
        }
        setSelectedYear(dayjs(valueProp).year());
      }
    }
  }, [valueProp]);

  const checkIfExclude = (dateValue) => {
    let isExcluded = false;
    let isBeforeMin = false;
    let isAfterMax = false;
    if (excludeArray) {
      isExcluded = excludeArray.some((el) => dateValue.isSame(dayjs(el), 'days'));
    }
    if (minDate) {
      isBeforeMin = dateValue.isBefore(dayjs(minDate));
    }
    if (maxDate) {
      isAfterMax = dateValue.isAfter(dayjs(maxDate));
    }
    const isDisabled = isExcluded || isBeforeMin || isAfterMax;
    return isDisabled;
  };

  const checkIfSelected = (dateValue) => {
    if (selectedDates) {
      if (Array.isArray(selectedDates)) {
        return (selectedDates.some((el) => (
          dateValue.isSame(dayjs(el), 'days')
        )));
      }
      return dateValue.isSame(dayjs(selectedDates), 'days');
    }
    return false;
  };

  const mapDateValue = (dateValue, month) => {
    return {
      date: dateValue.toDate(),
      day: dateValue.date(),
      now: dateValue.isToday(),
      month,
      data: getDataSlot(dateValue.format()),
      disabled: checkIfExclude(dateValue),
      selected: checkIfSelected(dateValue),
      isFirst: dateValue.date() === dateValue.startOf('month').date(),
      isLast: dateValue.date() === dateValue.endOf('month').date()
    };
  };

  const generateDates = (year, month) => {
    const target = new Date(year, month);
    const parsedDate = dayjs(target);
    const daysInMonth = parsedDate.daysInMonth();

    const monthEndOffset = 6 - parsedDate.endOf('month').day();
    const startDateOffset = parsedDate.startOf('month').day();

    const prevMonth = parsedDate.subtract(1, 'months');
    const nextMonth = parsedDate.add(1, 'months');

    const prevMonthLastDay = prevMonth.endOf('month').date();
    const currentMonthDays = Array.from(
      { length: daysInMonth }, (val, i) => mapDateValue(parsedDate.date(i + 1), 'current')
    );
    const prevMonthDays = Array.from(
      { length: startDateOffset }, (val, index) => mapDateValue(prevMonth.date(prevMonthLastDay - startDateOffset + index), 'prev')
    );
    const nextMonthDays = Array.from({ length: monthEndOffset }, (val, index) => mapDateValue(nextMonth.date(index + 1), 'next'));

    const mergedDays = [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
    return mergedDays;
  };

  const splitIntoWeeks = (dates) => {
    const weeks = [];
    for (let i = 0; i < dates.length; i += WEEK_SIZE) {
      const chunk = dates.slice(i, i + WEEK_SIZE);
      weeks.push(chunk);
    }
    return weeks;
  };

  const calendarDates = useMemo(() => {
    const calendarArray = numberOfCalendars.map(
      (el) => splitIntoWeeks(generateDates(selectedYear, selectedMonth + el))
    );
    return calendarArray;
  }, [selectedDates, selectedMonth, selectedYear]);

  const selectSingleDate = (event, value) => {
    const finalValue = isSameDate(value, selectedDates) ? undefined : value;
    setSelectedDates(finalValue);
    if (onChange) {
      const formattedValue = finalValue && dayjs(finalValue).format();
      onChange(event, formattedValue);
    }
  };

  const selectMultipleDates = (event, value) => {
    let finalValue = [];
    if (Array.isArray(selectedDates)) {
      if (selectedDates.some((el) => isSameDate(value, el))) {
        const filteredArray = selectedDates.filter((el) => !(isSameDate(value, el)));
        finalValue = [...filteredArray];
      } else {
        finalValue = [...selectedDates, value];
      }
    } else {
      finalValue = [value];
    }
    setSelectedDates(finalValue);
    if (onChange) {
      const finalParsedArray = finalValue.map((el) => (dayjs(el).format()));
      onChange(event, finalParsedArray);
    }
  };

  const handleOnChange = (event, value) => {
    setInternalFocusedDay(value);
    if (multiple) {
      selectMultipleDates(event, value);
    } else {
      selectSingleDate(event, value);
    }
  };

  const handleNextButton = (event) => {
    switch (currentView) {
      case 'month':
        setSelectedYear((prevState) => (prevState + 1));
        break;
      case 'year':
        setSelectedPage((prevState) => prevState + 1);
        break;
      default: {
        if (selectedMonth < 11) {
          setInternalFocusedDay(dayjs(new Date(selectedYear, selectedMonth + 1)));
          setSelectedMonth((prevState) => (prevState + 1));
        } else {
          setInternalFocusedDay(dayjs(new Date(selectedYear + 1, 0)));
          setSelectedMonth(0);
          setSelectedYear((prevState) => (prevState + 1));
        }
        break;
      }
    }
  };

  const handleBackButton = (event) => {
    switch (currentView) {
      case 'month':
        setSelectedYear((prevState) => (prevState - 1));
        break;
      case 'year':
        setSelectedPage((prevState) => prevState - 1);
        break;
      default: {
        if (selectedMonth > 0) {
          setInternalFocusedDay(dayjs(new Date(selectedYear, selectedMonth - 1)));
          setSelectedMonth((prevState) => (prevState - 1));
        } else {
          setInternalFocusedDay(dayjs(new Date(selectedYear - 1, 11)));
          setSelectedMonth(11);
          setSelectedYear((prevState) => (prevState - 1));
        }
        break;
      }
    }
  };

  const handleMonthChange = (event, value) => {
    if (!Number.isNaN(value)) {
      setInternalFocusedDay(dayjs(new Date(selectedYear, value)));
      setSelectedMonth(value);
      setCurrentView('calendar');
    }
  };

  const handleYearChange = (event, value) => {
    if (!Number.isNaN(value)) {
      setInternalFocusedDay(dayjs(new Date(value, selectedMonth)));
      setSelectedYear(value);
      setCurrentView('calendar');
      setSelectedPage(0);
    }
  };

  const moveFocus = (focusedDate, value, granularity = 'days') => {
    const newFocusDate = addDays(focusedDate, value, granularity);
    const newFocusedMonth = newFocusDate.month();
    const newFocusedYear = newFocusDate.year();

    if (newFocusedMonth < selectedMonth || newFocusedMonth > monthInclusionRange) {
      setSelectedMonth(newFocusedMonth);
    }
    if (newFocusedYear !== selectedYear) {
      setSelectedYear(newFocusedYear);
    }
    if (Array.isArray(excludeArray) && excludeArray.some((el) => isSameDate(el, newFocusDate))) {
      return moveFocus(newFocusDate, value);
    }
    if (maxDate && newFocusDate.isAfter(maxDate)) {
      const parsedMaxDate = dayjs(maxDate);
      setSelectedMonth(parsedMaxDate.month());
      setSelectedYear(parsedMaxDate.year());
      setInternalFocusedDay(dayjs(maxDate));
      return;
    }
    if (minDate && newFocusDate.isBefore(minDate)) {
      const parsedMinDate = dayjs(minDate);
      setSelectedMonth(parsedMinDate.month());
      setSelectedYear(parsedMinDate.year());
      setInternalFocusedDay(dayjs(minDate));
      return;
    }
    setInternalFocusedDay(newFocusDate);
  };

  const focusDay = (event, value) => {
    setInternalHasFocus(true);

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event, value) => {
    if (internalHasFocus) {
      setInternalHasFocus(false);
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const keyLog = {};
  const handleKeyDown = (event) => {
    const nextItemKey = 'ArrowRight';
    const previousItemKey = 'ArrowLeft';
    const nextRowKey = 'ArrowDown';
    const previousRowKey = 'ArrowUp';
    const firstDayOfWeekKey = 'Home';
    const lastDayOfWeekKey = 'End';
    const nextMonthKey = 'PageDown';
    const previousMonthKey = 'PageUp';
    const modifierKey = 'Shift';

    if (event.type === 'keydown') {
      keyLog[event.key] = true;
    } else if (event.type === 'keyup') {
      delete keyLog[event.key];
    }

    switch (event.key) {
      case nextItemKey:
        event.preventDefault();
        moveFocus(internalFocusedDay, 1);
        break;
      case previousItemKey:
        event.preventDefault();
        moveFocus(internalFocusedDay, -1);
        break;
      case nextRowKey:
        event.preventDefault();
        moveFocus(internalFocusedDay, 7);
        break;
      case previousRowKey:
        event.preventDefault();
        moveFocus(internalFocusedDay, -7);
        break;
      case firstDayOfWeekKey:
        event.preventDefault();
        moveFocus(internalFocusedDay.day(0), 0);
        break;
      case lastDayOfWeekKey:
        event.preventDefault();
        moveFocus(internalFocusedDay.day(6), 0);
        break;
      case nextMonthKey:
        event.preventDefault();
        if (keyLog[modifierKey]) {
          moveFocus(internalFocusedDay, 1, 'year');
          break;
        }
        moveFocus(internalFocusedDay, 1, 'month');
        break;
      case previousMonthKey:
        event.preventDefault();
        if (keyLog[modifierKey]) {
          moveFocus(internalFocusedDay, -1, 'year');
          break;
        }
        moveFocus(internalFocusedDay, -1, 'month');
        break;
      default:
        break;
    }
  };

  const onInvalidFocus = (focusedDate) => {
    moveFocus(focusedDate, 1);
  };

  let yearPageRangeString = `${selectedYear + (selectedPage * 12)} - ${selectedYear + (selectedPage * 12) + 11}`;
  let nextButtonLabel = currentView === 'year' ? 'next range of 12 years' : 'next month';
  let previousButtonLabel = currentView === 'year' ? 'previous range of 12 years' : 'previous month';

  const handleViewChange = () => {
    switch (currentView) {
      case 'month':
        setCurrentView('year');
        break;
      case 'year':
        setCurrentView('calendar');
        break;
      default:
        setCurrentView('month');
    }
  };

  const calendarClasses = classNames('sui-text-center sui-min-w-[280px] sui-max-w-[336px] sui-w-full sui-mb-2');
  const navigationClasses = classNames('sui-w-full sui-flex sui-justify-between sui-max-h-[32px] sui-mb-2 sui-min-h-[32px]');

  return (
    <div className="sui-flex sui-justify-center sui-gap-6" ref={ref}>
      {calendarDates.map((calendarWeeks, calendarIndex) => (
        <div className={calendarClasses} key={`Calendar ${calendarIndex}`}>
          <div className={navigationClasses}>
            <ViewChangerButton
              onClick={handleViewChange}
            >
              <>
                {(currentView === 'calendar' || calendarIndex !== 0) ? `${showMonthAndYear(selectedMonth + calendarIndex, selectedYear)}` : ''}
                {(currentView === 'month' && calendarIndex === 0) ? selectedYear : ''}
                {(currentView === 'year' && calendarIndex === 0) ? yearPageRangeString : ''}
                {(currentView === 'year' && calendarIndex === 0) || <SortDesc size="small" />}
              </>
            </ViewChangerButton>
            {(calendars === 1 || (calendars > 1 && calendarIndex !== 0)) && (
              <div>
                <IconButton
                  aria-label={previousButtonLabel}
                  icon={ArrowBack}
                  onClick={handleBackButton}
                />
                <IconButton
                  aria-label={nextButtonLabel}
                  icon={ArrowForward}
                  onClick={handleNextButton}
                />
              </div>
            )}
          </div>
          {(currentView === 'calendar' || calendarIndex !== 0) && (
            <table className="sui-w-full">
              <CalendarWeekHeader />
              <tbody
                onKeyDown={handleKeyDown}
              >
                {calendarWeeks.map((row, index) => (
                  <tr className="sui-grid sui-grid-cols-7" key={index}>
                    {row.map((el) => (
                      <td
                        key={el.date}
                        className="sui-max-h-[48px] sui-min-h-[40px] sui-max-w-[48px] sui-min-w-[40px] sui-aspect-square sui-p-0"
                      >
                        <CalendarCell
                          current={el.now}
                          disabled={el.month !== 'current' || el.disabled}
                          focusable={internalFocusedDay}
                          insideCurrentMonth={el.month === 'current'}
                          isViewFocused={internalHasFocus}
                          selected={el.selected}
                          data={el.data}
                          range={el.range}
                          readOnly={readOnly}
                          value={el.date}
                          key={el.date}
                          onClick={handleOnChange}
                          onFocus={focusDay}
                          onBlur={handleBlur}
                          onInvalidFocus={onInvalidFocus}
                        >
                          {el.month === 'current' ? formatDay(el.day) : ''}
                        </CalendarCell>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {(currentView === 'month' && calendarIndex === 0) && (
            <MonthPicker
              autoFocus={internalHasFocus}
              month={selectedMonth}
              onChange={handleMonthChange}
            />
          )}
          {(currentView === 'year' && calendarIndex === 0) && (
            <YearPicker
              autoFocus={internalHasFocus}
              year={selectedYear}
              page={selectedPage}
              onChange={handleYearChange}
            />
          )}
        </div>
      ))}
    </div>
  );
});

DateCalendar.displayName = 'DateCalendar';

DateCalendar.propTypes = {
  /**
   * If `true`, the component will be focused during initial mount.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * Number of calendars to render.
   * @default 1
   */
  calendars: PropTypes.oneOf([1, 2]),
  /**
   * The default value selected in the Calendar during initial mouint. Use when the component is not controlled.
   */
  defaultValue: PropTypes.string,
  /**
   * Adds extra content to each cell's data slot. Function receives an ISO formatted date string
   * and the returned content is passed to the data slot.
   * __Note:__ Expensive logic can impact performance.
   */
  getDataSlot: PropTypes.func,
  /**
   * Values in the array will be compared against the ones in the calendar. If a match is found, they will be disabled.
   */
  unavailableDates: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.instanceOf(Date))
  ]),
  /**
   * Maximal selectable date.
   */
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  /**
   * Minimal selectable date.
   */
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  /**
   * If `true`, will make it possible to select more than one separate date at a time.
   * To control a multiple calendar, it will require an array as the value.
   */
  multiple: PropTypes.bool,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Event fired when the user changes the selected date.
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * If `true`, will make the component read only.
   */
  readOnly: PropTypes.bool,
  /**
   * The value of the selected date. If using multiple selection, an array will be used instead.
   */
  value: PropTypes.string
};

DateCalendar.defaultProps = {
  calendars: 1,
  multiple: false,
  readOnly: false,
};

export { DateCalendar };
