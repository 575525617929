import React, { createContext, useMemo } from 'react';
import { node } from 'prop-types';
import MESSAGES from '../config/messages';
import { usePromoModel } from '../hooks/usePromoModel';
import { formatPrice, formattedReward, toWord } from '../utils/promo-presentation-utils';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS, DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS, MSB_SUBEXPERIENCE_TAGS
} from '../utils/constants';

/*
 * MESSAGES is a map of strings that the messages within the drawer can pull from. Dynamic values can be inserted
 * by writing in the format `$varName`.
 *
 * Current list of known variables:
 *
 * - qualifyingAmount
 * - rewardAmount
 * - src1ListCount
 * - tgt1ListCount
 *
 * Escape characters can also be used to apply certain styling:
 * - `\n` new line
 * - `\u00a0` non breaking space
 */

const DEFAULT_PROMO_MESSAGE_CONTEXT = Object.freeze({ messages: MESSAGES });

export const PromoMessageContext = createContext(DEFAULT_PROMO_MESSAGE_CONTEXT);

export const PromoMessageProvider = ({ children }) => {
  const {
    rewardTiers,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    subExperience
  } = usePromoModel();

  // The names of the keys matter for this object. The message format function uses the key names
  // to determine where in the string it needs to replace. Be careful if you change any property names here.
  const messageReplaceValues = useMemo(() => {
    let qualifyingAmount = null;
    let rewardAmount = null;

    const {
      minThresholdVal,
      rewardVal,
      rewardType,
    } = rewardTiers?.[0] || {};

    switch (subExperience) {
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff: {
      rewardAmount = `${rewardVal}%`;
      break;
    }
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
    case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff: {
      rewardAmount = formattedReward({ rewardVal }, true, rewardType);
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne: {
      qualifyingAmount = formatPrice(src1EligibilityCriteria, true);
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff: {
      qualifyingAmount = formatPrice(src1EligibilityCriteria, true);
      rewardAmount = `${rewardVal}%`;
      break;
    }
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff: {
      qualifyingAmount = formatPrice(src1EligibilityCriteria, true);
      rewardAmount = formattedReward({ rewardVal }, true, rewardType);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB1: {
      qualifyingAmount = minThresholdVal;
      rewardAmount = `${rewardVal}%`;
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB2: {
      qualifyingAmount = minThresholdVal;
      rewardAmount = formatPrice(rewardVal, true);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB3: {
      qualifyingAmount = minThresholdVal;
      rewardAmount = formatPrice(rewardVal, true);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB4: {
      qualifyingAmount = formatPrice(minThresholdVal, true);
      rewardAmount = `${rewardVal}%`;
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB5: {
      qualifyingAmount = formatPrice(minThresholdVal, true);
      rewardAmount = formatPrice(rewardVal, true);
      break;
    }
    case MSB_SUBEXPERIENCE_TAGS.MSB6: {
      qualifyingAmount = formatPrice(minThresholdVal, true);
      rewardAmount = formatPrice(rewardVal, true);
      break;
    }
    default: {
      break;
    }
    }

    return Object.freeze({
      qualifyingAmount,
      rewardAmount,
      src1MinPurchaseQuantity: src1EligibilityCriteria || null,
      src1MinPurchaseQuantityWord: toWord(src1EligibilityCriteria) || null,
      tgt1MinPurchaseQuantity: tgt1EligibilityCriteria || null,
      tgt1MinPurchaseQuantityWord: toWord(tgt1EligibilityCriteria) || null
    });
  }, [
    rewardTiers,
    subExperience,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria
  ]);

  const contextValue = useMemo(() => {
    return Object.freeze({
      messageReplaceValues,
      messages: MESSAGES
    });
  }, [messageReplaceValues]);

  return (
    <PromoMessageContext.Provider value={contextValue}>
      {children}
    </PromoMessageContext.Provider>
  );
};

PromoMessageProvider.propTypes = {
  children: node.isRequired
};
