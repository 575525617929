import React from 'react';
import {
  extend,
  params,
  shape as shapeType,
  arrayOf,
  string as stringType
} from '@thd-nucleus/data-sources';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';

import { MediaGalleryMobile } from './MediaGalleryMobile';
import { dataModel } from './dataModel';
import { mediaGalleryMobilePropTypes, mediaGalleryMobileDefaultPropTypes } from './MediaGalleryPropTypes';

export const MediaGalleryWithHotspotsMobile = (props) => {
  return (
    <MediaGalleryMobile {...props} />
  );
};

MediaGalleryWithHotspotsMobile.displayName = 'MediaGalleryWithHotspotsMobile';

MediaGalleryWithHotspotsMobile.propTypes = mediaGalleryMobilePropTypes;

MediaGalleryWithHotspotsMobile.defaultProps = mediaGalleryMobileDefaultPropTypes;

MediaGalleryWithHotspotsMobile.dataModel = extend({}, {
  product: params({ itemId: stringType().isRequired() }).shape({
    media: shapeType({
      images: arrayOf(shapeType({
        hotspots: arrayOf(shapeType({
          coordinate: shapeType({
            xCoordinate: stringType(),
            yCoordinate: stringType()
          }),
          omsIDs: arrayOf(stringType()),
        })),
        ocrHotspots: arrayOf(shapeType({
          coordinate: shapeType({
            xCoordinate: stringType(),
            yCoordinate: stringType()
          }),
          omsIDs: arrayOf(stringType()),
        })),
      }))
    })
  })
}, dataModel, MediaHotspots);
