export const IncludeStoreList = [
  '483', '441', '424', '413', '453', '455', '459', '475', '405', '447', '485',
  '6948', '468', '480', '401', '477', '432', '248', '8447', '278', '6371', '6375',
  '8926', '6851', '261', '265', '6367', '263', '6328', '232', '6349', '264', '262',
  '6350', '266', '6323', '6869', '287', '6331', '6355', '206', '6343', '209', '221',
  '6378', '6856', '277', '285', '251', '6322', '1982', '1989', '1919', '1983', '1901',
  '6919', '1906', '1903', '1935', '1908', '1974', '6822', '1911', '1932', '1955', '1980',
  '1961', '1986', '1912', '1976', '1950', '1936', '1914', '1909', '2036', '1937', '2001',
  '2030', '970', '941', '953', '939', '976', '921', '962', '909', '927', '6917', '925',
  '947', '982', '903', '918', '906', '977', '901', '944', '919', '965', '6903', '6911',
  '954', '957', '916', '961', '915', '981', '933', '934', '6905', '922', '980', '928',
  '926', '902', '908', '959', '912', '950', '935', '949', '904', '932', '910', '6845',
  '907', '983', '8475', '3322', '3315', '3314', '3305', '3308', '3301', '3318', '3307',
  '3324', '3306', '3316', '3303', '3302', '1281', '1249', '6150', '1225', '4103', '4134',
  '4102', '4163', '6556', '6847', '6818', '6547', '586', '588', '581', '6529', '582', '6544',
  '503', '6550', '6989', '580', '6988', '6551', '8520', '563', '6557', '530', '6555', '564', '552',
  '6816', '6505', '589', '8976', '6504', '598', '6804', '550', '556', '6537', '555', '553', '8951',
  '531', '6817', '6579', '6107', '6561', '6530', '534', '6586', '585', '6860', '6506', '574', '6838',
  '6546', '584', '571', '6525', '6558', '6985', '6859', '6828', '578', '6517', '566', '2389', '577',
  '6510', '1326', '6567', '6806', '6509', '6543', '6539', '6501', '1832', '569', '565', '1859', '1853',
  '6560'
];
