import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Link,
  DrawerBody, DrawerFooter
} from '@one-thd/sui-atomic-components';
import { AddToContext } from '../../AddToContext';

export const CopyProjectMessagingScreen = ({ createdProject, copyProjectItems }) => {
  const {
    handleClose,
    showSuccessMessage,
    setShowSuccessMessage
  } = useContext(AddToContext);
  const isMultipleItems = copyProjectItems?.length > 1;

  return (
    <>
      <DrawerBody overflow="scroll">
        {showSuccessMessage && (
          <Alert
            status="success"
            onClose={() => setShowSuccessMessage(false)}
            data-testid="add-to-success-alert"
          >
            {isMultipleItems ? 'Items' : 'Item'} successfully added to <Link href={`/projects/${createdProject?.projectId}`}>{createdProject?.projectName}</Link>
          </Alert>
        )}
        {!showSuccessMessage && (
          <Alert
            status="error"
            onClose={handleClose}
            data-testid="add-to-error-alert"
          >
            Unable to add item. Please try again.
          </Alert>
        )}
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="add-to-cancel-button"
          variant="primary"
          fullWidth
          onClick={handleClose}
          type="submit"
        > OK
        </Button>
      </DrawerFooter>
    </>
  );
};

CopyProjectMessagingScreen.propTypes = {
  createdProject: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string
  }),
  copyProjectItems: PropTypes.arrayOf(PropTypes.string),
};

CopyProjectMessagingScreen.defaultProps = {
  createdProject: {},
  copyProjectItems: []
};