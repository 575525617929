import { useContext } from 'react';
import { QueryContext, useDataModel } from '@thd-nucleus/data-sources';

import { getBrandName, getBundleImage } from '../utils/product-utils';

export const useBundleProducts = ({ bundle }) => {

  const { defaultVariables } = useContext(QueryContext);

  const bundleProductIds = bundle?.bundleSpecificationDetails?.components;

  const { data, loading, error } = useDataModel('products', {
    variables: {
      itemIds: bundleProductIds?.map(({ defaultProductId }) => defaultProductId),
      ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
        isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
      })
    },
    skip: !bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
  });

  const brand = getBrandName(bundle, data?.products);
  const image = getBundleImage(bundle);

  return {
    productsData: data?.products,
    error,
    loading,
    brand,
    image
  };
};
