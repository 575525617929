export const DEFAULT_FILTERS = [
  {
    checked: false,
    id: 'rating_5',
    value: '5',
    count: 0
  },
  {
    checked: false,
    id: 'rating_4',
    value: '4',
    count: 0
  },
  {
    checked: false,
    id: 'rating_3',
    value: '3',
    count: 0
  },
  {
    checked: false,
    id: 'rating_2',
    value: '2',
    count: 0
  },
  {
    checked: false,
    id: 'rating_1',
    value: '1',
    count: 0
  },
  {
    checked: false,
    id: 'verified',
    value: 'verified',
    count: 0
  },
  {
    checked: false,
    id: 'current_item_selections',
    value: 'current_item_selections',
    count: 0
  }
];

export const DEFAULT_ADDITINAL_FILTER = [
  {
    checked: false,
    id: 'profile_diy',
    value: 'DIYer',
    count: 0
  },
  {
    checked: false,
    id: 'profile_pro',
    value: 'Professional',
    count: 0
  },
  {
    checked: false,
    id: 'gender_male',
    value: 'Male',
    count: 0
  },
  {
    checked: false,
    id: 'gender_female',
    value: 'Female',
    count: 0
  },
  {
    checked: false,
    id: 'age_18to24',
    value: '18to24',
    label: '18 to 24',
    count: 0
  },
  {
    checked: false,
    id: 'age_25to34',
    value: '25to34',
    label: '25 to 34',
    count: 0
  },
  {
    checked: false,
    id: 'age_35to44',
    value: '35to44',
    label: '35 to 44',
    count: 0
  },
  {
    checked: false,
    id: 'age_45to54',
    value: '45to54',
    label: '45 to 54',
    count: 0
  },
  {
    checked: false,
    id: 'age_5564',
    value: '55to64',
    label: '55 to 64',
    count: 0
  },
  {
    checked: false,
    id: 'age_65orover',
    value: '65orOver',
    label: '65 or Over',
    count: 0
  }
];
export const DEFAULT_PAGE_CONTEXT = {
  totalPages: 3,
  currentPage: 1,
  pageNeighbours: 1,
  pageReviewLimit: 3
};
export const DEFAULT_SORTBY = 'photoreview';
export const initialState = {
  filters: [...DEFAULT_FILTERS],
  firstPageReviews: {},
  pageContext: DEFAULT_PAGE_CONTEXT,
  searchText: null,
  selectedSentiment: null,
  sortBy: DEFAULT_SORTBY
};

export const storeInitialState = {
  filters: [...DEFAULT_FILTERS, ...DEFAULT_ADDITINAL_FILTER],
  firstPageReviews: {},
  pageContext: DEFAULT_PAGE_CONTEXT,
  searchText: null,
  selectedSentiment: null,
  sortBy: DEFAULT_SORTBY
};

export const pipReviewsPerPage = 10;
export const seoReviewsPerPage = 30;

export const badgeLinks = {
  INCENTIVIZEDREVIEW: 'https://www.homedepot.com/c/home_depot_seeds_program',
  EARLYREVIEWERINCENTIVE: 'https://www.homedepot.com/c/the_home_depot_reviewer_program'
};