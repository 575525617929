import React, { useEffect, useState } from 'react';
import {
  string, func, number, bool, shape
} from 'prop-types';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType,
  params,
  useDataModel
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { useConfigService } from '@thd-nucleus/experience-context';
import { SwatchSui } from './swatch-sui';
import { publish } from '../../publisher';
import { MAX_NUMBER_OF_MINI_SWATCHES } from '../../statics';

export const SuperSkuMiniSwatches = ({
  itemId,
  onChange,
  onHover,
  numberOfMiniSwatches,
  center,
  componentImpressionData,
  isImpressionsRequired,
  showLabelWithSwatches,
  removeStaticHeight,
  hideComponentWhenEmpty
}) => {
  const { greater } = useBreakpoint('sm');
  const enableHover = Boolean(greater);
  const swatchNavigatestoPip = useConfigService('fs-prop:isSwatchNavigatestoPIPEnabled') || false;

  const [state, setState] = useState({
    swatches: [],
    parentId: null,
    shouldSkip: false,
    isSwatchClicked: false,
    selectedItemLabel: null
  });

  const { data } = useDataModel('product', {
    variables: { itemId },
    fetchPolicy: 'cache-first'
  });

  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'SuperSkuMiniSwatches',
      name: 'SuperSkuMiniSwatches',
      position: 0
    }
  });

  // impressions data
  const { parent } = additionalData || {};

  // product data
  const { parentId, canonicalUrl } = data?.product?.identifiers || {};
  const {
    swatches: swatchesData = [],
    totalNumberOfOptions: totalNumberOfOptionsAvailable = 0
  } = data?.product?.info || {};

  // swatch state management hooks
  useEffect(() => {
    const { swatches = [], totalNumberOfOptions = 0 } = data?.product?.info || {};

    if (swatches?.length && !state.shouldSkip) {
      const firstSwatchLabel = swatches[0]?.label || null;
      setState((prevState) => ({
        ...prevState,
        swatches,
        shouldSkip: true,
        totalNumberOfOptions,
        parentId,
        selectedItemLabel: firstSwatchLabel
      }));
    } else if (totalNumberOfOptions > 0) {
      setState((prevState) => ({
        ...prevState,
        totalNumberOfOptions
      }));
    }
  }, [data?.product?.info]);

  useEffect(() => {
    if (state?.isSwatchClicked) {
      publish('click-swatch', {});

      const selectedItem = state.swatches.filter((swatch) => { return swatch.isSelected; });
      const selectedItemId = selectedItem?.length ? selectedItem[0].itemId : '';

      onChange({ itemId: selectedItemId });

      setState((prevState) => ({
        ...prevState,
        isSwatchClicked: false
      }));
    }
  }, [state.isSwatchClicked]);

  const handleAttributeValueClick = (event, clickedItemId) => {
    const newSwatches = state.swatches.map((swatch) => {
      return {
        ...swatch,
        isSelected: swatch?.itemId === clickedItemId
      };
    });
    const selectedItem = newSwatches.filter((swatch) => { return swatch.isSelected; })[0];

    if (swatchNavigatestoPip && selectedItem?.url) {
      window.location.assign(selectedItem?.url);
    } else {
      setState((prevState) => ({
        ...prevState,
        swatches: newSwatches,
        isSwatchClicked: true,
        selectedItemLabel: selectedItem?.label || ''
      }));
    }
  };

  const handleAttributeValueHover = (event, hoveredItemId) => {
    const hoveredSwatch = state.swatches.find((swatch) => swatch.itemId === hoveredItemId);
    onHover({
      itemId: hoveredItemId,
      isHovered: true,
      selectedItemLabel: hoveredSwatch?.label || ''
    });
    setState((prevState) => ({
      ...prevState,
      itemId: hoveredItemId,
      isHovered: true,
      selectedItemLabel: hoveredSwatch?.label || ''
    }));
  };

  const handleResetHover = () => {
    const selectedSwatch = state.swatches.find((swatch) => swatch.isSelected);
    onHover({
      itemId: selectedSwatch?.itemId || itemId,
      isHovered: false,
      selectedItemLabel: selectedSwatch?.label || ''
    });
    setState((prevState) => ({
      ...prevState,
      itemId: selectedSwatch?.itemId || itemId,
      isHovered: false,
      selectedItemLabel: selectedSwatch?.label || ''
    }));
  };

  const handleOptionsLinkClick = (event, action = null) => {
    const productMiniSwatchEventData = {
      podAction: action || 'more options link',
      podAnchorSku: itemId,
      target: '',
      parent
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productMiniSwatchEventData);
  };

  // Always 5 maximum unless prop is passed. Using container queries to control hiding as needed.
  const numberOfMiniSwatchesToDisplay = numberOfMiniSwatches || MAX_NUMBER_OF_MINI_SWATCHES;

  const { swatches = [], totalNumberOfOptions = 0 } = state;
  const numberOfOptions = totalNumberOfOptionsAvailable || totalNumberOfOptions;
  const availableSwatches = swatches.length ? swatches : swatchesData;

  const toDisplay = (availableSwatches || []).slice(0, numberOfMiniSwatchesToDisplay);
  const displaySwatches = toDisplay?.length > 1;

  const showMoreOptionsLink = ((numberOfOptions >= numberOfMiniSwatchesToDisplay
    || (!availableSwatches && availableSwatches?.length < numberOfOptions))
    && numberOfOptions > 1);

  const hoveredSwatchLabelClasses = [
    `${removeStaticHeight ? '' : 'sui-h-6'}`,
    'sui-pt-1 sui-font-regular sui-text-xs sui-text-subtle',
  ].join(' ');

  const sskuSwatchContainerClasses = [
    'sui-inline-flex sui-flex-wrap sui-items-center sui-pt-1 sui-pb-1',
    `${center ? 'sui-justify-center' : 'sui-justify-start'}`,
    `${removeStaticHeight ? '' : 'sui-min-h-11'}`,
    'sui-@container/ssms sui-w-full',
  ].join(' ');

  const optionsLinkClasses = [
    `${removeStaticHeight ? '' : 'sui-h-11'}`,
    'sui-content-center sui-font-regular sui-text-sm sui-text-primary sui-underline',
  ].join(' ');

  if (hideComponentWhenEmpty && !displaySwatches && !showMoreOptionsLink) {
    return <></>;
  }

  return (
    <div data-testid="ssku-min-swatch" data-component="SuperSkuMiniSwatches">
      {displaySwatches && showLabelWithSwatches && (
        <div className={hoveredSwatchLabelClasses}>
          {state?.selectedItemLabel && (
            <div>{state.selectedItemLabel}</div>
          )}
        </div>
      )}
      <div className={sskuSwatchContainerClasses}>
        {displaySwatches && toDisplay.map((swatch, index) => {
          const {
            isSelected,
            itemId: swatchItemId,
            label,
            swatchImgUrl
          } = swatch || {};

          const swatchWrapperClasses = (swatchIndex) => {
            const defaultClasses = 'sui-mr-2 sui-h-11 sui-w-11';
            if (swatchIndex === 2) return `${defaultClasses} sui-hidden @[202px]/ssms:sui-block`;
            if (swatchIndex === 3) return `${defaultClasses} sui-hidden @[256px]/ssms:sui-block`;
            if (swatchIndex === 4) return `${defaultClasses} sui-hidden @[314px]/ssms:sui-block`;
            return defaultClasses;
          };

          if (!swatchImgUrl) return null;

          return (
            <SwatchSui
              wrapperClasses={swatchWrapperClasses(index)}
              key={`ssku-${swatchImgUrl}`}
              enableHover={enableHover}
              isSelected={isSelected}
              onClick={(event) => handleAttributeValueClick(event, swatchItemId)}
              onMouseEnter={((event) => handleAttributeValueHover(event, swatchItemId))}
              onMouseLeave={handleResetHover}
              swatchGuid={swatchImgUrl}
              value={label}
              index={index}
              swatchItemId={swatchItemId}
              componentImpressionData={componentImpressionData}
              isImpressionsRequired={isImpressionsRequired}
            />
          );
        })}
        {showMoreOptionsLink && (
          <a
            className="sui-flex sui-flex-col"
            aria-label="product show more options link"
            href={canonicalUrl}
            onClick={handleOptionsLinkClick}
          >
            <div className={optionsLinkClasses}>
              {(toDisplay?.length > 1) ? '+more' : '+more available options'}
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

SuperSkuMiniSwatches.propTypes = {
  itemId: string.isRequired,
  onChange: func.isRequired,
  onHover: func,
  numberOfMiniSwatches: number,
  center: bool,
  componentImpressionData: shape({
    name: string,
    component: string,
    position: number,
    type: string,
  }),
  isImpressionsRequired: bool,
  showLabelWithSwatches: bool,
  removeStaticHeight: bool,
  hideComponentWhenEmpty: bool,
};

SuperSkuMiniSwatches.defaultProps = {
  onHover: () => { },
  numberOfMiniSwatches: null,
  center: false,
  componentImpressionData: {
    name: '',
    component: '',
    position: 1,
    type: '',
  },
  isImpressionsRequired: false,
  /* Reserves space above mini swatches for swatch label text. */
  showLabelWithSwatches: false,
  /* Remove static height classes, allow dynamic component height */
  removeStaticHeight: false,
  /* Return react fragment element when no swatches or links to show */
  hideComponentWhenEmpty: false,
};

SuperSkuMiniSwatches.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      canonicalUrl: stringType(),
      parentId: stringType()
    }),
    info: shapeType({
      swatches: arrayOfType(shapeType({
        isSelected: boolType(),
        itemId: stringType(),
        label: stringType(),
        swatchImgUrl: stringType(),
        url: stringType(),
        value: stringType(),
      })),
      totalNumberOfOptions: numberType()
    })
  })
};
