import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormController,
  FormLabel,
  Dropdown,
  MenuItem,
  TextField
} from '@one-thd/sui-atomic-components';
import {
  DatePicker
} from '@one-thd/sui-date-pickers';
import {
  validateProjectName,
  validatePropertyType,
  validateProjectDescription,
  validateEstimatedStartDate,
  validateEstimatedEndDate
} from '../helpers';
import {
  EMPTY_PROJECT_NAME_MESSAGE
} from '../constants';

export const ProjectInformationForm = ({
  showErrors,
  projectInformation,
  setProjectInformation,
  isProjectInfoError,
  setIsProjectInfoError,
  open,
  duplicateNameError,
  hasTradeCreditAttached
}) => {
  const projectNameRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const estimatedEndDateRef = useRef(null);
  const notesRef = useRef(null);

  const [errors, setErrors] = useState({
    projectNameError: '',
    propertyTypeError: '',
    estimatedEndDateError: '',
    notesError: '',
  });

  const findFirstTruthyKey = (obj) => {
    for (const key in obj) {
      if (obj[key]) {
        return key;
      }
    }
    return null;
  };

  useEffect(() => {
    const firstErrorKey = findFirstTruthyKey(errors);
    
    if (showErrors && firstErrorKey) {
      if (firstErrorKey === 'projectNameError') {
        projectNameRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      } else if (firstErrorKey === 'propertyTypeError') {
        propertyTypeRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      } else if (firstErrorKey === 'estimatedEndDateError') {
        estimatedEndDateRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      } else if (firstErrorKey === 'notesError') {
        notesRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [showErrors, errors])
  const {
    projectName,
    propertyType,
    estimatedStartDate,
    estimatedEndDate,
    notes
  } = projectInformation;
  const [propertyTypeDropdownValue, setPropertyTypeDropdownValue] = useState('');

  useEffect(() => {
    if (propertyType) {
      // eslint-disable-next-line max-len
      if (propertyType !== 'Single Family' && propertyType !== 'Multi-Family' && propertyType !== 'Renovation/Remodel') {
        setPropertyTypeDropdownValue('Other');
      } else {
        setPropertyTypeDropdownValue(propertyType);
      }
    }
  }, []);

  const {
    projectNameError,
    propertyTypeError,
    estimatedEndDateError,
    notesError
  } = errors;

  const handleNameValidation = (value) => {
    const { isError, errorMessage } = validateProjectName(value);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        projectNameError: errorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        projectNameError: ''
      }));
    }
  };

  const handleTypeValidation = (value) => {
    if (value === 'Other') {
      setErrors((prevState) => ({
        ...prevState,
        propertyTypeError: 'Please provide a Project Type'
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        propertyTypeError: ''
      }));
    }
  };

  const handleOtherTypeValidation = (value) => {
    const { isError, errorMessage } = validatePropertyType(value);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        propertyTypeError: errorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        propertyTypeError: ''
      }));
    }
  };

  const handleEstimatedStartDateValidation = (value) => {
    const { isError, endDateErrorMessage } = validateEstimatedStartDate(value, estimatedEndDate);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        estimatedEndDateError: endDateErrorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        estimatedEndDateError: ''
      }));
    }
  };

  const handleEstimatedEndDateValidation = (value) => {
    const { isError, endDateErrorMessage } = validateEstimatedEndDate(value, estimatedStartDate);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        estimatedEndDateError: endDateErrorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        estimatedEndDateError: ''
      }));
    }
  };

  const handleNotesValidation = (value) => {
    const { isError, errorMessage } = validateProjectDescription(value, 200);
    if (isError) {
      setErrors((prevState) => ({
        ...prevState,
        notesError: errorMessage
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        notesError: ''
      }));
    }
  };

  const handleOnDateChange = (key, event, value) => {
    let formattedValue = value;
    if (value) {
      formattedValue = new Date(value).toLocaleDateString(
        'en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }
      );
    }
    if (key === 'estimatedStartDate') handleEstimatedStartDateValidation(formattedValue);
    if (key === 'estimatedEndDate') handleEstimatedEndDateValidation(formattedValue);
    setProjectInformation((prevState) => ({
      ...prevState,
      [key]: formattedValue
    }));
  };

  const handleOnChange = (key, event) => {
    const { value } = event.target;
    setProjectInformation((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleOnPropertyChange = (key, event) => {
    const { value } = event.target;
    setPropertyTypeDropdownValue(value);
    if (value === 'Other') {
      setProjectInformation((prevState) => ({
        ...prevState,
        propertyType: ''
      }));
    } else {
      setProjectInformation((prevState) => ({
        ...prevState,
        [key]: value
      }));
    }
  };

  useEffect(() => {
    const errorMessageValues = Object.values(errors);
    const hasErrors = errorMessageValues.some(Boolean);
    if (hasErrors) {
      setIsProjectInfoError(true);
    } else {
      setIsProjectInfoError(false);
    }
  }, [errors]);

  useEffect(() => {
    if (showErrors && !projectName) {
      setErrors((prevSate) => ({
        ...prevSate,
        projectNameError: EMPTY_PROJECT_NAME_MESSAGE
      }));
    }
  }, [showErrors]);

  useEffect(() => {
    if (!open && isProjectInfoError) {
      setErrors((prevState) => ({
        ...prevState,
        projectNameError: '',
        propertyTypeError: '',
        estimatedEndDateError: '',
        notesError: '',
      }));
    }
  }, [open]);

  return (
    <>
      <TextField
        required
        ref={projectNameRef}
        id="project-name"
        label="Project Name"
        type="text"
        onBlur={() => { handleNameValidation(projectName); }}
        value={projectName}
        fullWidth
        onChange={(event) => { handleOnChange('projectName', event); }}
        disabled={hasTradeCreditAttached}
        status={
          projectNameError || duplicateNameError ? 'error' : null
        }
        statusMessage={
          projectNameError || duplicateNameError
        }
        InputProps={{
          inputProps: {
            'data-testid': 'project-name-input'
          }
        }}
      />
      <FormController fullWidth>
        <FormLabel
          optional
          htmlFor="project-type-form-dropdown"
          id="project-type-form-label"
        >
          Project Type
        </FormLabel>
        <Dropdown
          maxItems={4}
          id="project-type-form-dropdown"
          data-testid="project-type-dropdown"
          value={propertyTypeDropdownValue}
          onBlur={() => { handleTypeValidation(propertyType); }}
          onChange={(event) => { handleOnPropertyChange('propertyType', event); }}
          placeholder="Select a Project Type"
        >
          <MenuItem
            value="Single Family"
            data-testid="single-family"
          >Single Family
          </MenuItem>
          <MenuItem
            value="Multi-Family"
            data-testid="multi-family"
          >Multi-Family
          </MenuItem>
          <MenuItem
            value="Renovation/Remodel"
            data-testid="renovation-remodel"
          >Renovation/Remodel
          </MenuItem>
          <MenuItem
            value="Other"
            data-testid="type-other"
          >Other
          </MenuItem>
        </Dropdown>
        {propertyTypeDropdownValue === 'Other' && (
          <TextField
            fullWidth
            ref={propertyTypeRef}
            id="project-type"
            placeholder="Project Type"
            value={propertyType}
            onBlur={() => { handleOtherTypeValidation(propertyType); }}
            onChange={(event) => { handleOnChange('propertyType', event); }}
            status={
              showErrors && propertyTypeError ? 'error' : null
            }
            statusMessage={
              showErrors ? propertyTypeError : ''
            }
            InputProps={{
              inputProps: {
                'data-testid': 'project-type-input'
              }
            }}
          />
        )}
      </FormController>
      <div>
        <div className="sui-mb-6">
          <DatePicker
            dateFieldProps={
              {
                label: 'Estimated Start Date',
                optional: true
              }
            }
            collapsible
            value={estimatedStartDate}
            onChange={(event, value) => { handleOnDateChange('estimatedStartDate', event, value); }}
            id="project-start-date"
            data-testid="project-start-date-input"
          />
        </div>
        <div className="sui-mb-6">
          <DatePicker
            ref={estimatedEndDateRef}
            dateFieldProps={
              {
                label: 'Estimated End Date',
                optional: true
              }
            }
            collapsible
            value={estimatedEndDate}
            onChange={(event, value) => { handleOnDateChange('estimatedEndDate', event, value); }}
            id="project-end-date"
            data-testid="project-end-date-input"
            status={
              showErrors && estimatedEndDateError ? 'error' : null
            }
            statusMessage={
              showErrors ? estimatedEndDateError : ''
            }
          />
        </div>
        <TextField
          fullWidth
          ref={notesRef}
          optional
          id="project-description"
          label="Project Description"
          placeholder=""
          multiline
          minRows={2}
          maxRows={2}
          value={notes}
          onBlur={() => { handleNotesValidation(notes); }}
          onChange={(event) => { handleOnChange('notes', event); }}
          status={
            showErrors && notesError ? 'error' : null
          }
          statusMessage={
            showErrors ? notesError : ''
          }
          InputProps={{
            inputProps: {
              'data-testid': 'project-description-input'
            }
          }}
        />
      </div>
    </>
  );
};

ProjectInformationForm.propTypes = {
  showErrors: PropTypes.bool.isRequired,
  projectInformation: PropTypes.shape({
    projectName: PropTypes.string,
    propertyType: PropTypes.string,
    otherPropertyType: PropTypes.string,
    estimatedStartDate: PropTypes.string,
    estimatedEndDate: PropTypes.string,
    notes: PropTypes.string
  }).isRequired,
  setProjectInformation: PropTypes.func.isRequired,
  isProjectInfoError: PropTypes.bool.isRequired,
  setIsProjectInfoError: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  duplicateNameError: PropTypes.string.isRequired,
  hasTradeCreditAttached: PropTypes.bool
};

ProjectInformationForm.defaultProps = {
  hasTradeCreditAttached: false
};