import { useContext, useEffect } from 'react';
import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';

import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useHelmet, LinkTag } from '@thd-nucleus/thd-helmet';

import Helper from '../../component/Helper';

export const useDecisionEngine = ({ media, isMobile, pageType }) => {
  const enableDeFeature = useConfigService('fs:enableDeFeature');
  const {
    experiment: {
      decision_engine: deCampaignsFromEW
    } = {}
  } = useContext(ExperienceContext);

  const currentDECampaign = Object.keys(deCampaignsFromEW || {})?.[0];
  const decision = deCampaignsFromEW?.[currentDECampaign];
  const isDecisionEngineEligible = !!(decision && enableDeFeature);
  const imageType = Helper.armToImageMapping({ decision });

  const productMetaDataHandler = () => {
    if (!isDecisionEngineEligible) {
      return [];
    }

    const { image: primaryImage, images = [] } = media || {};
    const filteredImage = images?.find((image) => image?.type === imageType);

    if (!filteredImage) {
      return [];
    }

    const shouldMinimizeImage = pageType === 'pep' && isMobile;
    const getImageUrl = (image) => image?.url?.replace(/<SIZE>/ig, shouldMinimizeImage ? '300' : '600');
    const imgUrl = getImageUrl(filteredImage);
    const primaryImageUrl = getImageUrl(primaryImage);

    // Skip primary image, as this is already loaded in the head within ProductMetadata.js
    if (imgUrl === primaryImageUrl) {
      return [];
    }

    return [
      new LinkTag({
        rel: 'preload',
        href: imgUrl,
        id: `preLoadImg ${filteredImage.type}`,
        as: 'image',
        media: `(min-width: ${shouldMinimizeImage ? '300px' : '600px'})`,
        fetchpriority: 'high'
      })
    ];
  };

  useEffect(() => {
    if (isDecisionEngineEligible) {
      // set analytics for decision engine
      try {
        const cookie = THDStorageUtils.get(COOKIE_STORAGE, { key: 'Experiment' });
        if (!cookie || !window.LIFE_CYCLE_EVENT_BUS) {
          return;
        }

        const deExperiment = JSON.parse(cookie)?.decision_engine?.['first-de-experiment'] || {};
        const { name: campaignName = '', test_group: testGroup = '', arm: decisionArm = '' } = deExperiment;
        const timestamp = Date.now() - (Date.now() % 1000);
        // eslint-disable-next-line camelcase
        const post_de = [campaignName, testGroup, decisionArm, timestamp].join('|');

        window.LIFE_CYCLE_EVENT_BUS.trigger('decision-engine.api-call', {
          post_de,
          loading: false
        });
      } catch (error) {
        console.error('media-gallery: Error occurred while accessing cookie - Experiment', error);
      }
      // This code only works for a single campaign. In order to support
      // multiple campaigns, there needs to be changes on the thd-analytics
      // side and discussion of how to identify the current campaign.
    }
  }, []); // setting this up to only run once, and only if DE is enabled

  useHelmet('decisionEngine.mediaGallery', {}, productMetaDataHandler, [decision]);

  return isDecisionEngineEligible ? imageType : null;
};