/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';

export const AddToListProductInfo = ({
  identifiers,
  primaryImage
}) => {
  if ((!identifiers && !primaryImage)) {
    return null;
  }

  return (
    <div className="sui-flex sui-flex-row">
      <img
        src={primaryImage?.url?.replace('<SIZE>', '145')}
        alt={identifiers?.productLabel}
        className="sui-aspect-square sui-max-w-[20%] sm:sui-max-w-[30%]"
        width="145px"
        height="145px"
      />
      <div className="sui-pl-6 sui-text-xl">
        <strong>{identifiers?.brandName}&nbsp;</strong>{identifiers?.productLabel}
      </div>
    </div>
  );
};

AddToListProductInfo.displayName = 'AddToListProductInfo';

AddToListProductInfo.propTypes = {
  identifiers: PropTypes.shape({
    brandName: PropTypes.string,
    productLabel: PropTypes.string
  }),
  primaryImage: PropTypes.shape({
    url: PropTypes.string
  })
};

AddToListProductInfo.defaultProps = {
  identifiers: {},
  primaryImage: {}
};