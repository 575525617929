import React from 'react';
import PropTypes, { shape } from 'prop-types';

import { extend } from '@thd-nucleus/data-sources';
import { Button, Link } from '@one-thd/sui-atomic-components';

import { BundleImage } from './BundleImage';
import { BundleMiniPrice } from './BundleMiniPrice';
import { BUNDLE_MINI_TO_PIP_ANALYTICS } from '../constants';
import { BundleMiniRating } from './BundleMiniRating';
import { getButtonText, tiggerAnalyticsCustomEvent } from '../utils/product-utils';

const BundleMiniContent = (props) => {
  const {
    product, products, bundleId, brand, image, label, url, removeBackgroundImage
  } = props;

  const backgroundImage = removeBackgroundImage ? '' : 'sui-bg-inactive';

  const triggerAnalytics = () => {
    const output = {
      itemId: bundleId,
      product
    };
    tiggerAnalyticsCustomEvent(BUNDLE_MINI_TO_PIP_ANALYTICS, output);
  };

  return (
    <div data-testid="bundle-mini-content" className="sui-w-full">
      <div
        // eslint-disable-next-line max-len
        className="sui-bg-primary sui-mb-2 lg:sui-mt-2 lg:sui-mb-4 sui-w-full sui-border-solid sui-border-1 sui-border-primary sui-p-4 md:sui-p-6 lg:sui-p-8 sui-grid sui-grid-cols-12 sui-gap-4 lg:sui-gap-8"
      >
        <a
          href={url}
          aria-label="bundle-mini-image"
          className={`sui-flex sui-justify-center sui-py-4 sui-col-span-12 lg:sui-col-span-7 
            ${backgroundImage}`}
        >
          <div className="sui-w-3/4 sui-flex sui-items-center">
            <BundleImage
              aspectRatio="3:2"
              src={image}
              alt={label}
            />
          </div>
        </a>
        <div
          // eslint-disable-next-line max-len
          className="lg:sui-h-full lg:sui-flex lg:sui-flex-col lg:sui-justify-center sui-col-span-12 lg:sui-col-span-5"
        >
          {
            brand && (
              <div className="sui-mb-2" data-testid="bundle-mini-brand">
                <Link variant="body-base" href={url} underline="none">{brand}</Link>
              </div>
            )
          }
          <Link variant="h4" href={url} underline="none" onClick={triggerAnalytics}>
            {label}
          </Link>
          <div className="[&_button]:sui-pl-0 sui-mb-2">
            { product && <BundleMiniRating bundle={product} /> }
          </div>
          <div className="sui-mb-3 sui-min-h-20">
            {
              products && products?.length > 0
              && <BundleMiniPrice product={product} products={products} bundleId={bundleId} />
            }
          </div>
          <div className="sui-w-full sm:sui-w-fit">
            <Button
              fullWidth
              variant="secondary"
              href={url}
              onClick={triggerAnalytics}
            >
              {getButtonText(product?.bundleSpecificationDetails?.experienceType)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

BundleMiniContent.displayName = 'BundleMiniContent';

BundleMiniContent.propTypes = {
  bundleId: PropTypes.string.isRequired,
  product: PropTypes.shape({
    itemId: PropTypes.string,
    bundleSpecificationDetails: shape({
      experienceType: PropTypes.string,
      type: PropTypes.string
    })
  }),
  products: PropTypes.arrayOf(shape({})),
  brand: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  removeBackgroundImage: PropTypes.bool
};

BundleMiniContent.defaultProps = {
  removeBackgroundImage: false,
  product: {},
  products: [],
  brand: '',
  image: '',
  label: '',
  url: ''
};

BundleMiniContent.dataModel = extend(
  BundleMiniRating,
  BundleMiniPrice
);

export { BundleMiniContent };