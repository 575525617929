import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import createTypography from '@one-thd/sui-atomic-components/dist/utils/createTypography';
import { ButtonBase } from '@one-thd/sui-atomic-components/dist/button/ButtonBase';

const ViewChangerButton = (props) => {
  const {
    children,
    onClick
  } = props;

  const typography = createTypography({
    variant: 'body-base',
    weight: 'bold'
  });
  const navigationButtonClasses = classNames('sui-flex sui-items-center focus-visible:sui-outline-strongest focus-visible:sui-outline-1 sui-outline-offset-1 hover:sui-underline', typography.classes);

  return (
    <>
      <ButtonBase
        aria-live="polite"
        unstyled
        onClick={onClick}
        className={navigationButtonClasses}
      >
        {children}
      </ButtonBase>
    </>
  );
};

export { ViewChangerButton };