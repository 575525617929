import React, { useEffect } from 'react';
import { bool, shape, func } from 'prop-types';

import { extend } from '@thd-nucleus/data-sources';

import { BundleMiniPrice } from './BundleMiniPrice';
import { BundleMiniRating } from './BundleMiniRating';
import { BundleMiniContent } from './BundleMiniContent';
import { BundleProductsDataModel } from '../models/dataModel';
import { useBundleProducts } from '../hooks/useBundleProducts';
import { BundleMiniPlaceholder } from './BundleMiniPlaceholder';

export const BundleMiniItem = ({ bundleLoading, bundleData, onError }) => {
  const {
    productsData, brand, loading, error, image
  } = useBundleProducts({ bundle: bundleData });

  const renderMiniItem = bundleData && productsData && !error;

  useEffect(() => {
    if (!loading && !bundleLoading && !renderMiniItem) {
      onError();
    }
  }, [renderMiniItem, loading, bundleLoading]);

  if (loading || bundleLoading) {
    return <BundleMiniPlaceholder />;
  }

  if (!renderMiniItem) {
    return null;
  }

  return (
    <BundleMiniContent
      product={bundleData}
      products={productsData}
      brand={brand}
      image={image}
      label={bundleData?.identifiers?.productLabel}
      url={bundleData?.identifiers?.canonicalUrl}
      bundleId={bundleData?.itemId}
      removeBackgroundImage
    />
  );
};

BundleMiniItem.propTypes = {
  bundleLoading: bool,
  bundleData: shape({}),
  onError: func
};

BundleMiniItem.defaultProps = {
  bundleLoading: false,
  bundleData: {},
  onError: () => { }
};

BundleMiniItem.dataModel = extend(
  BundleProductsDataModel,
  BundleMiniContent
);