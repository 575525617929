import React from 'react';
import {
  node
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const SuggestionsMenuTitle = ({
  title
}) => {
  return (
    <>
      {title && (
        <div
          id="suggestions-menu-title"
          data-testid="suggestions-menu-title"
          className="sui-px-4 sui-pt-4"
        >
          <Typography color="primary" height="loose" weight="bold" variant="body-base">
            {title}
          </Typography>
        </div>
      )}
    </>
  );
};

SuggestionsMenuTitle.displayName = 'SuggestionsMenuTitle';
SuggestionsMenuTitle.propTypes = {
  title: node
};
SuggestionsMenuTitle.defaultProps = {
  title: null
};