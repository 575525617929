import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ownerDocument from '@one-thd/sui-atomic-components/dist/private/utils/ownerDocument';
import useForkRef from '@one-thd/sui-atomic-components/dist/private/hooks/useForkRef';
import { CalendarCell } from './CalendarCell';

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December'
];

const MonthPicker = React.forwardRef((props, ref) => {
  const {
    autoFocus = false,
    month,
    onChange
  } = props;

  const currentMonth = dayjs().month();
  const gridRef = useRef(null);
  const handleRef = useForkRef(gridRef, ref);

  const nextItem = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.firstChild;
    }
    if (currentFocus && currentFocus.nextElementSibling) {
      return currentFocus.nextElementSibling;
    }
    return grid.firstChild;
  };

  const previousItem = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.lastChild;
    }
    if (currentFocus && currentFocus.previousElementSibling) {
      return currentFocus.previousElementSibling;
    }
    return grid.lastChild;
  };

  const nextRow = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.firstChild;
    }
    const currentIndex = Array.prototype.indexOf.call(grid.children, currentFocus);
    const nextIndex = grid.children[currentIndex + 3];
    if (nextIndex) {
      return nextIndex;
    }
    return grid.firstChild;
  };

  const previousRow = (grid, currentFocus) => {
    if (grid === currentFocus) {
      return grid.lastChild;
    }
    const currentIndex = Array.prototype.indexOf.call(grid.children, currentFocus);
    const nextIndex = grid.children[currentIndex - 3];
    if (nextIndex) {
      return nextIndex;
    }
    return grid.lastChild;
  };

  const moveFocus = (grid, currentFocus, traversalFunction, fallBackwards = false) => {
    let wrappedOnce = false;
    let nextFocus = traversalFunction(grid, currentFocus);

    while (nextFocus) {
      // Prevent infinite loop.
      if (nextFocus === grid.firstChild) {
        if (wrappedOnce) {
          return;
        }
        wrappedOnce = true;
      }

      // Same logic as useAutocomplete.js
      const nextFocusDisabled = nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

      if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
        // Move to the next element.
        if (fallBackwards) {
          nextFocus = previousItem(grid, nextFocus);
        } else {
          nextFocus = nextItem(grid, nextFocus);
        }
      } else {
        nextFocus.focus();
        return;
      }
    }
  };

  const handleKeyDown = (event) => {
    const grid = gridRef.current;
    const currentFocus = ownerDocument(grid).activeElement;

    const nextItemKey = 'ArrowRight';
    const previousItemKey = 'ArrowLeft';
    const nextRowKey = 'ArrowDown';
    const previousRowKey = 'ArrowUp';

    switch (event.key) {
      case nextItemKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, nextItem);
        break;
      case previousItemKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, previousItem, true);
        break;
      case nextRowKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, nextRow);
        break;
      case previousRowKey:
        event.preventDefault();
        moveFocus(grid, currentFocus, previousRow, true);
        break;
      default:
        break;
    }
  };

  const gridClasses = classNames('sui-grid sui-grid-cols-3 sui-outline-none');

  return (
    <div
      role="grid"
      className={gridClasses}
      ref={handleRef}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      {MONTHS.map((name, index) => (
        <CalendarCell
          size="large"
          key={name}
          selected={index === month}
          current={index === currentMonth}
          focusable={month}
          granularity="month"
          isViewFocused={autoFocus}
          value={index}
          onClick={onChange}
        >
          {name}
        </CalendarCell>
      ))}
    </div>
  );
});

MonthPicker.displayName = 'MonthPicker';

MonthPicker.propTypes = {
  autoFocus: PropTypes.bool,
  month: PropTypes.number,
  onChange: PropTypes.func
};

MonthPicker.defaultProps = {};

export { MonthPicker };
