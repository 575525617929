import { useDataModel } from '@thd-nucleus/data-sources';
import { getProductObject, getAverageRating } from '../utils/rating';

export const useBundleRating = ({ bundle }) => {
  const { data, loading } = useDataModel('reviews', {
    variables: {
      itemId: bundle?.itemId,
      filters: {
        isVerifiedPurchase: false,
        prosCons: null,
        starRatings: null
      },
      searchTerm: null,
      sortBy: 'photoreview',
      startIndex: 1
    }
  });

  const { reviews } = data || {};

  const totalResults = reviews?.TotalResults || 0;
  const itemObj = getProductObject(reviews, totalResults);
  const averageRating = getAverageRating(itemObj, bundle, totalResults);

  const noReviews = !loading && totalResults === 0;

  return {
    loading,
    noReviews,
    averageRating,
    totalResults
  };
};