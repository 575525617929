import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, useDataModel, extend
} from '@thd-nucleus/data-sources';
import { DrawerHeader, DrawerBody, Alert, Link } from '@one-thd/sui-atomic-components';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';
import { CopyProjectMessagingScreen } from './CopyProjectMessagingScreen';
import { CreateAProjectContent } from '@thd-olt-component-react/project-forms';

export const CopyProject = ({
  copyProjectData
  // sourceList: defaultListId from the source project
  // listItemsToInclude: list of item ids to copy
  // targetProjectId: id of the target project
}) => {
  const {
    screen,
    setScreen,
    handleClose,
    showSuccessMessage,
    setShowSuccessMessage,
  } = useContext(AddToContext);
  const {
      PROJECT_CREATE_PROJECT,
      COPY_PROJECT_MESSAGING
    } = SCREENS;
  const [createdProject, setCreatedProject] = useState(null);
  const [copyList, copyListResponse] = useDataModel('copyProjectList', {
    variables: {
      targetListName: "",
      sourceList: copyProjectData?.sourceList,
      listItemsToInclude: copyProjectData?.items,
      targetProjectId: createdProject?.projectId,
    },
    context: { withAuth: true },
    fetchPolicy: 'no-cache',
    skip: !createdProject?.projectId?.length
  });

  const handleProjectCreation = (response) => {
    if (response?.id) {
      setCreatedProject({
        projectId: response?.id,
        projectName: response?.projectName,
      });
      setScreen
    }

  };

  useEffect(() => {
    if (createdProject?.projectId) {
      copyList();
    }
  }, [createdProject?.projectId]);

  useEffect(() => {
    if (copyListResponse?.data) {
      setShowSuccessMessage(true);
      setScreen(COPY_PROJECT_MESSAGING);
    }
    if (copyListResponse?.error) {
      setScreen(COPY_PROJECT_MESSAGING);
    }
  }, [copyListResponse?.data, copyListResponse?.error]);

  useEffect(() => {
    if (!createdProject?.projectId?.length) {
      setScreen(PROJECT_CREATE_PROJECT);
    }
  }, [createdProject]);
  return (
    <>
      <DrawerHeader
        data-testid="add-to-drawer-header"
        title={createdProject?.projectId?.length ? 'Add Item to Project' : 'Create a Project'}
        onClose={handleClose}
      />
      {screen === COPY_PROJECT_MESSAGING && (
        <CopyProjectMessagingScreen
          createdProject={createdProject}
          copyListResponse={copyListResponse}
          copyProjectItems={copyProjectData?.items}
        />
      )}

      {screen === PROJECT_CREATE_PROJECT && (
        <CreateAProjectContent onCreate={handleProjectCreation} handleClose={handleClose} isCopyProject isAddToDrawer />
      )}
    </>
  );
};

CopyProject.displayName = 'CopyProject';

CopyProject.propTypes = {
  copyProjectData: PropTypes.shape({
    targetListName: PropTypes.string,
    sourceList: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    targetProjectId: PropTypes.string,
  }).isRequired,
};

CopyProject.defaultProps = {};

CopyProject.dataModel = extend({
  copyProjectList: params({
    targetListName: string(),
    sourceList: string(),
    listItemsToInclude: arrayOf(string()),
    targetProjectId: string().isRequired(),
  }).mutation().shape({
    projectListId: string(),
  })
});