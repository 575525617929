import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

const getUrlSearchParams = (param, queryString) => {
  const searchParam = new RegExp('[?&]' + param + '=([^&#]*)').exec(queryString);
  return searchParam && decodeURI(searchParam[1]);
};

export const shouldDisplayReferrerRecommendations = (referer) => {
  return /google.com|bing.com|yahoo.com|duckduckgo.com|aol.com/i.test(referer)
    || /^Shopping/.test(getUrlSearchParams('cm_mmc', referer))
    || /PIN_Shopping/.test(getUrlSearchParams('cm_mmc', referer));
};

export const shouldDisplayPipInStock = (data = {}, referer) => {
  const { product = {} } = data || {};
  if (shouldDisplayReferrerRecommendations(referer)) return false;
  if (podFulFillmentUtils.isDiscontinued(product)) return true;
  if (product?.availabilityType?.type === 'Browse Only') return podFulFillmentUtils.isOutOfStockYourStore(product);
  if (product?.availabilityType?.type === 'Online') return podFulFillmentUtils.isOutOfStockOnline(product);
  if (product?.availabilityType?.type === 'Shared') {
    return !!(podFulFillmentUtils.isOutOfStockOnline(product)
      || podFulFillmentUtils.isOutOfStockShared(product)
      || podFulFillmentUtils.isLimitedStock(product)
      || podFulFillmentUtils.isBackordered(product)
      || !podFulFillmentUtils.isATCEnabled(product));
  }
  if (product?.availabilityType?.type === 'Store Only') {
    return podFulFillmentUtils.isBuyInStore(product);
  }
  return false;
};

export const shouldDisplayPipAlternativeRecs = (data = {}, referer) => {
  return !shouldDisplayReferrerRecommendations(referer) && !shouldDisplayPipInStock(data, referer);
};

export const shouldDisplayVisuallySimilar = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.visualNavigation;
};

export const shouldDisplayBatterySystems = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.batItems;
};

export const shouldDisplayPipCollections = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.pipCollections;
};

export const shouldDisplayConfigurator = (data = {}, isServer) => {
  return !isServer && data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS';
};

export const shouldDisplayIrgaccessories = (data = {}) => {
  return (data?.product?.info?.recommendationFlags?.ACC);
};

export const shouldDisplayIrgcollections = (data = {}) => {
  return (data?.product?.info?.recommendationFlags?.collections
  && !shouldDisplayPipCollections(data)) && !shouldDisplayBatterySystems(data);
};

export const getAnchorSku = (data = {}, itemId) => {
  return data?.product?.info?.gccExperienceOmsId || itemId;
};

export const isBathCategory = (data = {}) => {
  if (data?.product?.taxonomy?.breadCrumbs) {
    return (data?.product?.taxonomy?.breadCrumbs[0]?.label.toLowerCase() === 'bath');
  }
  return false;
};

export const validatePipFlip = (data = {}, flag) => {
  return !flag && data?.product?.info?.gccExperienceOmsId;
};

export const flipPip = (
  configuredProductLabel,
  productData,
  hasPipFlip,
  productId,
  setHasPipFlip,
  itemId,
  anchorSku,
  setItemId
) => {
  const product = {
    configuredProductLabel,
  };
  const stockProduct = {
    configuredProductLabel: productData?.product?.identifiers?.productLabel,
  };
  if (hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', { product: stockProduct });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', { product: stockProduct });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_media_gallery_event', { extMedia: {} });
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.stock_product_chosen_event', { configuredItemId: productId });
  } else if (!hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_choice_selected_event', product);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', product);
  }
  setHasPipFlip(!hasPipFlip);
  return itemId === anchorSku ? setItemId(productId) : setItemId(anchorSku);
};

/* BEGIN - This code was pulled from FBT component to add parity for an AB test with PackagesMini
   - This code Will most likely be removed when test concludes
*/
const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};
const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};
const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
        && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

export function isObjectEmpty(object) {
  if (!object) return true;

  const isEmpty = !!object
    && Object.keys(object).length === 0
    && object.constructor === Object;

  return isEmpty;
}

export function isFulfillmentValid(productData) {
  let isValid = true;

  if (
    !productData.product.fulfillment
    || isObjectEmpty(productData.product.fulfillment)
  ) {
    isValid = false;
  } else if (
    !productData.product.fulfillment.fulfillmentOptions
    || isObjectEmpty(productData.product.fulfillment.fulfillmentOptions)
  ) {
    isValid = false;
  }

  return isValid;
}
