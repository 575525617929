import React, { useMemo } from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodRow,
  PodSection,
  PodFooter,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC,
  PodHeader,
  PodTapper,

} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType,
  shape as shapeType
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const RecsProductPod = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    itemId,
    storeId,
    position,
    parent,
    hideSwatches,
    hideFavorites,
    hideBadge,
    hideATC,
    hideRating,
    scheme,
    anchorProduct,
    preferredPriceFlag,
    brandTitleMaxLine,
    strategy,
    zoneName,
    zonePosition,
    podResults,
    noATCFulfillment,
    hidePrice,
    url,
    analyticImpressionData
  } = props;

  const theme = useTheme(RecsProductPod);
  const { hideAddToList } = theme.props;
  const anchorId = (anchorProduct.length > 2) ? anchorProduct : 'n/a';

  const merchRec = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-${anchorId}-_-${position}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [anchorId, position]);

  const handleClick = () => {

    const productPodEventData = {
      podAction: 'product pod',
      podAnchorSku: itemId,
      target: '_self',
      parent
    };
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productPodEventData);
  };

  const PodDetails = ({ podItemId }) => {
    const Details = () => {
      return (
        <>
          <PodTapper fitWidth zIndex>
            <ProductHeader
              brand="above"
              itemId={podItemId}
              brandTitleMaxLine={brandTitleMaxLine}
              disableShopThisCollection
              merchQueryParam={merchRec}
              productNameLineHeight="tight"
            />
          </PodTapper>
          {!hideRating && (
            <PodTapper fitWidth zIndex>
              <ProductRating itemId={podItemId} hideRating={hideRating} merchQueryParam={merchRec} />
            </PodTapper>
          )}
          {!hidePrice && (
            <PodTapper zIndex pointerNone aPointer buttonPointer spanPointer>
              <PodSpacer>
                <Price
                  itemId={podItemId}
                  large={false}
                  storeId={storeId}
                  displayEachUom={false}
                  hideSavingsText={!preferredPriceFlag && !zoneName}
                  showPreferredPricingBadge
                  stackWasPrice
                />
              </PodSpacer>
            </PodTapper>
          )}
        </>
      );
    };

    if (zoneName) {
      return <Details />;
    }

    return (
      <PodSection columnAlign noPadding>
        <Details />
      </PodSection>
    );
  };

  PodDetails.propTypes = {
    podItemId: stringType.isRequired
  };

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2"
      analyticImpressionData={analyticImpressionData}
      hasSSKU={!hideSwatches}
      analyticsData={{
        parent, position, scheme, anchorProduct, strategy, zoneName, zonePosition, podResults
      }}
      render={(pod) => (
        <>
          <a
            className="sui-top-0 sui-left-0 sui-absolute sui-size-full sui-z-1"
            href={url}
            aria-label="Link"
            onClick={handleClick}
          />
          <PodSpacer>
            <PodHeader noPadding>
              {!hideBadge
                && (
                  <PodRow noPadding>
                    <ProductBadge itemId={pod.itemId} storeId={storeId} />
                  </PodRow>
                )}
              {!hideFavorites
                && !hideAddToList && (
                <PodTapper fitWidth zIndex>
                  <PodRow rowReverse noPadding>
                    <AddToList
                      itemId={pod.itemId}
                      storeId={storeId}
                      showIconButton
                    />
                  </PodRow>
                </PodTapper>
              )}
            </PodHeader>
          </PodSpacer>
          <PodTapper zIndex>
            <ProductImage
              itemId={pod.itemId}
              showSecondaryImage={pod.showSecondaryImage}
              merchQueryParam={merchRec}
            />
          </PodTapper>
          {!hideSwatches
            && (
              <SuperSkuMiniSwatches
                itemId={pod.itemId}
                storeId={storeId}
                onChange={pod.onChange}
                onHover={pod.onSSKUHover}
                componentImpressionData={{
                  name: 'ProductPod',
                  component: 'ProductPod',
                  position: position + 1,
                  type: 'product',

                }}
                isImpressionsRequired
              />
            )}
          <PodDetails podItemId={pod.itemId} />
          {!hideATC
            && (
              <PodFooter noPadding>
                <PodSpacer minHeight="40px">
                  <PodTapper zIndex>
                    <ProductATC
                      itemId={pod.itemId}
                      checkGeneric
                      outline
                      noATCFulfillment={noATCFulfillment}
                    />
                  </PodTapper>
                </PodSpacer>
              </PodFooter>
            )}
        </>
      )}
    />
  );
};

RecsProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC,
  Price,
  ProductHeader,
  SuperSkuMiniSwatches,
  AddToList
);

RecsProductPod.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  storeId: stringType,
  position: numType,
  parent: stringType,
  hideSwatches: boolType,
  hideFavorites: boolType,
  hideBadge: boolType,
  hideATC: boolType,
  hideRating: boolType,
  scheme: stringType,
  anchorProduct: stringType,
  preferredPriceFlag: boolType,
  brandTitleMaxLine: numType,
  strategy: stringType,
  zoneName: stringType,
  zonePosition: stringType,
  podResults: numType,
  noATCFulfillment: boolType,
  hidePrice: boolType,
  url: stringType.isRequired,
  analyticImpressionData: shapeType({
    name: stringType,
    component: stringType,
    id: stringType,
    type: stringType
  }),
};

RecsProductPod.defaultProps = {
  storeId: '',
  position: '',
  parent: '',
  hideSwatches: true,
  hideFavorites: true,
  hideBadge: false,
  hideATC: false,
  hideRating: false,
  scheme: 'drecs',
  anchorProduct: '',
  preferredPriceFlag: false,
  brandTitleMaxLine: 4,
  strategy: 'project-based',
  zoneName: '',
  zonePosition: '',
  podResults: 0,
  noATCFulfillment: true,
  hidePrice: false,
  analyticImpressionData: {
    name: '',
    component: '',
    id: '',
    type: ''
  },

};
RecsProductPod.displayName = 'RecsProductPod';

RecsProductPod.themeProps = {
  hideAddToList: boolType
};

RecsProductPod.defaultThemeProps = {
  hideAddToList: false
};
