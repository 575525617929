import React, { useRef, forwardRef, useEffect } from 'react';
import { node, oneOf, bool, func } from 'prop-types';
import { ownerDocument } from './typeahead-utils';
import useForkRef from '../hooks/useForkRef';
import useEventCallback from '../hooks/useEventCallback';

const mapEventPropToEvent = (eventProp) => eventProp.substring(2).toLowerCase();

const clickedRootScrollbar = (event, doc) => (
  doc.documentElement.clientWidth < event.clientX || doc.documentElement.clientHeight < event.clientY
);

const ClickAwayListener = forwardRef(({
  children,
  disableReactTree,
  mouseEvent,
  onClickAway,
  touchEvent
}, ref) => {

  const movedRef = useRef(false);
  const nodeRef = useRef(null);
  const activatedRef = useRef(false);
  const syntheticEventRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      activatedRef.current = true;
    }, 0);
    return () => {
      activatedRef.current = false;
    };
  }, []);

  const handleRef = useForkRef(
    children.ref,
    nodeRef,
  );

  const handleClickAway = useEventCallback((event) => {

    const insideReactTree = syntheticEventRef.current;
    syntheticEventRef.current = false;

    const doc = ownerDocument(nodeRef.current);

    if (
      !activatedRef.current
      || !nodeRef.current
      || ('clientX' in event && clickedRootScrollbar(event, doc))
    ) {
      return;
    }

    if (movedRef.current) {
      movedRef.current = false;
      return;
    }

    let insideDOM;

    if (event.composedPath) {
      insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
    } else {
      insideDOM = !doc.documentElement.contains(event.target) || nodeRef.current.contains(event.target);
    }

    if (!insideDOM && (disableReactTree || !insideReactTree)) {
      onClickAway(event);
    }
  });

  const createHandleSynthetic = (handlerName) => (event) => {
    syntheticEventRef.current = true;

    const childrenPropsHandler = children.props[handlerName];
    if (childrenPropsHandler) {
      childrenPropsHandler(event);
    }
  };

  const childrenProps = { ref: handleRef };

  if (touchEvent !== false) {
    childrenProps[touchEvent] = createHandleSynthetic(touchEvent);
  }

  useEffect(() => {
    if (touchEvent !== false) {
      const mappedTouchEvent = mapEventPropToEvent(touchEvent);
      const doc = ownerDocument(nodeRef.current);

      const handleTouchMove = () => {
        movedRef.current = true;
      };

      doc.addEventListener(mappedTouchEvent, handleClickAway);
      doc.addEventListener('touchmove', handleTouchMove);

      return () => {
        doc.removeEventListener(mappedTouchEvent, handleClickAway);
        doc.removeEventListener('touchmove', handleTouchMove);
      };
    }

    return undefined;
  }, [handleClickAway, touchEvent]);

  if (mouseEvent !== false) {
    childrenProps[mouseEvent] = createHandleSynthetic(mouseEvent);
  }

  useEffect(() => {
    if (mouseEvent !== false) {
      const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
      const doc = ownerDocument(nodeRef.current);

      doc.addEventListener(mappedMouseEvent, handleClickAway);

      return () => {
        doc.removeEventListener(mappedMouseEvent, handleClickAway);
      };
    }

    return undefined;
  }, [handleClickAway, mouseEvent]);

  useEffect(() => {
    const doc = ownerDocument(nodeRef.current);
    doc.addEventListener('focusin', handleClickAway);
    return () => {
      doc.removeEventListener('focusin', handleClickAway);
    };
  }, []);

  return (
    <>{React.cloneElement(children, childrenProps)}</>
  );
});

ClickAwayListener.displayName = 'ClickAwayListener';

ClickAwayListener.propTypes = {
  children: node,
  disableReactTree: bool,
  mouseEvent: oneOf([
    'onClick',
    'onMouseDown',
    'onMouseUp',
    'onPointerDown',
    'onPointerUp',
    false,
  ]),
  onClickAway: func.isRequired,
  touchEvent: oneOf(['onTouchEnd', 'onTouchStart', false]),
};

ClickAwayListener.defaultProps = {
  children: null,
  disableReactTree: false,
  mouseEvent: 'onClick',
  touchEvent: 'onTouchEnd',
};

export { ClickAwayListener };