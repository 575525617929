import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  Collapse,
  Button,
  InputAdornment,
  IconButton
} from '@one-thd/sui-atomic-components';
import { Calendar } from '@one-thd/sui-icons';
import dayjs from 'dayjs';
import useForkRef from '@one-thd/sui-atomic-components/dist/private/hooks/useForkRef';
import useControlled from '@one-thd/sui-atomic-components/dist/private/hooks/useControlled';
import { DateField } from '../datefield/DateField';
import { DateButton } from '../datefield/DateButton';
import { DateCalendar } from '../calendar/DateCalendar';

const formatDate = (dateValue) => {
  if (dateValue) {
    const calendarValue = dayjs(dateValue);
    const month = calendarValue.month() + 1 < 10 ? `0${calendarValue.month() + 1}` : calendarValue?.month() + 1;
    const date = calendarValue.date() < 10 ? `0${calendarValue.date()}` : calendarValue.date();
    return `${month}/${date}/${calendarValue.year()}`;
  }
  return undefined;
};

const DatePicker = React.forwardRef((props, ref) => {
  const {
    children,
    calendarProps,
    collapsible = false,
    dateFieldProps = {},
    defaultValue: defaultValueProp,
    disabled = false,
    label,
    onChange,
    onClick,
    onBlur,
    onFocus,
    onKeyDown,
    open: openProp = false,
    PopoverProps,
    status,
    statusMessage,
    transitionProps,
    value: valueProp,
    variant = 'dialog',
    ...other
  } = props;

  const [open, setOpen] = React.useState(openProp);
  const [dateField, setDateField] = useControlled({
    controlled: valueProp,
    defaultValue: defaultValueProp || ''
  });
  const [calendar, setCalendar] = useControlled({
    controlled: valueProp,
    defaultValue: defaultValueProp || null
  });
  const [autoFocus, setAutoFocus] = React.useState(true);
  const anchorRef = useRef(null);
  const handleRef = useForkRef(anchorRef, ref);

  const isMultipleCalendarView = !!((calendarProps?.calendars && calendarProps?.calendars > 1));

  const handleToggle = (event) => {
    setOpen((prevOpen) => !prevOpen);

    if (onClick) {
      onClick(event);
    }
  };

  const handleClose = (event) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }
    setAutoFocus(true);
    prevOpen.current = open;
  }, [open]);

  const handleCalendar = (event, value) => {
    if (value) {
      const formattedDate = formatDate(value);
      setDateField(formattedDate);
      setCalendar(value);
    } else {
      setDateField('');
      setCalendar(null);
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  const handleDateField = (event) => {
    const value = event.target.value;
    setAutoFocus(false);
    setDateField(value);
    if (value.length > 9) {
      setCalendar(value);
    }
    if (onChange) {
      onChange(event, value);
    }
  };

  const handleBlur = (event) => {
    setAutoFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event) => {
    setAutoFocus(true);

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleKeyDown = (event) => {
    if ((event.keyCode === 32 || event.keyCode === 13) && variant === 'dialog') {
      setOpen((prevState) => !prevState);
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  if (collapsible) {
    return (
      <>
        {
          (variant === 'iconbutton') ? (
            <DateField
              disabled={disabled}
              label={label}
              status={status}
              statusMessage={statusMessage}
              onClick={onClick}
              onBlur={handleBlur}
              ref={handleRef}
              fullWidth
              value={dateField}
              onKeyDown={handleKeyDown}
              onChange={handleDateField}
              InputProps={{
                inputProps: {
                  inputMode: 'numeric'
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Show Calendar" icon={Calendar} onClick={handleToggle} />
                  </InputAdornment>
                ),
                disableStatus: true
              }}
              {...dateFieldProps}
              {...other}
            />
          ) : (
            <DateButton
              disabled={disabled}
              status={status}
              statusMessage={statusMessage}
              onBlur={handleBlur}
              onClick={handleToggle}
              onKeyDown={handleKeyDown}
              ref={handleRef}
              value={dateField}
              {...dateFieldProps}
              {...other}
            />
          )
        }
        <Collapse in={open} {...transitionProps}>
          <div className="sui-p-4 sui-z-1 sui-flex sui-justify-center">
            <DateCalendar
              autoFocus={autoFocus}
              onBlur={handleBlur}
              onChange={handleCalendar}
              onFocus={handleFocus}
              value={calendar}
              {...calendarProps}
            />
          </div>
          {children}
          <div className="sui-justify-end sui-flex">
            <Button onClick={handleClose} variant="primary">Close</Button>
          </div>
        </Collapse>
      </>
    );
  }

  return (
    <>
      {
        (variant === 'iconbutton') ? (
          <DateField
            disabled={disabled}
            label={label}
            status={status}
            statusMessage={statusMessage}
            onClick={onClick}
            onBlur={handleBlur}
            ref={handleRef}
            fullWidth
            value={dateField}
            onKeyDown={handleKeyDown}
            onChange={handleDateField}
            InputProps={{
              inputProps: {
                inputMode: 'numeric'
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Show Calendar" icon={Calendar} onClick={handleToggle} />
                </InputAdornment>
              ),
              disableStatus: true
            }}
            {...dateFieldProps}
            {...other}
          />
        ) : (
          <DateButton
            disabled={disabled}
            status={status}
            statusMessage={statusMessage}
            onBlur={handleBlur}
            onClick={handleToggle}
            onKeyDown={handleKeyDown}
            ref={handleRef}
            value={dateField}
            {...dateFieldProps}
            {...other}
          />
        )
      }
      <Popover
        open={open}
        anchorEl={anchorRef?.current}
        onClose={handleClose}
        PaperProps={isMultipleCalendarView ? { style: { maxWidth: '600px' } } : undefined}
        {...PopoverProps}
      >
        <DateCalendar
          autoFocus={autoFocus}
          onBlur={handleBlur}
          onChange={handleCalendar}
          onFocus={handleFocus}
          value={calendar}
          {...calendarProps}
        />
        {children}
        <div className="sui-justify-end sui-flex">
          <Button onClick={handleClose} variant="primary">Close</Button>
        </div>
      </Popover>
    </>
  );
});

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  /**
   * Props passed to the underlying Calendar component. Refer to the DateCalendar props.
   */
  calendarProps: PropTypes.object,
  /**
   * Content within the DatePicker component, rendered below the DateCalendar.
   */
  children: PropTypes.node,
  /**
   * If `true`, the component will use a collapsible Calendar.
   */
  collapsible: PropTypes.bool,
  /**
   * Props passed to the underlying DateField component. Refer to the DateField props.
   * It is now recommended to just pass the props directly to the component instead, as `dateFieldProps`
   * will be deprecated in the future.
   */
  dateFieldProps: PropTypes.object,
  /**
   * The default value selected in the DatePicker during initial mount. Use when the component is not controlled.
   */
  defaultValue: PropTypes.object,
  /**
   * If `true`, the component is inactive
   */
  disabled: PropTypes.bool,
  /**
   * Component's label content.
   */
  label: PropTypes.string,
  /**
   * Event fired when the user changes the selected date.
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore.
   */
  onBlur: PropTypes.func,
  /**
   * @ignore.
   */
  onFocus: PropTypes.func,
  /**
   * @ignore.
   */
  onKeyDown: PropTypes.func,
  /**
   * If `true`, the component will make the underlying Calendar visible.
   */
  open: PropTypes.bool,
  /**
   * Props passed to the Popover component.
   */
  PopoverProps: PropTypes.object,
  /**
   * The states of validation for the TextField component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   */
  statusMessage: PropTypes.string,
  /**
   * @ignore
   */
  transitionProps: PropTypes.node,
  /**
   * The date value of the component.
   */
  value: PropTypes.string,
  /**
   * Determines the DatePicker variant. `dialog` will use a button as a trigger while `iconbutton` will turn
   * the trigger into a `TextField` + `IconButton` combination.
   * @default dialog
   */
  variant: PropTypes.oneOf(['dialog', 'iconbutton'])
};

DatePicker.defaultProps = {};

export { DatePicker, formatDate };
