/* eslint-disable id-length */
import { TA_V2_DEFAULT_HOST } from '../../AddToConstants';

export const typeaheadAPI = {
  url: ({ requestSearchTerm, prevQuery }) => {
    const searchTermCleaned = encodeURIComponent(requestSearchTerm.replace(/\\u/i, ' '));
    const prevQueryParam = prevQuery ? `&prevQuery=${prevQuery}` : '';
    return `/TA2/search?term=${searchTermCleaned}${prevQueryParam}`;
  },

  fetch: async ({ searchText, recentSearches }) => {
    const prevQuery = recentSearches?.[0]?.keyword || null;
    const searchTermUrl = typeaheadAPI.url({
      requestSearchTerm: searchText, prevQuery
    });
    const urlWithHostName = TA_V2_DEFAULT_HOST + searchTermUrl;

    let fetchConfig = {};

    try {
      const response = await fetch(urlWithHostName, fetchConfig);
      const data = await response.json();

      const { r: results, cta } = data;
      return { results, cta };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Search term API Error:', error);
      return null;
    }
  },

  format: (data) => {
    return data?.filter((resultsObj) => typeof resultsObj?.t === 'string')
      .map(({ k: taLink, t: title }) => {
        return ({ link: '#', title });
      });
  }
};
