import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import {
  node,
  bool,
  number,
  shape
} from 'prop-types';
import className from 'classnames';
import { debounce } from '@thd-olt-functional/utils';
import { DEBOUNCE_DELAY_MS } from '../utils/constants';
import { isValidAnchorElement, ownerWindow } from '../utils/typeahead-utils';
import { typeaheadEvent } from '../events/publisher';
import { EventDictionary } from '../events/event-dictionary';
import HTMLElementType from '../utils/HTMLElementType';

const SuggestionsMenu = ({
  open,
  anchorElement,
  anchorOffset,
  fullScreenHeight,
  children
}) => {
  const openRef = useRef(null);
  const menuRef = useRef(null);

  const isReadyToOpen = () => {
    return open && isValidAnchorElement(anchorElement);
  };

  const publishEventHandler = useMemo(() => {
    const debouncePublishEvent = debounce(DEBOUNCE_DELAY_MS.MENU_OPEN_CLOSE_EVENT,
      (isMenuOpen) => {
      // only publish when isMenuOpen is different from previous boolean value
        if (typeof isMenuOpen !== 'boolean' || openRef.current === isMenuOpen) {
          return;
        }
        openRef.current = isMenuOpen;
        typeaheadEvent.publish(
          EventDictionary.SUGGESTIONS_MENU_OPEN_CLOSE_EVENT.name,
          { open: isMenuOpen });
      });

    return ({ isMenuOpen }) => {
      return debouncePublishEvent(isMenuOpen);
    };
  }, []);

  const getAnchorPosition = useCallback(() => {

    const anchorRect = anchorElement.getBoundingClientRect();

    return {
      top: anchorElement.offsetTop + anchorRect.height,
      left: anchorElement.offsetLeft,
      width: anchorRect.width
    };
  }, [anchorElement]);

  const getPositioningStyle = useCallback(() => {
    const anchorPosition = getAnchorPosition();
    return {
      top: `${anchorPosition.top + (+anchorOffset.top || 0)}px`,
      left: `${anchorPosition.left}px`,
      width: `${anchorPosition.width}px`,
      bottom: typeof anchorOffset.bottom === 'number' ? `${+anchorOffset.bottom}px` : null
    };

  }, [anchorElement, anchorOffset]);

  const setPositioningStyles = useCallback(() => {
    const menuEl = menuRef.current;

    const positioning = getPositioningStyle();

    menuEl.style.top = positioning.top;
    menuEl.style.width = positioning.width;
    menuEl.style.left = positioning.left;
    menuEl.style.bottom = positioning.bottom;
    menuEl.style.display = 'block';
  }, [getPositioningStyle]);

  useEffect(() => {
    if (!isReadyToOpen()) {
      return undefined;
    }
    setPositioningStyles();

    const handleResize = debounce(DEBOUNCE_DELAY_MS.DEFAULT, () => {
      setPositioningStyles();
    });

    const containerWindow = ownerWindow(anchorElement);
    containerWindow.addEventListener('resize', handleResize);

    publishEventHandler({ isMenuOpen: true });

    return () => {
      handleResize.cancel();
      containerWindow.removeEventListener('resize', handleResize);

      publishEventHandler({ isMenuOpen: false });
    };
  }, [anchorElement, open, setPositioningStyles]);

  const rootMenuClasses = className([
    'sui-z-max sui-w-full sui-absolute sui-bg-primary sui-shadow-base'
  ].join(' '), { 'sui-h-screen': fullScreenHeight });

  if (!isReadyToOpen()) return null;

  return (
    <div
      id="suggestions-menu-root"
      data-testid="suggestions-menu-root"
      className={rootMenuClasses}
      style={{ display: 'none' }} // hide until menuRef style is set
      ref={menuRef}
    >
      { children }
    </div>
  );
};

SuggestionsMenu.displayName = 'SuggestionsMenu';

SuggestionsMenu.propTypes = {
  open: bool,
  anchorElement: HTMLElementType,
  anchorOffset: shape({
    top: number,
    bottom: number
  }),
  fullScreenHeight: bool,
  children: node
};

SuggestionsMenu.defaultProps = {
  open: false,
  anchorElement: undefined,
  anchorOffset: shape({
    top: 0,
    bottom: undefined
  }),
  fullScreenHeight: false,
  children: null
};

export { SuggestionsMenu };
