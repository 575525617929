/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useContext, useState, useRef } from 'react';
import {
  bool as boolType,
  any,
  func,
  string as stringType,
  number as numType
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  bool,
  extend,
  string,
  shape,
  QueryProvider,
  QueryContext,
  params
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import classNames from 'classnames';
import CollectionsTitle from './CollectionsTitle';
import CollectionsUplevelTitle from './CollectionsUplevelTitle';
import { LoadingPlaceholder } from './LoadingPlaceholder';
import VisualTabs from './VisualTabs';
import VisualTabsCollection from './VisualTabsCollection';
import CollectionsImage from './CollectionsImage';
import { RecsProductPod } from './RecsProductPod';
import {
  getLoyaltyMembershipInput,
  getCollectionClass,
  getCollectionTitleClass,
  getCollectionPod,
  getClassNames,
  getCollPlaceholderLines
} from '../utils/helpers';
import '../../styles/thd-recs-containers.scss';

const Wrapper = ({ children }) => children;

Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string()
  })
}, RecsProductPod);
const CollectionCarousel = (props) => {

  const { channel, customer } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext) || {};
  const isB2BCustomer = customer?.type === 'b2b';

  const isMobile = channel === 'mobile';

  const {
    data,
    showColUpLevel,
    isBatteryCollection,
    hideTitle,
    loading,
    scheme,
    showLoading,
    storeId,
    // Displaying the category tabs
    isCategoryTab,
    hideATC,
    hideFavorites,
    hideBadge,
    membershipInformation,
    setCategoryGuid,
    setVisualTabsCategoriesData,
    tabClick,
    zipCode,
    hideSwatches,
    hideCarouselArrows,
    analyticsAnchorProductSku,
    parent,
    brandTitleMaxLine,
    hideRating,
    noATCFulfillment,
    tntTitle,
    tabCountConfigValue,
    analyticImpressionData
  } = props;

  const batteryCatData = useRef([]);
  const batteryData = useRef([]);

  const setFilteredProducts = (initialBatteryData, clickedTabItemIds) => {
    // eslint-disable-next-line max-len
    return initialBatteryData?.products?.filter((product) => clickedTabItemIds?.includes(product?.product?.itemId));
  };
  let fallbackBatteryProducts;
  if (!loading) {
    if (!tabClick?.length > 0 && !batteryCatData?.current?.length) {
      batteryData.current = data;
      batteryCatData.current = data?.categories;
    }
    if (tabClick?.length > 1 && (data === null || data === undefined)) {
      let clickedTab = batteryData?.current?.categories?.filter((category) => category?.category?.includes(tabClick));
      let filteredProducts = setFilteredProducts(batteryData?.current, clickedTab[0]?.itemIds);
      fallbackBatteryProducts = filteredProducts;
    }
  }

  const { products, metadata, categories } = batteryData?.current || {};

  const classes = getCollectionClass(showColUpLevel, isMobile);
  const titleClasses = getCollectionTitleClass(showColUpLevel, isMobile, metadata);
  const colSpanClasses = getClassNames(isMobile, showColUpLevel, metadata, isCategoryTab, isBatteryCollection, isB2BCustomer);
  const [filtered, setFiltered] = useState([]);
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const pullFilteredData = (filteredProducts) => {
    setIsCategorySelected(true);
    setFiltered(filteredProducts);
  };
  // when battery tab is clicked and loading is true, to render loading placeholder only for products area,
  // we need to have displayProducts products avaialble to render loading placeholder correctly, so we are using useRef to store the products
  // once the data is loaded, we are updating the displayProducts with the data
  const displayProducts = useRef([]);
  let numberOfPods = getCollectionPod(metadata, showColUpLevel, isB2BCustomer);

  const qtxDefaultVariables = {
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: getLoyaltyMembershipInput(membershipInformation),
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  if (!loading) {
    if (isCategorySelected) {
      displayProducts.current = filtered;
    } else if (fallbackBatteryProducts?.length > 0) {
      displayProducts.current = fallbackBatteryProducts;
    } else {
      displayProducts.current = data?.products;
    }
  }
  let placeholderTxtLines = getCollPlaceholderLines(showColUpLevel);

  let breakpoints = ({
    sm: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    md: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    lg: {
      slidesPerView: numberOfPods,
      slidesPerGroup: numberOfPods
    },
    xl: {
      slidesPerView: numberOfPods,
      slidesPerGroup: numberOfPods
    }
  });

  return (
    <>
      {showLoading && loading && tabClick?.length === 0 && (
        <LoadingPlaceholder txtPlaceholderLines={placeholderTxtLines} hideTitle={hideTitle} />
      )}

      {displayProducts?.current?.length > 0 && (
        <>
          <CollectionsTitle
            metadata={metadata}
            productIdentifier={products[0]?.product?.identifiers}
            isCategoryTab={isCategoryTab}
            isMobile={isMobile}
            isBatteryCollection={isBatteryCollection}
            showColUpLevel={showColUpLevel}
            tntTitle={tntTitle}
            scheme={scheme}
          />

          {!isMobile && isCategoryTab && showColUpLevel && (
            <div className="sui-pb-2">
              {isCategoryTab && categories?.length > 0 && categories?.length >= tabCountConfigValue && (
                // eslint-disable-next-line max-len
                <VisualTabs products={products} categories={categories} filterItemIds={pullFilteredData} showColUpLevel={showColUpLevel} setVisualTabsCategoriesData={setVisualTabsCategoriesData} />
              )}
            </div>
          )}

          <div className={classNames(classes)}>
            {(() => {
              if (metadata?.collectionImageUrl || isCategoryTab) {
                return (
                  <>
                    {showColUpLevel && isCategoryTab && !isBatteryCollection && (
                      <div className={titleClasses}>
                        <CollectionsUplevelTitle
                          metadata={metadata}
                          productIdentifier={products[0]?.product?.identifiers}
                          isCategoryTab={isCategoryTab}
                          isMobile={isMobile}
                          isBatteryCollection={isBatteryCollection}
                          showColUpLevel={showColUpLevel}
                        />
                      </div>
                    )}
                    {isBatteryCollection && !isB2BCustomer && (
                      <div className="sui-pt-6">
                        <CollectionsImage metadata={metadata} firstProductMedia={products[0]?.product?.media} isMobile={isMobile} showColUpLevel={showColUpLevel} />
                      </div>
                    )}
                  </>
                );
              }
              return null;
            })()}
            <div className={colSpanClasses}>
              {!isMobile && isCategoryTab && isBatteryCollection && (
                <div className="sui-pb-2">
                  {isCategoryTab && batteryCatData?.current?.length > 0 && batteryCatData?.current?.length >= tabCountConfigValue && (
                    // eslint-disable-next-line max-len
                    <VisualTabsCollection products={products} categories={batteryCatData?.current} setCategoryGuid={setCategoryGuid} />
                  )}
                </div>
              )}
              {showLoading && loading && tabClick?.length > 0 && (
                <LoadingPlaceholder txtPlaceholderLines={5} itemsCount={isB2BCustomer ? 6 : 4} hideTitle />
              )}
              {!loading && (
                <QueryProvider
                  dataSource={scheme}
                  cacheKey={`${scheme}-recommendations-carousel`}
                  defaultVariables={qtxDefaultVariables}
                >
                  <Carousel
                    breakpoints={breakpoints}
                    hideCarouselArrows={hideCarouselArrows}
                    disableMargin
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                  >
                    {displayProducts?.current?.map((dynamicProduct, idx) => {
                      const { product } = dynamicProduct;
                      const { itemId } = product;
                      const { preferredPriceFlag } = product?.pricing;
                      const { canonicalUrl } = product?.identifiers || {};

                      return (
                        <Wrapper key={idx}>
                          <RecsProductPod
                            itemId={itemId}
                            key={itemId}
                            position={idx}
                            storeId={storeId}
                            scheme={scheme}
                            anchorProduct={analyticsAnchorProductSku}
                            parent={parent}
                            hideSwatches={hideSwatches}
                            hideFavorites={hideFavorites}
                            hideBadge={hideBadge}
                            hideATC={hideATC}
                            hideRating={hideRating}
                            preferredPriceFlag={preferredPriceFlag}
                            brandTitleMaxLine={brandTitleMaxLine}
                            strategy={dynamicProduct?.strategy}
                            noATCFulfillment={noATCFulfillment}
                            analyticImpressionData={analyticImpressionData}
                            url={canonicalUrl}
                          />
                        </Wrapper>
                      );
                    }
                    )}
                  </Carousel>
                </QueryProvider>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const productModel = extend({}, {
  itemId: string(),
  dataSource: string()
}, RecsProductPod.dataModel.product);

const wrappedProduct = shape({
  category: string(),
  description: string(),
  idEndDate: string(),
  idPromoDetails: string(),
  idStartDate: string(),
  strategy: string(),
  isAnchor: bool(),
  product: params({
    dataSource: string()
  })
    .shape(productModel),
  slot: shape({
    description: string(),
    isRequired: bool(),
    name: string()
  })
});
CollectionCarousel.dataModel = extend(Wrapper, wrappedProduct);

CollectionCarousel.displayName = 'CollectionCarousel';

CollectionCarousel.propTypes = {
  ...RecsProductPod.propTypes,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  loading: boolType,
  hideTitle: boolType,
  scheme: stringType,
  showLoading: boolType,
  storeId: stringType.isRequired,
  subtitle: stringType,
  hideATC: boolType,
  hideFavorites: boolType,
  setCategoryGuid: func,
  hideBadge: boolType,
  isCategoryTab: boolType,
  brandTitleMaxLine: numType,
  hideSwatches: boolType,
  hideCarouselArrows: boolType,
  analyticsAnchorProductSku: stringType,
  hideRating: boolType,
  noATCFulfillment: boolType
};

CollectionCarousel.defaultProps = {
  hideTitle: false,
  scheme: 'drecs',
  showLoading: true,
  loading: false,
  data: null,
  subtitle: null,
  hideBadge: false,
  hideATC: false,
  setCategoryGuid: null,
  hideFavorites: true,
  isCategoryTab: false,
  brandTitleMaxLine: 4,
  hideSwatches: true,
  hideCarouselArrows: false,
  analyticsAnchorProductSku: '',
  hideRating: false,
  noATCFulfillment: true
};

export { CollectionCarousel };
