import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { DrawerBody, DrawerFooter, Button } from '@one-thd/sui-atomic-components';
import { Specifications } from '@thd-olt-component-react/specifications';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const ProductSpecs = ({ itemId, updateList }) => {
  const { setScreen, addToType } = useContext(AddToContext);
  const { PROJECT_SELECT_GROUP, DEFAULT } = SCREENS;

  const handleSubmit = async () => {
    if (addToType === 'List') {
      await updateList({
        variables: {
          products: [{ itemId, quantity: 1 }],
        }
      });
      setScreen(DEFAULT);
    } else {
      setScreen(PROJECT_SELECT_GROUP);
    }
  };

  return (
    <>
      <DrawerBody>
        <Specifications itemId={itemId} hideMobileFade oneColumn />
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="productSpecs-AITP-button"
          variant="secondary"
          fullWidth
          onClick={handleSubmit}
          type="submit"
        >
          Add to {addToType}
        </Button>
      </DrawerFooter>
    </>
  );
};

ProductSpecs.displayName = 'ProductSpecs';

ProductSpecs.propTypes = {
  itemId: PropTypes.string.isRequired,
  updateList: PropTypes.func
};

ProductSpecs.defaultProps = {
  updateList: () => {}
};

ProductSpecs.dataModel = extend(Specifications);
