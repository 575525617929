import {
  arrayOf as arrayType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  bool as booleanType,
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      canonicalUrl: stringType(),
      productLabel: stringType(),
    }),
  }),
  questionsAnswers: params({
    itemId: stringType().isRequired(),
    startindex: numberType(),
    pagesize: numberType(),
    searchTerm: stringType(),
    sortBy: stringType(),
  }).shape({
    Includes: shapeType({
      Answers: arrayType(
        shapeType({
          AnswerId: stringType(),
          Answer: {
            Id: stringType(),
            AnswerText: stringType(),
            AuthorId: stringType(),
            CampaignId: stringType(),
            LastModeratedTime: stringType(),
            LastModificationTime: stringType(),
            ModerationStatus: stringType(),
            UserNickname: stringType(),
            IsFeatured: booleanType(),
            IsBrandAnswer: booleanType(),
            BadgesOrder: arrayType(stringType()),
            TotalPositiveFeedbackCount: numberType(),
            Photos: arrayType(shapeType({
              Id: stringType(),
              Caption: stringType(),
              SizesOrder: arrayType(stringType()),
              Sizes: shapeType({
                normal: shapeType({
                  Id: stringType(),
                  Url: stringType()
                }),
                thumbnail: shapeType({
                  Id: stringType(),
                  Url: stringType()
                })
              })
            }))
          },
        })
      ),
      AnswersOrder: arrayType(stringType()),
    }),
    Limit: numberType(),
    Offset: numberType(),
    Results: arrayType(
      shapeType({
        Id: stringType(),
        AuthorId: stringType(),
        AnswerIds: arrayType(stringType()),
        TotalAnswerCount: numberType(),
        QuestionSummary: stringType(),
        QuestionDetails: stringType(),
        SubmissionTime: stringType(),
        UserNickname: stringType(),
        SubmissionId: stringType(),
        Photos: arrayType(shapeType({
          Id: stringType(),
          Caption: stringType(),
          SizesOrder: arrayType(stringType()),
          Sizes: shapeType({
            normal: shapeType({
              Id: stringType(),
              Url: stringType()
            }),
            thumbnail: shapeType({
              Id: stringType(),
              Url: stringType()
            })
          })
        }))
      })
    ),
    TotalResults: numberType(),
  }),
};
