/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, customType,
  number, bool, extend
} from '@thd-nucleus/data-sources';
import {
  RadioGroup, Radio, Button,
  SelectionControlLabel,
  DrawerFooter, DrawerBody,
} from '@one-thd/sui-atomic-components';
import { useProjectDetails } from '../../hooks/useProjects';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';
import { AddToProductDetails } from '../../common/AddToProductDetails/AddToProductDetails';

export const SelectGroup = ({
  projectId,
  handleClose,
  products,
  addItem,
  groupsExist
}) => {
  const {
    setScreen,
    selectedGroup,
    setSelectedGroup,
  } = useContext(AddToContext);
  const { PROJECT_CREATE_GROUP } = SCREENS;
  const { projectData } = useProjectDetails({ projectId });
  const {
    itemGroups,
  } = projectData?.projectDetails || {};

  const groups = (itemGroups || []).filter((itemGroup) => itemGroup.groupName !== '');

  const onSelectGroup = (event) => {
    const groupName = event.target.value;
    setSelectedGroup(groupName);
  };

  const showCreateGroup = () => {
    setScreen(PROJECT_CREATE_GROUP);
  };

  const addItemToList = async () => {
    await addItem();
  };

  return (
    <>
      <DrawerBody overflow="scroll">
        {products?.length <= 1 && (
          <AddToProductDetails products={products} />
        )}
        <div>
          {
            !groupsExist && 'Before adding items to this project, please create a group.'
          }
          <Button
            className="sui-mb-4 sui-mt-12 sui-mx-6 sui-text-base sui-flex"
            variant="text"
            onClick={showCreateGroup}
          >
            <u>Create New Group</u>
          </Button>
        </div>
        <div className="sui-pl-5">
          {
            groupsExist && (
              <>
                <div className="sui-px-8 sui-py-3">-or-</div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="dog"
                  name="radio-buttons-group"
                  onChange={onSelectGroup}
                >
                  {
                    groups?.map((group, i) => (
                      <SelectionControlLabel value={group.groupName} label={group.groupName} key={i}>
                        <Radio />
                      </SelectionControlLabel>
                    ))
                  }
                </RadioGroup>
              </>
            )
          }
        </div>
      </DrawerBody>
      <DrawerFooter position="center" data-testid="add-to-project-add-to-group-footer">
        <div className="sui-flex sui-flex-col sui-gap-1">
          <Button
            data-testid="add-to-project-add-button"
            variant="primary"
            fullWidth
            disabled={!groupsExist || !selectedGroup}
            onClick={addItemToList}
            type="submit"
          > Add
          </Button>
          <Button
            data-testid="add-to-project-cancel-button"
            variant="secondary"
            fullWidth
            onClick={handleClose}
            type="submit"
          >
            Cancel
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

SelectGroup.propTypes = {
  projectId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  groupsExist: PropTypes.bool
};

SelectGroup.defaultProps = {
  products: [],
  groupsExist: false
};

SelectGroup.dataModel = extend(
  AddToProductDetails
);
