import { useEffect, useState, useMemo } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';

export const useBundle = ({ product }) => {
  const isBundlePackageEnabled = useConfigService('fs:isBundleEntryEnabled');
  const [errorOnBundleMini, setErrorOnBundleMini] = useState(false);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('bundle-mini.error', () => {
      setErrorOnBundleMini(true);
    });
    return () => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('bundle-mini.error');
    };
  }, []);

  const bundleIds = useMemo(() => {
    if (errorOnBundleMini) {
      return null;
    }

    const productParents = product?.bundleSpecificationDetails?.parents || [];
    if (!isBundlePackageEnabled || productParents.length === 0) {
      return null;
    }

    return productParents?.map((parent) => parent.parentId);
  }, [product, errorOnBundleMini, isBundlePackageEnabled]);

  return {
    bundleIds
  };
};