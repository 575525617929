export const SEARCH_HISTORY_STORAGE_KEY = 'header_search_history';
export const TA_V2_SEARCH_API = 'typeahead-v2-search-api';
// eslint-disable-next-line
export const TA_V2_DEFAULT_HOST= typeof __DEFAULT_HOST__ !== 'undefined' ?  __DEFAULT_HOST__ : '';
export const PAGE_SIZE = 12;

export const publicAccessType = 'Public';
export const privateAccessType = 'Private';
export const sharedAccessType = 'Shared';

export const PROJECT_SETTINGS_SAVE = 'project-forms.settings-save';
export const PROJECT_CREATE_WO_PRODUCTS = 'project-forms.create-without-products';
export const PROJECT_DELETE = 'project-forms.project-delete';
export const PROJECT_COPY = 'project-forms.project-copy';
export const PROJECT_SETTINGS_FORM_LOAD = 'project-forms.settings-form-load';
export const PROJECT_SHARED_SAVE_CLICK = 'project-forms.save-a-shared-project';
export const PROJECT_SHARE_CLICK = 'project-forms.share-click';

export const ADD_TO_PROJECT_SCREEN = {
  DEFAULT: 'DEFAULT',
  ADD_ITEMS: 'ADD_ITEMS',
  CREATE_GROUP: 'CREATE_GROUP',
  MINI_PIP: 'MINI_PIP',
  PRODUCT_OVERVIEW: 'PRODUCT_OVERVIEW',
  PRODUCT_SPECS: 'PRODUCT_SPECS',
  PRODUCT_REVIEWS: 'PRODUCT_REVIEWS',
};

export const DELETE_GROUP_OPERATION = 'DELETE_GROUP';
export const DELETE_ITEM_OPERATION = 'DELETE_ITEM';

export const PROJECT_ALREADY_EXISTS_ERROR = 'Project name already exists';
export const GROUP_ALREADY_EXITS_ERROR = 'Group already exists';

// eslint-disable-next-line max-len
export const INVALID_PROJECT_TEXT_MESSAGE = 'Invalid Text: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.';
export const EMPTY_PROJECT_NAME_MESSAGE = 'Enter Project Name';
export const EMPTY_GROUP_NAME_MESSAGE = 'Enter Group Name';
export const EMPTY_SUBMITTED_GROUP_NAME_MESSAGE = 'Please enter a group name';
export const INVALID_NAME_LENGTH_MESSAGE = 'Project Names can be at most 80 characters';
export const INVALID_GROUP_LENGTH_MESSAGE = 'Group Names can be at most 50 characters';
export const INVALID_ADDRESS_LENGTH_MESSAGE = 'Address can be at most 60 characters';
export const EMPTY_PROJECT_TYPE_MESSAGE = 'Please provide a Project Type';
export const INVALID_TYPE_LENGTH_MESSAGE = 'Project Types can be at most 40 characters';
export const INVALID_NOTE_LENGTH_MESSAGE = 'Project Description can be at most 1000 characters';
export const INVALID_START_DATE = 'Invalid start date';
export const INVALID_END_DATE = 'Invalid end date';
export const INVALID_ZIP_CODE = 'Please provide a valid ZIP code';
export const EMPTY_ZIP_CODE_MESSAGE = 'Zip code is required if address is provided.';
export const EMPTY_ADDRESS_MESSAGE = 'Address Line 1 is required if any part of the jobsite address is provided.';

export const ACTIVE = 'active';
export const ARCHIVED = 'archived';
export const ARCHIVE = 'Archive';
export const SET_ACTIVE = 'Set Active';
export const DELETE = 'Delete';
export const STATUS_SESSION_STORAGE_KEY = 'status';

export const MODAL_INFO_ARRAY = [{
  type: 'Delete',
  header: 'Delete Project?',
  query: 'Are you sure you want to delete this project?',
  warning: 'Deleteing the project will permanently remove it and its contents. This action cannot be undone.',
  button: 'Delete',
},
{
  type: 'Archive',
  header: 'Archive Project?',
  query: 'Are you sure you want to archive',
  warning: `Archiving the project will keep it accessible for future reference, but it will no longer be
    actively used or populated. You can reactivate it later if needed.`,
  button: 'Archive',
},
{
  type: 'Reactivate',
  header: 'Set Project as Active?',
  query: 'Are you sure you want to set this project as active?',
  warning: `Setting the project as active will allow users to make edits and perform all actions associated
    with an active project.`,
  button: 'Active',
}];

// generals
export const STR_1 = '1';
export const STR_2 = '2';
export const CANCEL_BUTTON = 'cancel button';
export const X_BUTTON = 'x button';
export const BACK_BUTTON = 'back button';
export const REPLY_SENT = 'reply sent';

// Analytics
export const PROJECT_CREATE_BUTTON_CLICKED = 'projects: create new project button';
export const PROJECT_CREATE_OVERLAY = 'projects: create new project overlay';
export const PROJECT_CREATE_CLICK = 'projects: create new project overlay: create project button';
export const PROJECT_CREATE_SUCCESS = 'new project created';
export const PROJECT_STATUS_MENU_CLICK = 'projects: status menu click';
export const PROJECT_STATUS_OVERLAY = 'projects: status overlay';
export const PROJECT_STATUS_CHANGE = 'projects: status change';
export const PROJECT_DETAILS_SHARE_YOUR_PROJECT = 'projects: project details: share your project';
export const SHARE_YOUR_PROJECT_SEND = 'projects: project details: share your project: send button';
export const SHARE_YOUR_PROJECT_STEP = 'projects: project details: share your project: step';
export const PROJECT_REPLY_TO_MSG_OVERLAY = 'projects: reply to message overlay';
export const SHARE_YOUR_PROJECT_CONTINUE = 'projects: project details: share your project: continue';
export const PROJECT_DETAILS_REPLY_TO_MESSAGE_OVERLAY = 'projects: project details: reply to message overlay';
export const PROJECT_NEW_MESSAGE_CLICK = 'projects: project details: new message click';
export const PROJECT_REPLY_BTN_CLICK = 'projects: project details: reply button';
