import {
  string as stringType
} from '@thd-nucleus/data-sources';
import THDStorageUtils, { LOCAL_STORAGE, SESSION_STORAGE } from '@thd-olt-global/thd-storage-utils';
import {
  INVALID_PROJECT_TEXT_MESSAGE,
  EMPTY_PROJECT_NAME_MESSAGE,
  INVALID_NAME_LENGTH_MESSAGE,
  EMPTY_PROJECT_TYPE_MESSAGE,
  INVALID_TYPE_LENGTH_MESSAGE,
  INVALID_NOTE_LENGTH_MESSAGE,
  INVALID_ADDRESS_LENGTH_MESSAGE,
  SEARCH_HISTORY_STORAGE_KEY,
  EMPTY_GROUP_NAME_MESSAGE,
  INVALID_GROUP_LENGTH_MESSAGE,
  STATUS_SESSION_STORAGE_KEY,
} from './constants';

export const storeStatusChange = (statusValue) => {
  THDStorageUtils.set(SESSION_STORAGE, { key: STATUS_SESSION_STORAGE_KEY, value: statusValue });
};

export const fetchSearch = () => {
  let history = null;
  try {
    history = THDStorageUtils.get(LOCAL_STORAGE, { key: SEARCH_HISTORY_STORAGE_KEY });
  } catch (err) {
    return [];
  }
  if (!history || history === 'undefined') return [];
  return JSON.parse(history).slice(0, 10);
};

export const searchHystoryFormat = (data) => {
  return data?.map(({ keyword }) => {
    const cleanKeyword = decodeURIComponent(keyword) || keyword;
    return ({ link: `/s/${keyword}`, title: cleanKeyword });
  });
};

export const PROJECT_SHAPE = {
  id: stringType(),
  name: stringType(),
  projectAccessType: stringType()
};

export const validateProjectName = (value) => {
  const validCharacters = /^(?=.{0,80}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 80;
  let errorMessage = '';
  if (!isValid) {
    if (blank) {
      errorMessage = EMPTY_PROJECT_NAME_MESSAGE;
    } else if (charLimit) {
      errorMessage = INVALID_NAME_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};

export const validateGroupName = (value) => {
  const validCharacters = /^(?=.{0,50}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 50;
  let errorMessage = '';
  if (!isValid) {
    if (blank) {
      errorMessage = EMPTY_GROUP_NAME_MESSAGE;
    } else if (charLimit) {
      errorMessage = INVALID_GROUP_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};

export const validatePropertyType = (value) => {
  const validCharacters = /^(?=.{0,40}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 40;
  let errorMessage = '';
  if (!isValid) {
    if (blank) {
      errorMessage = EMPTY_PROJECT_TYPE_MESSAGE;
    } else if (charLimit) {
      errorMessage = INVALID_TYPE_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};

export const validateProjectDescription = (value) => {
  let errorMessage = '';
  if (!value) return { isError: false, errorMessage };
  const validCharacters = /^(?=.{0,1000}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 1000;
  if (!isValid) {
    if (charLimit) {
      errorMessage = INVALID_NOTE_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};

export const validateEstimatedStartDate = (value, endDate) => {
  if (endDate && (!value || new Date(value) >= new Date(endDate))) {
    return {
      isError: true,
      endDateErrorMessage: 'End date must be after start date',
    };
  }
  return { isError: false, endDateErrorMessage: '' };
};

export const validateEstimatedEndDate = (value, startDate) => {
  if ((new Date(value) <= new Date(startDate)) || !startDate) {
    return {
      isError: true,
      endDateErrorMessage: 'End date must be after start date'
    };
  }
  return { isError: false, endDateErrorMessage: '' };
};

export const validateTextField = (value) => {
  let errorMessage = '';
  if (!value) return { isError: false, errorMessage };
  const validCharacters = /^(?=.{0,60}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 60;
  if (!isValid) {
    if (charLimit) {
      errorMessage = INVALID_ADDRESS_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};

export const generateLoadedProducts = (existingProducts, newProducts) => {
  const existingItemIds = existingProducts.map((product) => product.itemId);
  const productsToLoad = newProducts?.filter((product) => !existingItemIds.includes(product.id)) || [];
  return [...existingProducts, ...productsToLoad];
};

export const getNvalue = (path) => {
  const pathArr = path.split('/');
  const fullNValue = pathArr.find((part) => part.startsWith('N-') || part.startsWith('Ntt-'));
  if (fullNValue) {
    const nValue = fullNValue.split('?')?.[0];
    if (nValue.startsWith('N-')) {
      return nValue.slice(2);
    }
    return nValue.slice(4);
  }
  return null;
};

export const triggerAnalytics = (event, payload) => {
  LIFE_CYCLE_EVENT_BUS.trigger(event, payload);
};

export const cityAndStateValidation = (value) => {
  let errorMessage = '';
  if (!value) return { isError: false, errorMessage };
  const validCharacters = /^[a-zA-Z\s-]*$/;
  let isValid = validCharacters.test(value);
  if (!isValid) {
    errorMessage = 'Invalid text';
  }
  return { isError: !isValid, errorMessage };
};

export const addNonQuotableProductVariables = (product) => {
  const { identifiers, info } = product || {};

  const isMajorAppliance = identifiers?.productType === 'MAJOR_APPLIANCE';
  if (isMajorAppliance) {
    return {
      isQuotable: false,
      notQuotableLabel: 'Major appliances'
    };
  }

  const isConfigurableBlinds = identifiers?.productType === 'CONFIGURABLE_BLINDS';
  if (isConfigurableBlinds) {
    return {
      isQuotable: false,
      notQuotableLabel: 'GCC configurators'
    };
  }

  const isLiveGoods = info?.isLiveGoodsProduct;
  if (isLiveGoods) {
    return {
      isQuotable: false,
      notQuotableLabel: 'Live goods'
    };
  }

  return {};
};

export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const formatDate = (date) => new Date(date).toISOString().split('T')[0];
