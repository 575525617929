import React, { useEffect, useContext } from 'react';
import { string, arrayOf } from 'prop-types';

import { extend, QueryProvider, QueryContext, useDataModel } from '@thd-nucleus/data-sources';

import { BundlesDataModel } from '../models/dataModel';
import { BundleMiniItem } from '../subcomponents/BundleMiniItem';

const BundleMini = ({ bundleIds }) => {
  const { defaultVariables } = useContext(QueryContext);
  const isValidBundleIds = bundleIds && Array.isArray(bundleIds) && bundleIds?.length > 0;

  const { data, loading } = useDataModel('products', {
    variables: {
      itemIds: bundleIds
    },
    skip: !isValidBundleIds
  });

  const bundles = data?.products || [];

  const triggerErrorEvent = () => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-mini.error');
  };

  const noBundles = !loading && bundles.length === 0;

  useEffect(() => {
    if (noBundles) {
      triggerErrorEvent();
    }
  }, [noBundles]);

  if (noBundles) {
    return null;
  }

  return (
    <QueryProvider
      cacheKey="bundle-mini-item"
      defaultVariables={defaultVariables.current}
    >
      <BundleMiniItem
        bundleLoading={loading}
        bundleData={bundles[0]}
        onError={triggerErrorEvent}
      />
    </QueryProvider>
  );
};

BundleMini.propTypes = {
  bundleIds: arrayOf(string.isRequired)
};

BundleMini.defaultProps = {
  bundleIds: []
};

BundleMini.displayName = 'BundleMini';

BundleMini.dataModel = extend(
  BundlesDataModel
);

export { BundleMini };