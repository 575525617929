import React from 'react';
import { Skeleton, SkeletonContent, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { AddToPodPlaceHolder } from '../addto-product-pod/AddToPodPlaceHolder';

export const PreviouslyPurchasedPlaceholder = () => {
  return (
    <div
      className="sui-w-full"
      data-component="previously-purchased-carousel-placeholder"
      data-testid="previously-purchased-carousel-placeholder"
    >
      <Skeleton
        orientation="vertical"
        disablePadding
        PaperProps={{
          className: '',
          variant: 'shadow'
        }}
        SkeletonContentProps={{
          disableGutters: true
        }}
      >
        <SkeletonContent>
          <SkeletonBlock aspect="wide" height={11} />
        </SkeletonContent>
        <div className="sui-grid sui-grid-cols-2 sui-gap-2 sui-w-full">
          <AddToPodPlaceHolder count={2} />
        </div>
        <SkeletonContent>
          <SkeletonBlock aspect="wide" height={11} />
        </SkeletonContent>
      </Skeleton>
    </div>
  );
};

PreviouslyPurchasedPlaceholder.displayName = 'ProjectFormsPreviouslyPurchasedPlaceholder';
