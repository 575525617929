import dayjs from 'dayjs';

export function isSameDate(dateA, dateB) {
  const parsedDateA = dateA ? dayjs(dateA) : dayjs(null);
  const parsedDateB = dateB ? dayjs(dateB) : dayjs(null);
  return parsedDateA.isSame(parsedDateB, 'days');
}

export function addDays(date, amount, granularity) {
  const parsedDate = date ? dayjs(date) : dayjs(null);
  return parsedDate.add(amount, granularity);
}