import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, SkeletonContent, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';

export const AddToPodPlaceHolder = ({ count }) => {
  const placeholders = new Array(Math.max(1, count)).fill('');
  return (
    <>
      {
        placeholders.map((placeholder, placeholderIndex) => {
          return (
            <div key={placeholderIndex} data-testid="project-add-item-placeholder">
              <Skeleton
                orientation="vertical"
                disablePadding
                PaperProps={{
                  className: '',
                  variant: 'shadow'
                }}
                SkeletonContentProps={{
                  disableGutters: true
                }}
              >
                <SkeletonContent>
                  <SkeletonBlock aspect="wide" height={44} />
                </SkeletonContent>
                <SkeletonContent>
                  <SkeletonLine numberOfLines={4} />
                </SkeletonContent>
                <SkeletonContent>
                  <SkeletonBlock aspect="wide" height={10} />
                </SkeletonContent>
                <SkeletonContent>
                  <SkeletonBlock aspect="wide" height={11} />
                </SkeletonContent>
              </Skeleton>
            </div>
          );
        })
      }
    </>
  );
};

AddToPodPlaceHolder.propTypes = {
  count: PropTypes.number
};

AddToPodPlaceHolder.defaultProps = {
  count: 1
};

AddToPodPlaceHolder.displayName = 'ProjectProductPodPlaceHolder';
