import React from 'react';
import {
  arrayOf, shape, string, bool, func, number
} from 'prop-types';
import { SpecialSavings } from '@one-thd/sui-icons';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';
import classNames from 'classnames';

export const HotspotTagIcon = ({
  isThumbnail, hotspots, ocrHotspots, openOverlay, onHotspotImageClick, imageUrl, mediaGalleryIndex
}) => {
  const thumbnailStyles = {
    position: 'absolute',
    top: '36%'
  };

  return (
    <>
      {!isThumbnail && (
        <MediaHotspots
          hotspots={hotspots}
          ocrHotspots={ocrHotspots}
          openOverlay={openOverlay}
          onHotspotImageClick={onHotspotImageClick}
          imageUrl={imageUrl}
          mediaGalleryIndex={mediaGalleryIndex}
          data-testid="hotspot-tag-icon"
        />
      )}
      {
        isThumbnail && (
          <span className="sui-text-inverse" data-testid="hotspot-tag-icon" style={thumbnailStyles}>
            <SpecialSavings />
          </span>
        )
      }
    </>

  );
};

HotspotTagIcon.propTypes = {
  isThumbnail: bool,
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })),
  ocrHotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })),
  openOverlay: bool,
  onHotspotImageClick: func,
  imageUrl: string,
  mediaGalleryIndex: number
};

HotspotTagIcon.defaultProps = {
  isThumbnail: false,
  hotspots: [],
  ocrHotspots: [],
  openOverlay: false,
  onHotspotImageClick: null,
  imageUrl: '',
  mediaGalleryIndex: 0
};
