import React, { useState } from 'react';
import {
  string,
  bool,
  func,
  element,
  oneOfType,
  shape,
  arrayOf,
  number,
} from 'prop-types';
import {
  Checkbox, FormGroup, Radio, RadioGroup, Typography, SelectionControlLabel,
} from '@one-thd/sui-atomic-components';
import { ErrorFilled } from '@one-thd/sui-icons';
import { PRODUCT_ADD_ON_IDENTIFIER } from '@thd-olt-functional/utils';
import { HdppItemOptionPod } from '../../HdppItemOptionPod';

const ProductAddOnsPodStencil = ({
  heading,
  subHeading,
  icon,
  onChange,
  tooltip,
  tooltipOptional,
  displayDisabledText,
  radio,
  contactInfo,
  children,
  optionsForProtectionPlan,
  setErrorProtectionPlan,
  userOptions,
  activateRequestAppointment,
  assemblyInfo,
  installationCategory,
  isGarbageDisposalVirtualAssistance
}) => {
  const Input = radio ? Radio : Checkbox;
  const Group = radio ? RadioGroup : FormGroup;
  const { HDPP } = PRODUCT_ADD_ON_IDENTIFIER;

  const handleChange = (evt) => {
    onChange(evt);
  };

  const handleChangeProPlan = (itemId) => {
    let checked = true;
    if (itemId === '0') {
      checked = false;
    }
    onChange({
      itemId,
      checked,
      addOnType: HDPP
    });
    LIFE_CYCLE_EVENT_BUS.trigger('hdpp.change', { isOptedIn: checked, itemId });
  };

  const getDisplayPrice = (price) => {
    if (price === 0) {
      return <span className="sui-font-bold sui-text-success">&nbsp;FREE</span>;
    }
    if (!price) {
      return null;
    }
    if (typeof price === 'number') {
      return (
        <span className="sui-font-bold">&nbsp;+&#36;{price.toFixed(2)}</span>
      );
    }
    if (typeof price === 'string') {
      return (
        <span className="sui-font-bold">{price}</span>
      );
    }
    return null;
  };

  const [hdppSelectedItem, setIsHdppSelected] = useState('0');
  if (displayDisabledText) {
    return (
      <div className="sui-flex sui-py-5 sui-text-danger">
        <div className="sui-mt-1"><ErrorFilled size="small" /></div>
        <div className="sui-ml-2">
          {displayDisabledText}
        </div>
      </div>
    );
  }
  return (
    <div className="sui-flex sui-flex-col sui-pl-1">
      <div className="sui-flex">
        <div className="sui-mr-3">
          {icon}
        </div>
        <div className="sui-flex sui-flex-col sui-gap-2 sui-leading-tight">
          <Typography variant="h4" weight="bold">{heading}</Typography>
          { subHeading && <div data-testid="subHeading" className="sui-text-base"> {subHeading} </div> }
          {!activateRequestAppointment && userOptions.length > 0 && (
            <div>
              <div className="sui-flex">
                {installationCategory !== 'Faucet' && !isGarbageDisposalVirtualAssistance && (
                  <Group>
                    {
                      (userOptions || []).map(
                        ({
                          value, checked, label, price
                        }, id) => (
                          <div
                            className="sui-flex sui-items-center sui-text-base sui-mr-4 -sui-ml-2 sui-leading-tight"
                            key={id}
                          >
                            <Input
                              id={`${value}__checkbox`}
                              onChange={handleChange}
                              checked={checked}
                              value={value}
                            />
                            <label htmlFor={`${value}__checkbox`}>
                              <span className="sui-font-regular sui-text-base">
                                {label}
                              </span>
                              <span className="sui-font-bold">
                                {getDisplayPrice(price)}
                              </span>
                            </label>
                          </div>
                        ))
                    }
                  </Group>
                )}
              </div>
              {tooltipOptional}
            </div>
          )}
          {optionsForProtectionPlan?.protectionPlanSkuArr
            && optionsForProtectionPlan?.protectionPlanSkuArr?.length > 0 && (
              <div className="sui-flex">
                <Group>
                  {optionsForProtectionPlan.protectionPlanSkuArr.map((item, index) => (
                    <HdppItemOptionPod
                      key={index}
                      index={index}
                      itemId={item}
                      optionsForProtectionPlan={optionsForProtectionPlan}
                      handleChangeProPlan={handleChangeProPlan}
                      Input={Input}
                      setIsHdppSelected={setIsHdppSelected}
                      hdppSelectedItem={hdppSelectedItem}
                      setErrorProtectionPlan={setErrorProtectionPlan}
                      getDisplayPrice={getDisplayPrice}
                    />
                  ))}
                  <SelectionControlLabel
                    label={<div data-id={optionsForProtectionPlan.protectionPlanSkuArr.length}>No thanks</div>}
                    value="hdpp-decline"
                  >
                    <Input
                      id="hdpp-decline__checkbox"
                      name={radio ? '0' : 0}
                      checked={hdppSelectedItem === '0'}
                      onClick={() => {
                        setIsHdppSelected('0');
                        handleChangeProPlan('0');
                        LIFE_CYCLE_EVENT_BUS.trigger(
                          'HDPP.change',
                          { isHDPP: false, HDPPValue: 0 });
                      }}
                    />
                  </SelectionControlLabel>
                </Group>
                {tooltipOptional}
              </div>
            )}
          {assemblyInfo}
          {contactInfo}
          {tooltip}
          {activateRequestAppointment && tooltipOptional}
        </div>
      </div>
      {children}
    </div>
  );
};

ProductAddOnsPodStencil.displayName = 'ProductAddOns-ProductAddOnsPodStencil';

ProductAddOnsPodStencil.propTypes = {
  /** heading -> Renders as the descriptive title */
  heading: string.isRequired,
  /** subHeading -> Renders as the descriptive sub-title */
  subHeading: oneOfType([element, string]),
  /** onChange -> callback ref for user selection event */
  onChange: func,
  /** Populates the tooltip */
  tooltip: element,
  assemblyInfo: element,
  /** Populates the optional tooltip */
  tooltipOptional: element,
  /** Renders Icon Image */
  icon: element,
  /** Populates the contact information */
  contactInfo: element,
  /** userOptions -> list that display input type of checkbox or radio and its label */
  /** radio -> Checkbox is the default type for user options list.  */
  radio: bool,
  /** disabledDisplayText - When this is truthy, display this over default template */
  displayDisabledText: string,
  /** Any element that will be rendered below the existing template */
  children: element,
  optionsForProtectionPlan: shape({
    variables: shape({
      storeId: string,
      price: number,
      protectionPlanParentId: string,
    }),
    ssr: bool,
    skip: bool,
    protectionPlanSkuArr: arrayOf(string),
  }).isRequired,
  setErrorProtectionPlan: func.isRequired,
  userOptions: arrayOf(shape({
    /** value - current product add on */
    value: string.isRequired,
    /** checkbox state */
    checked: bool.isRequired,
    /** Checkbox Label */
    label: string.isRequired,
    /** Price of the add on */
    price: oneOfType([number, string]),
  })),
  activateRequestAppointment: bool,
  isGarbageDisposalVirtualAssistance: bool,
  installationCategory: string
};

ProductAddOnsPodStencil.defaultProps = {
  subHeading: '',
  displayDisabledText: '',
  radio: false,
  tooltip: null,
  icon: null,
  assemblyInfo: null,
  tooltipOptional: null,
  contactInfo: null,
  children: null,
  onChange: () => { },
  userOptions: [],
  activateRequestAppointment: false,
  isGarbageDisposalVirtualAssistance: false,
  installationCategory: ''
};

export { ProductAddOnsPodStencil };
