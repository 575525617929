import React, { useEffect } from 'react';
import { SpecialSavings } from '@one-thd/sui-icons';
import { Typography, Badge } from '@one-thd/sui-atomic-components';
import { useMessage } from '../hooks/useMessage';
import { MESSAGE_KEY } from '../utils/constants';
import { usePromoModel } from '../hooks/usePromoModel';
import {
  getShortenedPromoMessage,
  setProductImpressionData,
  triggerNewRelicEvent
} from '../utils/utils';

export const PromoMessaging = () => {

  const promoMessage = useMessage(MESSAGE_KEY.plpMessage);
  const {
    itemId, subExperience, rewardTiers, src1EligibilityCriteria, tgt1EligibilityCriteria
  } = usePromoModel();
  const analyticsMessage = getShortenedPromoMessage(
    subExperience,
    rewardTiers,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria
  );

  useEffect(() => {
    if (analyticsMessage) {
      setProductImpressionData(itemId, {
        name: 'promo messaging',
        type: analyticsMessage
      });
    }

    triggerNewRelicEvent({
      actionName: (promoMessage
        ? 'PLP-Promo-Messaging' : 'PLP-Promo-Messaging-Not-Built'
      ),
      customAttributes: {
        segment: 'Promotion',
        subExperience,
        itemId,
        promoMessage
      }
    });
  });

  if (!promoMessage) {
    return null;
  }

  return (
    <div className="promo messaging1 " data-testid="promo-message">
      <div className="sui-flex sui-flex-row">
        <Badge
          startIcon={<SpecialSavings size="small" />}
          variant="regular"
          color="success"
        >
          <div className="sui-pb-1">
            <Typography
              align="left"
              component="span"
              data-testid="promo-primary-message"
              variant="h5"
              className="sui-pb-2 "
            >
              <span className="sui-gap-2 sui-text-xs">
                {promoMessage}
              </span>
            </Typography>
          </div>
        </Badge>
      </div>
    </div>
  );
};
