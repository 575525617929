export const SEARCH_HISTORY_STORAGE_KEY = 'header_search_history';
// eslint-disable-next-line
export const TA_V2_DEFAULT_HOST= typeof __DEFAULT_HOST__ !== 'undefined' ?  __DEFAULT_HOST__ : '';
export const PAGE_SIZE = 12;

export const SCREENS = {
  DEFAULT: 'DEFAULT',
  PROJECT_SELECT_PROJECT: 'PROJECT_SELECT_PROJECT',
  PROJECT_CREATE_GROUP: 'PROJECT_CREATE_GROUP',
  PROJECT_SELECT_GROUP: 'PROJECT_SELECT_GROUP',
  PROJECT_CREATE_PROJECT: 'PROJECT_CREATE_PROJECT',
  MINI_PIP_BASE: 'MINI_PIP_BASE',
  MINI_PIP_PRODUCT_OVERVIEW: 'MINI_PIP_PRODUCT_OVERVIEW',
  MINI_PIP_PRODUCT_SPECS: 'MINI_PIP_PRODUCT_SPECS',
  MINI_PIP_PRODUCT_REVIEWS: 'MINI_PIP_PRODUCT_REVIEWS',
  LIST_CREATE_LIST: 'LIST_CREATE_LIST',
  LIST_SELECT_LIST: 'LIST_SELECT_LIST',
  LIST_SUCCESS: 'LIST_SUCCESS',
  SIGN_IN: 'SIGN_IN',
  LOADING: 'LOADING',
  ADD_TO_SELECTION: 'ADD_TO_SELECTION',
  COPY_PROJECT_MESSAGING: 'COPY_PROJECT_MESSAGING'
};

export const HEADER_TEXT = {
  PROJECT_SELECT_PROJECT: 'Select Project',
  PROJECT_CREATE_GROUP: 'Create Group',
  PROJECT_SELECT_GROUP: 'Select Group',
  PROJECT_CREATE_PROJECT: 'Create Project',
  DEFAULT: 'Add Materials',
};

export const ERROR = {
  // eslint-disable-next-line max-len
  INVALID_QUOTE_NAME: 'Invalid Quote Name: Only a-z, A-Z, 0-9 are allowed.',
  EMPTY_QUOTE_NAME: 'Quote name cannot be empty',
  // eslint-disable-next-line max-len
  INVALID_LIST_NAME: 'Invalid List Name: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.',
  EMPTY_LIST: 'List name cannot be empty',
  LIST_ALREADY_EXISTS: 'List name already exists',
  GENERAL_API_FAIL: 'Something went wrong, please try after sometime',
  EMPTY_GROUP_NAME_MESSAGE: 'Enter Group Name',
  INVALID_GROUP_LENGTH_MESSAGE: 'Group Names can be at most 50 characters',
  // eslint-disable-next-line max-len
  INVALID_PROJECT_TEXT_MESSAGE: 'Invalid Text: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.'
};

export const TRACK = {
  OPEN_DRAWER: 'save-to-favorites.track-overlay',
  BUTTON_CLICK: 'save-to-favorites.track-click',
  ADD_ITEM: 'save-to-favorites.track-add-item',
  ADD_PRODUCTS: 'save-to-favorites.track-add-products',
  CREATE_LIST_WITH_ITEM: 'save-to-favorites.create-a-list-with-item',
  CREATE_LIST_WITH_PRODUCTS: 'save-to-favorites.create-a-list-with-products'
};

export const OPERATION_ADD_ITEMS = 'AddItems';
export const GROUP_ALREADY_EXITS_ERROR = 'Group already exists';

export const MOBILE_CHANNEL = 'mobile';
export const DELIMITER = ':';
export const DRAWERS = {
  LOADING: 'loading',
  CREATE_QUOTE: 'create-quote',
  ADD_TO_QUOTE: 'add-to-quote',
  SIGN_IN_WARNING: 'sign-in-warning',
  PRICE_CHANGE_WARNING: 'price-change-warning',
  SUCCESS: 'success',
  ERROR: 'error'
};
export const GENERIC_ERROR = 'Something went wrong. Please refresh and try again.';
export const DEFAULT_SELECTED_QUOTE = {
  userId: '', // this needs to be the userId of the user that created the quote, not current user
  quoteId: '',
  quoteName: ''
};

export const QUOTE_ACTIVE_STATUS = 'ACTIVE';
export const QUOTE_PENDING_STATUS = 'PENDING';

export const FULFILLMENT = {
  STH: 'ShipToHome',
  BOPIS: 'BOPIS',
  BOSS: 'ShipToStore',
  BODFS: 'DeliverFromStore',
  APPLIANCE: 'DirectDelivery'
};
export const PICKUP_FULFILLMENT = [FULFILLMENT.BOSS, FULFILLMENT.BOPIS];
export const DELIVERY_FULFILLMENT = [FULFILLMENT.STH, FULFILLMENT.BODFS, FULFILLMENT.APPLIANCE];

// role
export const USER_ADMIN = 'ADMIN';
export const PURCHASER = 'PURCHASER';

export const ADD_TO_TYPE = {
  LIST: 'List',
  QUOTE: 'Quote',
  PROJECT: 'Project',
  CART: 'Cart',
  COPY_PROJECT: 'CopyProject'
};

// Analytics
export const ANALYTICS_SELECT_PROJECT = 'add-to.select-project';
export const ADD_ITEM_NEW_PROJECT = 'projects: add to project action to new project';
