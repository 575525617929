/* eslint-disable react/forbid-prop-types */
import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@one-thd/sui-atomic-components/dist/button/ButtonBase';
import useForkRef from '@one-thd/sui-atomic-components/dist/private/hooks/useForkRef';
import { isSameDate } from './CalendarUtils';

const createComponent = (defaultComponent, displayName) => {
  const component = (props, ref) => {
    const {
      as,
      ...other
    } = props;

    const Component = as || defaultComponent;

    return (
      <Component ref={ref} {...other} />
    );
  };

  component.displayName = displayName;
  return React.forwardRef(component);
};

const CalendarCellRoot = createComponent(ButtonBase, 'CalendarCellRoot');

const CalendarCell = React.forwardRef((props, ref) => {

  const {
    children,
    current,
    disabled,
    data,
    focusable,
    granularity = 'day',
    range,
    readOnly,
    onBlur,
    onClick,
    onInvalidFocus,
    onFocus,
    insideCurrentMonth,
    isViewFocused,
    selected,
    size = 'small',
    value,
    ...other
  } = props;

  const cellRef = useRef();
  const handleRef = useForkRef(cellRef, ref);

  let isFocusable;
  if (granularity === 'month' || granularity === 'year') {
    isFocusable = value === focusable;
  } else {
    isFocusable = isSameDate(value, focusable);
  }

  useEffect(() => {
    if (!readOnly) {
      if (isViewFocused && isFocusable && !disabled && insideCurrentMonth) {
        cellRef.current.focus();
      } else if (isFocusable && disabled && insideCurrentMonth) {
        onInvalidFocus(focusable);
      }
    }
  });

  const handleOnClick = (event) => {
    if (onClick) {
      onClick(event, value);
    }
  };

  const handleOnFocus = (event) => {
    if (onFocus) {
      onFocus(event, value);
    }
  };

  const handleOnBlur = (event) => {
    if (onBlur) {
      onBlur(event, value);
    }
  };

  const buttonClasses = classNames('sui-group/calendar-cell sui-text-base sui-text-center sui-text-base sui-relative sui-outline-0',
    'sui-rounded-base sui-outline sui-outline-offset-2 focus-visible:sui-outline-strongest focus-visible:sui-outline-1',
    'sui-h-full sui-w-full',
    {
      'sui-text-inactive': disabled,
      'hover:sui-underline focus-visible:sui-z-100': !disabled && !readOnly,
      'sui-bg-button-hover-primary sui-text-inverse sui-font-w-bold sui-offset-primary': selected && !disabled,
      'sui-bg-strong sui-rounded-none hover:sui-ring-offset-strong': range === 'mid' && !disabled,
      'sui-rounded-r-none': range === 'start',
      'sui-rounded-l-none': range === 'end',
      'sui-flex sui-justify-center sui-items-center': readOnly,
      'sui-max-h-[48px] sui-min-h-[40px] sui-max-w-[48px] sui-min-w-[40px] sui-aspect-square': size !== 'large',
      'sui-max-h-12 sui-min-h-[40px] sui-max-w-[112px]': size === 'large'
    }
  );

  const dataClasses = classNames('sui-relative sui-bottom-[5px] sui-text-xs sui-font-normal', {
    'sui-text-inactive': disabled
  });

  const underlineClasses = classNames('sui-max-w-[50%] sui-min-w-[50%] sui-h-[1px]',
    'sui-absolute sui-top-[85%] sui-left-[25%]',
    { 'sui-bg-inverse': !selected && !disabled,
      'sui-bg-primary': selected,
      'sui-bg-inactive': disabled,
      'group-hover/calendar-cell:sui-hidden': !disabled && !readOnly }
  );

  const ariaLabel = granularity === 'month' || granularity === 'year' ? value : dayjs(value).format('MM/DD/YYYY');

  return (
    <>
      {readOnly && (
        <div ref={cellRef} className={buttonClasses}>
          { children }
          { current && <div className={underlineClasses} />}
          { data && <div className={dataClasses}>{data}</div>}
        </div>
      )}
      {!readOnly && (
        <CalendarCellRoot
          as={ButtonBase}
          role="gridcell"
          aria-selected={selected}
          tabIndex={isFocusable ? '0' : '-1'}
          ref={cellRef}
          unstyled
          className={buttonClasses}
          disabled={disabled}
          onBlur={handleOnBlur}
          onClick={handleOnClick}
          onFocus={handleOnFocus}
          {...other}
        >
          { children }
          { current && <div className={underlineClasses} />}
          { !disabled && data && <div className={dataClasses}>{data}</div>}
        </CalendarCellRoot>
      )}
    </>
  );
});

CalendarCell.displayName = 'CalendarCell';

CalendarCell.propTypes = {
  /**
   * The content of the CalendarCell component.
   */
  children: PropTypes.node,
  /**
   * If `true`, will apply the current date styles to the component.
   */
  current: PropTypes.bool,
  /**
   * If `true`, the component will be disable.
   */
  disabled: PropTypes.bool,
  /**
   * Extra information placed within the component, along with the date.
   */
  data: PropTypes.string,
  /**
   * A date that will be compared against the value prop. If a match is found, the element will place focus on itself.
   */
  focusable: PropTypes.any,
  /**
   * Determines what date granularity unit the button is representing. Necessary for accesibility and focus logic.
   * @default "day"
   */
  granularity: PropTypes.oneOf(['day', 'month', 'year']),
  /**
   * Will apply range selection styling based on the position of the element within a date range.
   */
  range: PropTypes.oneOf(['start', 'mid', 'end', 'default']),
  /**
   * If `true`, the component will become read only.
   */
  readOnly: PropTypes.bool,
  /**
   * Event fired when the component leaves the focused state.
   */
  onBlur: PropTypes.func,
  /**
   * Event fired when the component is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Event fired when the component receives focus.
   */
  onFocus: PropTypes.func,
  /**
   * Event fired when the component should focus but is determined as disabled.
   */
  onInvalidFocus: PropTypes.func,
  /**
   * @ignore
   */
  insideCurrentMonth: PropTypes.bool,
  /**
   * @ignore
   */
  isViewFocused: PropTypes.bool,
  /**
   * If `true`, will apply the selected styles to the component.
   */
  selected: PropTypes.bool,
  /**
   * Determines the size of the component.
   * @default "small"
   */
  size: PropTypes.oneOf(['small', 'large']),
  /**
   * @ignore
   */
  value: PropTypes.any,
};

CalendarCell.defaultProps = {};

export { CalendarCell };
