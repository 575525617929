export const getProductObject = (reviewStats, totalResults) => {
  const { store } = reviewStats?.Includes?.Products || {};
  const reviewCount = store?.FilteredReviewStatistics?.TotalReviewCount;
  if (reviewCount === totalResults) {
    return store || null;
  }
  const itemObject = reviewStats?.Includes?.Products?.items
    ?.find((el) => el?.FilteredReviewStatistics?.TotalReviewCount === totalResults);
  return itemObject || store;
};

export const getAverageRating = (itemObj, product, totalResults) => {
  const totalReviews = totalResults || product?.reviews?.ratingsReviews?.totalReviews || 0;
  const averageRating = totalReviews === 0 ? 0
    : itemObj?.FilteredReviewStatistics?.AverageOverallRating
      || product?.reviews?.ratingsReviews?.averageRating
      || 0;

  return Number(averageRating);

};