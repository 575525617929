import React, { useState, useEffect, useContext } from 'react';
import { string } from 'prop-types';

import { extend, useDataModel, QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { useConfigService, ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';

import {
  shouldDisplayRecsFBT,
  shouldDisplayFbtbundle,
  shouldDisplayFBTPMV,
  shouldDisplayPipInStock,
  isPackageRecommendationFlag,
  isProductAvailable,
  shouldDisplayConfigurator
} from '../utils/product-utils';
import { BundleMini } from './BundleMini';
import { useBundle } from '../hooks/useBundle';
import { FbtGm } from '../subcomponents/FbtGm';
import { ProductDataModel } from '../models/dataModel';
import { PackageMiniGM } from '../subcomponents/PackageMiniGM';
import { PackagesMiniAbTest } from '../subcomponents/PackagesMiniAbTest';

const BundlePmvFbtLayoutGmAndHdh = ({ itemId, name }) => {

  const storeId = useStoreId();
  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId
    },
    skip: !itemId,
    ssr: false
  });

  const ctx = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const { referer, isServer } = ctx;
  const [isPMVEnabled, setIsPMVEnabled] = useState(false);
  const [isFBTEnabled, setIsFBTEnabled] = useState(false);
  const [isPMVAndFBTEnabled, setIsPMVAndFBTEnabled] = useState(false);
  const fsRecsFBT = useConfigService('fs:isFbtRecsEnable');
  const abcTestEnable = useConfigService('fs:isABCTestEnable');
  const hasOneActiveABCTest = [isPMVEnabled, isFBTEnabled, isPMVAndFBTEnabled].filter((val) => !!val).length === 1;
  const displayConfigurator = shouldDisplayConfigurator(productData, isServer);
  const shouldDisplayRecs = !shouldDisplayFbtbundle(productData) && shouldDisplayRecsFBT(productData, referer)
  && fsRecsFBT;
  const shouldDisplayPipInStocks = !shouldDisplayPipInStock(productData, referer);

  const { bundleIds } = useBundle({ product: productData?.product });

  useEffect(() => {
    shouldDisplayFBTPMV(setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled);
  }, []);

  if (!productData || displayConfigurator) {
    return null;
  }

  const { product } = productData || {};
  const availableProduct = isProductAvailable(product, abcTestEnable);
  const isPackage = isPackageRecommendationFlag(product);
  if (!availableProduct) {
    return null;
  }

  if (bundleIds) {
    return (
      <>
        <FbtGm
          itemId={itemId}
          shouldDisplayRecs={shouldDisplayRecs}
          shouldDisplayPipInStocks={shouldDisplayPipInStocks}
          name={name}
        />
        <QueryProvider
          cacheKey="bundle-mini-provider"
          defaultVariables={defaultVariables.current}
        >
          <BundleMini bundleIds={bundleIds} />
        </QueryProvider>
      </>
    );
  }

  if (abcTestEnable && (isPackage)) {
    if (hasOneActiveABCTest) {
      return (
        <>
          {isPMVEnabled && (
            <PackageMiniGM itemId={itemId} name={name} />
          )}
          {isFBTEnabled && (
            <FbtGm
              itemId={itemId}
              shouldDisplayRecs={shouldDisplayRecs}
              shouldDisplayPipInStocks={shouldDisplayPipInStocks}
              name={name}
            />
          )}
          {isPMVAndFBTEnabled && (
            <>
              <PackageMiniGM itemId={itemId} name={name} />
              <FbtGm
                itemId={itemId}
                shouldDisplayRecs={shouldDisplayRecs}
                shouldDisplayPipInStocks={shouldDisplayPipInStocks}
                name={name}
              />
            </>
          )}
        </>
      );
    }
    return null;
  }
  if (isPackage) {
    return (
      <>
        <FbtGm
          itemId={itemId}
          shouldDisplayRecs={shouldDisplayRecs}
          shouldDisplayPipInStocks={shouldDisplayPipInStocks}
          name={name}
        />
        <PackageMiniGM itemId={itemId} name={name} />
      </>
    );
  }
  return (
    <FbtGm
      itemId={itemId}
      shouldDisplayRecs={shouldDisplayRecs}
      shouldDisplayPipInStocks={shouldDisplayPipInStocks}
      name={name}
    />
  );
};

BundlePmvFbtLayoutGmAndHdh.propTypes = {
  itemId: string,
  name: string
};

BundlePmvFbtLayoutGmAndHdh.defaultProps = {
  itemId: '',
  name: ''
};

BundlePmvFbtLayoutGmAndHdh.dataModel = extend(
  ProductDataModel,
  PackagesMiniAbTest,
  podFulFillmentUtils
);

BundlePmvFbtLayoutGmAndHdh.displayName = 'BundlePmvFbtLayoutGmAndHdh';

export { BundlePmvFbtLayoutGmAndHdh };