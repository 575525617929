import { useContext, useEffect, useState } from 'react';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  params, string, shape, number, arrayOf, useLazyDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import THDStorageUtils, { SESSION_STORAGE } from '@thd-olt-global/thd-storage-utils';

const ACCOUNT_BENEFITS_STORAGE_KEY = 'header_account_benefit_tags_shared_';

export const useBenefitCheck = ({ skip = false }) => {
  const { channel } = useContext(ExperienceContext);
  const THDCustomer = useThdCustomer();
  const { userID, svocID } = THDCustomer;
  const [isProjectsEligible, setIsProjectsEligible] = useState(false);
  const channelId = channel === 'mobile' ? 2 : 1;
  const PROJECT_BENEFIT_KEY = `${ACCOUNT_BENEFITS_STORAGE_KEY}${svocID}`;

  const [queryBenefits, { data, error }] = useLazyDataModel('loyaltyMembershipDetails', {
    variables: {},
    fetchPolicy: 'cache-first',
    context: { withAuth: true }
  });

  const updateEligibility = (isEligible) => {
    if (isEligible !== null && isEligible !== isProjectsEligible) {
      setIsProjectsEligible(isEligible);
    }
  };

  const checkBenefitLocal = async () => {
    let benefit = null;
    try {
      const sessionValue = window?.sessionStorage?.getItem(PROJECT_BENEFIT_KEY);
      if (sessionValue) {
        const decodedValue = decodeURIComponent(sessionValue);
        const parsedValue = JSON.parse(decodedValue);
        benefit = parsedValue?.value;
        if (new Date(parsedValue?.expiry) <= Date.now()) {
          benefit = null;
        }
      }
    } catch (err) {
      benefit = null;
    }
    if (benefit && benefit !== 'undefined') {
      const benefitObj = JSON.parse(benefit);
      return benefitObj?.projectBenefits;
    }
    return null;
  };

  const updateLocalStorage = (isEligible) => {
    try {
      THDStorageUtils.set(SESSION_STORAGE, {
        key: PROJECT_BENEFIT_KEY,
        value: JSON.stringify({ projectBenefits: isEligible })
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({
        message: `Error occurred while updating session storage - ${PROJECT_BENEFIT_KEY}`,
        err
      });
    }
  };

  useEffect(() => {
    if (data) {
      const benefitTags = data?.loyaltyMembershipDetails?.proTiers?.benefitTags || [];
      const hasProjectBenefits = benefitTags?.some((tag) => tag?.tagName === 'ProjectBenefit');
      updateEligibility(hasProjectBenefits);
      updateLocalStorage(hasProjectBenefits);
    } else if (error) {
      updateEligibility(false);
    }
  }, [data, error]);

  useEffect(() => {
    if (!skip) {
      const checkProjectBenefits = async () => {
        const shouldRefetchLoyalty = (hasBenefit) => {
          return ![true, false].includes(hasBenefit);
        };
        const benefitLocal = await checkBenefitLocal();
        if (shouldRefetchLoyalty(benefitLocal)) {
          await queryBenefits({
            variables: {
              userId: userID,
              customerAccountId: svocID,
              channelId
            }
          });
        } else {
          updateEligibility(benefitLocal);
        }
      };
      if (typeof window !== 'undefined' && THDCustomer.isB2BCustomer && svocID) {
        checkProjectBenefits();
      }
    }
  }, [THDCustomer, skip]);

  return { isProjectsEligible };
};

useBenefitCheck.dataModel = {
  loyaltyMembershipDetails: params({
    userId: string().isRequired(),
    customerAccountId: string().isRequired(),
    channelId: number().isRequired()
  }).shape({
    proTiers: shape({
      benefitTags: arrayOf(shape({
        tagName: string().isRequired(),
        value: string().isRequired()
      }))
    })
  })
};
