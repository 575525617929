import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@one-thd/sui-atomic-components';

const DateField = React.forwardRef((props, ref) => {
  const {
    disabled = false,
    format = 'MM/DD/YYYY',
    helpMessage,
    onChange,
    label,
    InputProps: InputPropsProp,
    status,
    statusMessage,
    ...other
  } = props;
  const [helper, setHelper] = useState(false);
  const [cursor, setCursor] = useState(null);
  const inputRef = useRef(null);

  const handleChange = (event) => {
    let dateValue;
    const cursorPosition = event.target.selectionStart;

    if (/\d/.test(event.target.value)) {
      setHelper(true);
    } else if (event.target.value === '') {
      setHelper(false);
    }

    switch (format) {
      case 'MM/YY':
        dateValue = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);

        if (cursorPosition !== event.target.value.length) {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}`;
          event.target.selectionStart = cursorPosition;
          event.target.selectionEnd = cursorPosition;
        } else {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}`;
        }
        break;
      case 'MM/DD/YYYY–MM/DD/YYYY':
        dateValue = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})(\d{0,4})/);

        if (cursorPosition !== event.target.value.length) {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}${`${dateValue[3] ? `/${dateValue[3]}` : ''}`}${`${dateValue[4] ? `–${dateValue[4]}` : ''}`}${`${dateValue[5] ? `/${dateValue[5]}` : ''}`}${`${dateValue[6] ? `/${dateValue[6]}` : ''}`}`;
          event.target.selectionStart = cursorPosition;
          event.target.selectionEnd = cursorPosition;
        } else {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}${`${dateValue[3] ? `/${dateValue[3]}` : ''}`}${`${dateValue[4] ? `–${dateValue[4]}` : ''}`}${`${dateValue[5] ? `/${dateValue[5]}` : ''}`}${`${dateValue[6] ? `/${dateValue[6]}` : ''}`}`;
        }
        break;
      default:
        dateValue = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

        if (cursorPosition !== event.target.value.length) {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}${`${dateValue[3] ? `/${dateValue[3]}` : ''}`}`;
          event.target.selectionStart = cursorPosition;
          event.target.selectionEnd = cursorPosition;
        } else {
          event.target.value = !dateValue[2] ? dateValue[1] : `${dateValue[1]}/${dateValue[2]}${`${dateValue[3] ? `/${dateValue[3]}` : ''}`}`;
        }
    }

    if (onChange) {
      onChange(event, event.target.value);
    }
  };

  useEffect(() => {
    if (inputRef) {
      inputRef?.current?.setSelectionRange(cursor, cursor, 'forward');
    }
  }, [inputRef, cursor]);

  return (
    <TextField
      disabled={disabled}
      label={<>{label}<span className="sui-sr-only"> Formatted As:</span></>}
      placeholder={format}
      InputProps={{
        inputProps: {
          inputMode: 'numeric'
        },
        inputRef,
        ...InputPropsProp
      }}
      onChange={(event) => handleChange(event)}
      helpMessage={(
        <>
          {helper && <span className="sui-line-clamp-1 sui-mb-1">{format}</span>}
          <span className="sui-line-clamp-1">{helpMessage}</span>
        </>
      )}
      ref={ref}
      status={status}
      statusMessage={statusMessage}
      {...other}
    />
  );
});

DateField.displayName = 'DateField';

DateField.propTypes = {
  /**
   * If `true`, the component is inactive
   */
  disabled: PropTypes.bool,
  /**
   * The type of input masking applied to the DateField.
   */
  format: PropTypes.oneOf(['MM/DD/YYYY', 'MM/DD/YYYY–MM/DD/YYYY', 'MM/YY']),
  /**
   * Component's help message content.
   */
  helpMessage: PropTypes.string,
  /**
   * @ignore
   */
  InputProps: PropTypes.object,
  /**
   * Component's label content.
   */
  label: PropTypes.string,
  /**
   * Event fired when the component's value is changed.
   */
  onChange: PropTypes.func,
  /**
   * The states of validation for the TextField component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   */
  statusMessage: PropTypes.string
};

DateField.defaultProps = {};

export { DateField };
