import React from 'react';
import { AddTo } from '@thd-olt-component-react/add-to';
import { extend } from '@thd-nucleus/data-sources';
import { number, string } from 'prop-types';

export const BuyboxAddToProject = ({ quantity, itemId }) => {
  return (
    <AddTo
      addToType="Project"
      products={[{ itemId, quantity }]}
    />
  );
};

BuyboxAddToProject.dataModel = extend(
  AddTo
);

BuyboxAddToProject.defaultProps = {
  quantity: 1,
};

BuyboxAddToProject.propTypes = {
  quantity: number,
  itemId: string.isRequired,
};