import React, { Component, Suspense } from 'react';
import {
  oneOf, arrayOf, bool, func, shape, string, number
} from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { PaintSwatches } from '@one-thd/sui-icons';
import { VideoPlayerProvider, VideoPlayer, pauseAllVideos } from '@thd-olt-component-react/video-player';
import {
  ARIcon, HotspotTagIcon, PlayIcon, ThreeSixtyIcon, BackgroundCircleIcon
} from '../Icons';
import Helper from '../../Helper';
import './media-gallery-mobile.style.scss';
import { thumbnailClick, mediaClick } from '../../../publisher';

const MediaGalleryOverlayMobile = React.lazy(() => import(
  /* webpackChunkName: "media-gallery-overlay-mobile" */'../MediaGalleryOverlayMobile')
);

class MediaGalleryMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mainImage: props?.media?.main[props.augmentedRealityIndex] || null,
      selectedImageIndex: props.augmentedRealityIndex || 0,
      overlayOpened: props.showAvataarAR || false,
      thumbnails: props?.media?.thumbnails
    };
  }

  componentDidUpdate(prevProps) {
    const { media = {}, product, showAvataarAR = false, augmentedRealityIndex = 0 } = this.props;
    const { main = [] } = media;
    if (prevProps?.product.itemId !== product.itemId
      || (prevProps.media && prevProps.media.main[0] !== main[0])) {
      this.updatePageLayout();
    }
    if (prevProps?.showAvataarAR !== showAvataarAR && showAvataarAR) {
      this.setState({
        selectedImageIndex: augmentedRealityIndex,
        mainImage: main[augmentedRealityIndex],
        overlayOpened: true
      });
    }
  }

  onMainImageClick = (images, index, thumbnailType) => {
    const { thumbnails } = this.state;
    const payload = {
      eventName: 'expanded-view',
      primaryCategory: 'overlay'
    };
    thumbnailClick(payload);

    const thumbnailPayload = {
      section: 'media',
      component: 'thumbnails',
      target: thumbnailType || 'n/a',
      position: 'n/a'
    };

    mediaClick(thumbnailPayload);

    this.setState({
      overlayOpened: true, mainImage: images[index], selectedImageIndex: index, thumbnails
    });
    if (typeof window !== 'undefined') {
      window.history.pushState({ page: 'MEDIAOVERLAY' }, null, '');
    }
  };

  setChildrenElements = (images) => {
    const {
      product,
      overrideData,
      firstItemFetchPriority,
      autoPlayInViewportEnabled,
    } = this.props;
    const brandName = overrideData?.brandNameLabel || product.identifiers?.brandName;
    const galleryLabel = overrideData?.galleryLabel || product.identifiers?.productLabel;
    const children = (images || []).map((image, imageIndex) => {
      const {
        collection, mediaStill, url, videoStill, type, altText, rgb
      } = image;
      const isImage360 = Helper.isImage360({ media: image });
      const isVideo = Helper.isVideo({ media: image });
      const isAR = Helper.isAR({ media: image });
      const isAvataarAR = Helper.isAvataarAR({ media: image });
      const isHotspot = Helper.isHotspot({ media: image });
      const isCustomColor = Helper.isCustomColor({ media: image });
      const isManualColor = Helper.isManualColor({ media: image });
      const { source, videoSource, videoId } = Helper.formatVideoProps(image);
      const alt = altText || `${brandName} ${galleryLabel}`;

      const lazy = imageIndex > 1;
      let thumbnailUrl = image?.url;
      if (isVideo) {
        thumbnailUrl = videoStill;
      } else if (isImage360) {
        thumbnailUrl = `${url}?thumb&profile=400`;
      } else if (isAR) {
        thumbnailUrl = mediaStill;
      }

      return (
        <li className="media-gallery-mobile" key={imageIndex}>
          {(autoPlayInViewportEnabled && isVideo)
            ? <VideoPlayer videoId={videoId} source={source} videoSrc={videoSource} />
            : (
              <button
                className="media-gallery-mobile__thumbnails-wrapper"
                onClick={() => this.onMainImageClick(images, imageIndex, type)}
                type="button"
              >
                {isCustomColor || isManualColor
                  ? (
                    <>
                      {isManualColor
                        ? (
                          <div
                            className={classNames('manualSwatch',
                              'sui-aspect-square',
                              'sui-bg-inactive',
                              'sui-flex',
                              'sui-items-center',
                              'sui-justify-center')}
                          >
                            <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
                              <div>
                                <PaintSwatches color="medium" />
                                <div>No Color Preview Available</div>
                              </div>
                            </div>
                            <Image
                              alt={alt}
                              src={image.brandLogo}
                            />
                          </div>
                        )
                        : (
                          <div
                            className="media-gallery-mobile__thumbnailImageFitBrand"
                            style={{
                              backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
                            }}
                          >
                            <Image
                              alt={alt}
                              src={image.brandLogo}
                            />
                          </div>
                        )}
                    </>
                  )
                  : (
                    <>
                      {(isImage360 || isVideo || isAR || isHotspot) && (<BackgroundCircleIcon />)}
                      {isImage360 && (<ThreeSixtyIcon />)}
                      {isVideo && (<PlayIcon />)}
                      {isAR && isAvataarAR && (<ThreeSixtyIcon />)}
                      {isAR && !isAvataarAR && (<ARIcon />)}
                      {isHotspot && (<HotspotTagIcon isThumbnail />)}
                      <Image
                        className="media-gallery-mobile__thumbnails"
                        src={thumbnailUrl}
                        alt={alt}
                        lazy={lazy}
                        height="263"
                        width="263"
                        fetchpriority={imageIndex === 0 ? firstItemFetchPriority : null}
                      />
                      {collection
                    && (
                      <div className="shopThisRoomContainer">
                        <a
                          className="shopThisRoomLink"
                          href={collection}
                          onClick={(e) => e && e.stopPropagation()}
                        > Shop This Room
                        </a>
                      </div>
                    )}
                    </>
                  )}
              </button>
            )}
        </li>
      );
    });
    return children;
  };

  handleMediaClick = ({ media }) => {
    const { onMediaClick } = this.props;
    if (onMediaClick) {
      onMediaClick({ media });
    }
  };

  updatePageLayout = () => {
    const { media } = this.props;
    const { main, thumbnails } = media;
    this.setState({
      mainImage: main[0],
      selectedImageIndex: 0,
      thumbnails
    });
    this.setChildrenElements(main);
  };

  hideOverlay = () => {
    const { onAvataarButtonClick } = this.props;
    this.setState({ overlayOpened: false });
    if (onAvataarButtonClick) {
      onAvataarButtonClick({ showAvataarAR: false });
    }
  };

  render() {
    const {
      overlayOpened, thumbnails, mainImage, selectedImageIndex
    } = this.state;
    const {
      product, media = {}, overrideData, autoPlayInViewportEnabled, itemId, showAvataarAR, showAvataarDefault
    } = this.props;
    const { main = [], zoom = [] } = media;
    const wrapperClass = classNames(
      'media-gallery-mobile__wrapper',
      { 'single-image': main.length === 1 }
    );

    if (autoPlayInViewportEnabled && overlayOpened) {
      pauseAllVideos();
    }

    return (
      <>
        {autoPlayInViewportEnabled && !showAvataarAR && (
          <VideoPlayerProvider autoPlayInViewport>
            <ul data-component="MediaGalleryMobile" className={wrapperClass}>
              {this.setChildrenElements(main)}
            </ul>
          </VideoPlayerProvider>
        )}
        {!autoPlayInViewportEnabled && !showAvataarAR && (
          <ul data-component="MediaGalleryMobile" className={wrapperClass}>
            {this.setChildrenElements(main)}
          </ul>
        )}
        {overlayOpened
          && (
            <Suspense fallback={<div />}>
              <MediaGalleryOverlayMobile
                handleClose={this.hideOverlay}
                overlayOpened={overlayOpened}
                product={product}
                mainImage={mainImage}
                mainImages={main}
                overrideData={overrideData}
                onMediaClick={this.handleMediaClick}
                zoomedImages={zoom}
                thumbnails={thumbnails}
                selectedImageIndex={selectedImageIndex}
                itemId={itemId}
                showAvataarAR={showAvataarAR}
                showAvataarDefault={showAvataarDefault}
              />
            </Suspense>
          )}
      </>
    );
  }
}

MediaGalleryMobile.propTypes = {
  media: shape({
    main: arrayOf(shape({
      id: string,
      location: string,
      mediaType: string,
      altText: string
    })),
    thumbnails: arrayOf(shape({
      id: string,
      location: string,
      mediaType: string,
      altText: string
    })),
    zoom: arrayOf(shape({
      id: string,
      location: string,
      mediaType: string,
      altText: string
    }))
  }).isRequired,
  overrideData: shape({
    galleryLabel: string,
    brandNameLabel: string
  }),
  onMediaClick: func,
  product: shape({}),
  firstItemFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ]),
  autoPlayInViewportEnabled: bool,
  itemId: string,
  showAvataarAR: bool,
  showAvataarDefault: bool,
  augmentedRealityIndex: number,
  onAvataarButtonClick: func,
};

MediaGalleryMobile.defaultProps = {
  onMediaClick: null,
  product: {},
  overrideData: null,
  firstItemFetchPriority: 'auto',
  autoPlayInViewportEnabled: false,
  itemId: null,
  showAvataarAR: false,
  showAvataarDefault: false,
  augmentedRealityIndex: 0,
  onAvataarButtonClick: null,
};

export { MediaGalleryMobile };
