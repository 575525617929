import React from 'react';
import { shape, bool, string } from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { useConfigService } from '@thd-nucleus/experience-context';
import { CarouselHeader } from './CarouselHeader';
import { getRecsCollectionTitle } from '../utils/helpers';

const CollectionsTitle = (props) => {
  const {
    metadata,
    isMobile,
    isBatteryCollection,
    showColUpLevel,
    tntTitle,
    scheme
  } = props;

  let title = getRecsCollectionTitle(tntTitle, showColUpLevel, metadata);

  const isBathCollectionPoCEnabled = useConfigService('fs:enableBathCollectionPoC');

  // eslint-disable-next-line no-nested-ternary
  const shopMoreStyleLink = isBatteryCollection
    ? metadata?.collectionPlpUrl
    : isBathCollectionPoCEnabled && metadata?.collectionBrandUrl
      ? metadata?.collectionBrandUrl
      : metadata?.collectionPlpUrl;

  return (
    <>
      <div className={isBatteryCollection ? 'sui-grid sui-grid-cols-3' : 'sui-grid sui-grid-cols-2'}>
        <div className={isBatteryCollection ? 'sui-col-span-2' : ''}>
          <CarouselHeader title={title} />
        </div>
        {((shopMoreStyleLink && showColUpLevel) || isBatteryCollection) && (
          <div className="sui-pt-5 sui-pb-5 sui-text-right">
            <Link
              href={shopMoreStyleLink}
              target={isMobile ? '_self' : '_blank'}
              rel="noreferrer"
              data-testid="tooltip-product"
            > {isBatteryCollection
                ? <span className="sui-text-sm">Shop All</span>
                : <span className="sui-text-sm">Shop More In This Style</span>}
            </Link>
          </div>
        )}
      </div>
      {scheme === 'battery_collection' && (
        <div className="sui-mb-3">
          <Typography variant="h6">Use the same battery and charger for all these tools</Typography>
        </div>
      )}
    </>
  );
};

CollectionsTitle.propTypes = {
  metadata: shape({
  }),
  isMobile: bool,
  isBatteryCollection: bool,
  showColUpLevel: bool,
  tntTitle: string,
  scheme: string
};

CollectionsTitle.defaultProps = {
  metadata: {},
  isMobile: false,
  isBatteryCollection: false,
  showColUpLevel: false,
  tntTitle: '',
  scheme: ''
};

export default CollectionsTitle;
