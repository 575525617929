import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const PrimaryResult = ({
  searchTerm,
  resultTitle,
  resultLink
}) => {
  const hasSearchTerm = searchTerm.length > 0;
  const startOfSearchTerm = resultTitle.toLowerCase().indexOf(searchTerm.toLowerCase());

  let plainText = (startOfSearchTerm >= 0 && hasSearchTerm)
    ? resultTitle.substring(0, startOfSearchTerm + searchTerm.length)
    : resultTitle;

  const boldSubstring = (startOfSearchTerm >= 0 && hasSearchTerm)
    ? resultTitle.substring(startOfSearchTerm + searchTerm.length, resultTitle.length)
    : '';

  const reformattedTitle = (showFormattedText) => {
    return showFormattedText
      ? (
        <>
          <span className="sui-whitespace-pre">{plainText}</span>
          <span className="sui-font-bold">{boldSubstring}</span>
        </>
      )
      : (<span>{resultTitle}</span>);
  };

  return (
    <div
      key={resultTitle}
      data-testid="typeahead-primary-result-item"
    >
      <Typography key={resultTitle} variant="body-base" color="primary">
        {reformattedTitle(hasSearchTerm)}
      </Typography>
    </div>
  );
};

PrimaryResult.displayName = 'PrimaryResult';

PrimaryResult.propTypes = {
  resultTitle: string,
  resultLink: string,
  searchTerm: string,
};
PrimaryResult.defaultProps = {
  resultTitle: '',
  resultLink: '',
  searchTerm: '',
};

export { PrimaryResult };
