import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { client, number, params, shape, string, useDataModel } from "@thd-nucleus/data-sources";
import { IconButton } from "@one-thd/sui-atomic-components";
import { Add, Heart } from "@one-thd/sui-icons";
import { useTheme } from "@thd-olt-component-react/theme-provider";
import { AddToList } from "./AddToList";

export const SaveToFavoriteIconButton = (props) => {
  const { storeId, products, handleOpen, showCount } = props;
  const [favoriteCount, setFavoriteCount] = React.useState(0);
  const theme = useTheme(AddToList, props);
  const { hideCount, showPlusSign } = theme.props;
  const { data } = useDataModel('product', {
    variables: {
      itemId: products?.[0]?.itemId,
      storeId
    },
    skip: products?.length > 1 || !products?.[0]?.itemId
  });

  const { product } = data || {};
  const favoriteDetailCount = product?.favoriteDetail?.count;

  useEffect(() => {
    setFavoriteCount(favoriteDetailCount);
  }, [favoriteDetailCount]);

  const count = favoriteCount || favoriteDetailCount;
  const canShowCount = !hideCount && showCount && count;
  let favoriteCountRoundOff = count;
  const maxFavoriteCount = 999;
  if (count && count > maxFavoriteCount) {
    favoriteCountRoundOff = `${(count / 1000).toFixed(1)}k`;
  }

  const favButtonWrapperClass = classNames({
    'sui-pr-2': canShowCount
  });

  return (
    <div className={favButtonWrapperClass}>
      <>
        <IconButton
          aria-label="Add To List"
          size="large"
          icon={showPlusSign ? Add : Heart}
          onClick={handleOpen}
        />
        {canShowCount && (<span>{favoriteCountRoundOff}</span>)}
      </>
    </div>
  );
}

SaveToFavoriteIconButton.displayName = 'SaveToFavoriteIconButton';

SaveToFavoriteIconButton.themeProps = {
  hideCount: PropTypes.bool,
  showPlusSign: PropTypes.bool
};

SaveToFavoriteIconButton.defaultThemeProps = {
  hideCount: false,
  showPlusSign: false
};


SaveToFavoriteIconButton.propTypes = {
  storeId: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string
  })),
  handleOpen: PropTypes.func,
  hideCount: PropTypes.bool,
  showCount: PropTypes.bool
};

SaveToFavoriteIconButton.defaultProps = {
  storeId: null,
  products: [],
  handleOpen: () => {},
  hideCount: false,
  showCount: true
};
const favoriteDetailCount = shape({
  count: number()
});

if (favoriteDetailCount.skip) {
  favoriteDetailCount.skip('skipFavoriteCount', false);
}

SaveToFavoriteIconButton.dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    dataSource: string(),
    dataSources: string(),
    favoriteDetail: client(favoriteDetailCount)
  })
};
