import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  FormController,
  Radio,
  RadioGroup,
  SelectionControlLabel,
  DrawerHeader,
  DrawerBody,
  Typography
} from '@one-thd/sui-atomic-components';
import { AddToProductDetails } from '../AddToProductDetails/AddToProductDetails';

export const AddToSelection = ({ products, addToType, setAddToType, handleClose, isProjectsEligible, copyProjectData }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    if (value === 'Project' && copyProjectData?.length) {
      setAddToType('CopyProject');
    } else {
      setAddToType(value);
    }
  };
  const projectSelectionWrapperClass = classNames('sui-py-4', {
    'sui-hidden': !isProjectsEligible
  });
  return (
    <>
      <DrawerHeader
        title="Add to..."
        onClose={handleClose}
      />
      <DrawerBody>
        {products?.length <= 1  && (
          <AddToProductDetails products={products} />
        )}
        <FormController>
          <RadioGroup
            aria-labelledby="add-to-buttons-group-label"
            value={addToType}
            name="add-to-radio-buttons-group"
            onChange={handleChange}
          >
            <div
              className="sui-border-b-1 sui-border-primary sui-border-solid sui-py-4"
            >
              <SelectionControlLabel value="List" label="List">
                <Radio/>
              </SelectionControlLabel>
              <div className="">
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  Organize and save items for future reference or repeat purchases. Ideal for
                  creating product collections for different tasks or jobs.
                </Typography>
              </div>
            </div>
            <div
              className={projectSelectionWrapperClass}
            >
              <SelectionControlLabel value="Project" label="Project">
                <Radio/>
              </SelectionControlLabel>
              <div className="sui-py-4">
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  Group items, track progress, and manage tasks for a specific job. Projects help
                  you oversee everything from materials to costs, all in one place.
                </Typography>
              </div>
            </div>
          </RadioGroup>
        </FormController>
      </DrawerBody>
    </>
  );
};

AddToSelection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  addToType: PropTypes.string,
  setAddToType: PropTypes.func,
  handleClose: PropTypes.func,
  isProjectsEligible: PropTypes.bool
};

AddToSelection.displayName = 'AddToSelection';

AddToSelection.defaultProps = {
  products: [],
  addToType: null,
  setAddToType: () => {
  },
  handleClose: () => {
  }
};
