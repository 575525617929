/* eslint-disable max-len */
export default {
  buyOneGetOne: {
    plpMessage: 'Free Gift With Purchase'
  },
  buyOneGetPercentageOff: {
    plpMessage: 'Buy One, Get One {{rewardAmount}} Off'
  },
  buyOneGetDollarOff: {
    plpMessage: 'Buy One, Get One {{rewardAmount}} Off'
  },
  buyOneGetY: {
    plpMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantity}} Free'
  },
  buyXGetOne: {
    plpMessage: 'Buy {{src1MinPurchaseQuantity}}, Get {{tgt1MinPurchaseQuantityWord}} Free'
  },
  buyOneGetYPercentageOff: {
    plpMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantity}} {{rewardAmount}} Off'
  },
  buyXGetOnePercentageOff: {
    plpMessage: 'Buy {{src1MinPurchaseQuantity}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off'
  },
  buyOneGetYDollarOff: {
    plpMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantity}} {{rewardAmount}} Off'
  },
  buyXGetOneDollarOff: {
    plpMessage: 'Buy {{src1MinPurchaseQuantity}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off'
  },
  buyOneGetOneBackwards: {
    plpMessage: 'Free With Eligible Purchase'
  },
  buyOneGetPercentageOffBackwards: {
    plpMessage: '{{rewardAmount}} Off With Eligible Purchase'
  },
  buyOneGetDollarOffBackwards: {
    plpMessage: '{{rewardAmount}} Off With Eligible Purchase'
  },
  buyMinAmountGetOne: {
    plpMessage: 'Spend {{qualifyingAmount}}, Choose Free Items'
  },
  buyMinAmountGetDollarOff: {
    plpMessage: 'Get {{rewardAmount}} Off Additional Items'
  },
  buyMinAmountGetPercentageOff: {
    plpMessage: 'Get {{rewardAmount}} Off Additional Items'
  },
  MSB1: {
    plpMessage: 'Buy {{qualifyingAmount}} or More, Get {{rewardAmount}} Off'
  },
  MSB2: {
    plpMessage: 'Buy {{qualifyingAmount}} or More, Get {{rewardAmount}} Off Order'
  },
  MSB3: {
    plpMessage: 'Buy {{qualifyingAmount}} or More, Get {{rewardAmount}} Off Each'
  },
  MSB4: {
    plpMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off'
  },
  MSB5: {
    plpMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Order'
  },
  MSB6: {
    plpMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Each'
  },
  BMSM: {
    plpMessage: 'Buy More, Save More'
  },
  BUNDLE: {
    plpMessage: 'Bundle Savings'
  }
};
