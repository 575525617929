import React, { useContext } from 'react';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const RelatedSearchPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  return (
    <div data-component="RelatedSearchPlaceholder">
      <Skeleton SkeletonContentProps={{ disablePadding: true }} grow>
        <SkeletonBlock aspect="wide" width="100%" />
      </Skeleton>
    </div>
  );
};

export { RelatedSearchPlaceholder };
